import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  changePassword(currentPassword, newPassword) {
    return Axios.post(
      "/api/user/change-password",
      {
        currentPassword: currentPassword,
        newPassword: newPassword,
      }
    );
  },

  editUser(firstName, lastName, mobileNumber) {
    return Axios.post(
      "/api/user/",
      {
        firstName: firstName,
        lastName: lastName,
        mobileNumber: mobileNumber
      }
    );
  },
  
  createTenantUser(email, firstName, lastName, role, password, mobileNumber) {
    return Axios.post(
      "/api/user/tenant/create",
      {
        email: email,
        firstName: firstName,
        lastName: lastName,
        role: role,
        password: password,
        mobileNumber: mobileNumber
      }
    );
  },

  getListTenantUsers(tenantId) {
    return Axios.post(
      "/api/user/list/tenant",
      {
        tenantId: tenantId
      }

    )
  },

  editTenantUser(email, firstName, lastName, id, role, mobileNumber) {
    return Axios.post(
      "/api/user/tenant/edit",
      {
        email: email,
        firstName: firstName,
        lastName: lastName,
        id: id,
        role: role,
        mobileNumber: mobileNumber
      }

    )
  },

  removeTenantUser(id) {
    return Axios.del(
      "/api/user/tenant",
      {
        id: id,
      }
    );
  },
};