import React, { useState, useEffect } from 'react';
import './profile.scss';
import Form from 'devextreme-react/form';
import UserService from "../../api/user.service";
import  { Item as Item2, PatternRule } from 'devextreme-react/form';
import Toast from 'components/Toast';
import eye from "../../assets/icons/eye.svg";
import eyeOff from "../../assets/icons/eye-off.svg";
import { useHistory } from 'react-router-dom';


export default function App() {
  const history = useHistory();
  const [passwordState, setPasswordState] = useState('password');
  const [oldPasswordState, setOldPasswordState] = useState('password');
  const [confirmPasswordState, setConfirmPasswordState] = useState('password');
  const [user, setUser] = useState({
    FirstName: "",
    LastName: "",
    MobileNumber: ""
  })
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    MobileNumber: "",
    OldPassword: "",
    NewPassword: "",
    ConfirmNewPassword: ""
  })
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 5000
  });

  const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: passwordState,  buttons: [{
    name: 'trash',
    location: 'after',
    options: {
      stylingMode: 'text',
      icon: (passwordState === 'password' ? eye : eyeOff),
      onClick: () => {
        setPasswordState(passwordState === 'password' ? 'text' : 'password')
      },
    },
    }],
  }

  const confirmPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: confirmPasswordState,  buttons: [{
    name: 'trash',
    location: 'after',
    options: {
      stylingMode: 'text',
      icon: (confirmPasswordState === 'password' ? eye : eyeOff),
      onClick: () => {
        setConfirmPasswordState(confirmPasswordState === 'password' ? 'text' : 'password')
      },
    },
    }],
  }

  const oldPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Old Password', mode: oldPasswordState,  buttons: [{
    name: 'trash',
    location: 'after',
    options: {
      stylingMode: 'text',
      icon: (oldPasswordState === 'password' ? eye : eyeOff),
      onClick: () => {
        setOldPasswordState(oldPasswordState === 'password' ? 'text' : 'password')
      },
    },
    }],
  }

  function getData(){
    let myUser = localStorage.getItem("user")
    setUser({
      FirstName: JSON.parse(myUser).firstName,
      LastName: JSON.parse(myUser).lastName,
      MobileNumber: JSON.parse(myUser).mobileNumber
    })
    let myFormData = formData

    formData.FirstName = JSON.parse(myUser).firstName
    formData.LastName = JSON.parse(myUser).lastName
    formData.MobileNumber = JSON.parse(myUser).mobileNumber
    setFormData(myFormData)
  }

  useEffect(() => {
    getData()
  }, []);

  function setData(e){
    let myFormData = formData
    myFormData[e.dataField] = e.value
    setFormData(myFormData)
  }

  function backClick(){
    history.goBack();
  }

  function onSaveClick(e){
    let myFormData = formData
    if (myFormData.ConfirmNewPassword === "" && myFormData.NewPassword === "" && myFormData.OldPassword === "" ){
      UserService.editUser(myFormData.FirstName, myFormData.LastName, myFormData.MobileNumber).then((value) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: 'success',
          status: 200,
          message: "Edit User successful",
        });
      })
    } else if (myFormData.ConfirmNewPassword === "" || myFormData.NewPassword === "" || myFormData.OldPassword === "" ){
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: 'error',
        status: 500,
        message: "Error password cannot be blank",
      });
    } else if (myFormData.NewPassword === myFormData.ConfirmNewPassword){
      let pattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$";
      const regex = new RegExp(pattern);
      let passResult = regex.test(myFormData.NewPassword);
      if(passResult){
      UserService.changePassword(myFormData.OldPassword, myFormData.NewPassword).then((value) => {
        if(value.data !== undefined){
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: value.status===200 ? 'success' : 'error',
            status: value.status,
            message: value.data.message,
          });
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: 'error',
            status: 401,
            message: "Password not correct",
          });
        }
      })
      UserService.editUser(myFormData.FirstName, myFormData.LastName).then((value) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: 'success',
          status: 200,
          message: "User updated successfully",
        });
      })
    } else {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: 'error',
        status: 400,
        message: "Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
      });
    }
    } else {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: 'error',
        status: 401,
        message: "Error Passwords don't match",
      });
    }
    let myUser = localStorage.getItem("user")
    let updateData = JSON.parse(myUser)
    updateData.firstName = myFormData.FirstName
    updateData.lastName = myFormData.LastName
    updateData.mobileNumber = myFormData.MobileNumber
    let updatedData = JSON.stringify(updateData);
    localStorage.setItem("user", updatedData);
  }


  return (
    <div className='profile-wrapper'>
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
      <h2 >&nbsp; Profile</h2>
      <div className={'profile-formLayout'}>
        <Form
        labelLocation={"left"}
        labelMode={"outside"}
        id={'form'}
        formData={user}
        onFieldDataChanged={setData}
        >
          <Item2 dataField={"FirstName"}>
          </Item2>
          <Item2 dataField={"LastName"}>
          </Item2>
          <Item2
              dataField="MobileNumber"
              helpText="Use a phone number that begins with a plus sign and includes the corresponding country code."
            />
          <Item2 dataField={"OldPassword"} editorOptions={oldPasswordEditorOptions}>
          </Item2>
          <Item2 dataField={"NewPassword"} editorOptions={passwordEditorOptions}>
          <PatternRule
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
            message="Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character"
          />
          </Item2>
          <Item2 dataField={"ConfirmNewPassword"} editorOptions={confirmPasswordEditorOptions} validationMessagePosition="right">
          <PatternRule
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
            message="Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character"
          />
          </Item2>
        </Form>
        {/* <div style={{marginTop:"30px"}} className='profile-save' onClick={onClick}>
          SAVE
        </div>
        <div style={{marginTop:"30px"}} className='profile-save' onClick={onClick}>
          CANCEL
        </div> */}

      <div>
        <button className='profile-save' onClick={onSaveClick}>SAVE</button>
        <button className='profile-save' onClick={backClick}>CANCEL</button>
    </div>
      </div>
    </div>
  );
}