import React from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Editing,
  Texts,
  HeaderFilter,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { useHistory } from "react-router";

const valmetData = [
  { name: 'v001002', storePaikka: 1, carryPaikka: 1.2, ileKoodi: 'ILE0003808', positionNimi: 'VIRAOSA. PICK-UP TELA', terämateriaali: 'VALPOLY', teränDimensiot: '5.0*75*6070', pidintyyppi: 'K35A', status: 'In Stock', space: 'Cabinet A', teriaLaatikko: 10 },
  { name: 'v001003', storePaikka: 2, carryPaikka: 1.1, ileKoodi: 'ILE0003809', positionNimi: '1-PURISTIN YLÄ JA ALATELA', terämateriaali: 'VALPOLY', teränDimensiot: '5.0*75*6000', pidintyyppi: 'DST', status: 'In Stock', space: 'Cabinet A', teriaLaatikko: 10 },
  //{ name: 'v001004',storePaikka: 3, carryPaikka: 1.3, ileKoodi: 'ILE0016176', positionNimi: '2-PURISTIN ALATELA', terämateriaali: 'VALTECH 2', teränDimensiot: '2.0*75*5750', pidintyyppi: 'DST', status: 'In Transit', space: 'Cabinet A',teriaLaatikko: 10},
  { name: 'v001005', storePaikka: 4, carryPaikka: 1.6, ileKoodi: 'ILE0016177', positionNimi: '3-PURISTIN. 1 JA 2 TERA', terämateriaali: 'VALTECH 2', teränDimensiot: '2.0*75*5750', pidintyyppi: 'DST', status: 'In Stock', space: 'Cabinet A', teriaLaatikko: 10 },
  { name: 'v001006', storePaikka: 5, carryPaikka: 3.5, ileKoodi: 'ILE0010503', positionNimi: 'KUIVATUSOSA SYL. 1,3,5,6', terämateriaali: 'VALECO 4.1', teränDimensiot: '2.0*75*5778', pidintyyppi: 'LITEFIT.K35.LITEADAPT', status: 'In Stock', space: 'Cabinet A', teriaLaatikko: 10 },
  { name: 'v001007', storePaikka: 6, carryPaikka: 1.4, ileKoodi: 'ILE0016190', positionNimi: 'PURISTIMEN PALAUTUSTELAT', terämateriaali: 'VALTECH 2', teränDimensiot: '2.0*75*6150', pidintyyppi: 'K35A', status: 'In Production', space: '', teriaLaatikko: 0 },
  { name: 'v001008', storePaikka: 7, carryPaikka: 1.5, ileKoodi: 'ILE0005759', positionNimi: 'PINTAVIIRAN PALAUTUSTELA', terämateriaali: 'VALRX2', teränDimensiot: '2.0*75*6050', pidintyyppi: 'DST', status: 'In Stock', space: 'Cabinet A', teriaLaatikko: 10 },
  { name: 'v001009', storePaikka: 8, carryPaikka: 3.7, ileKoodi: 'ILEV000746', positionNimi: 'SYLINTERIT 66,67,68,69,70,73,74 YLHÄÄLLÄ ENNEN KUIVAINTA', terämateriaali: 'VALTECH 2', teränDimensiot: '1.5*75*5800', pidintyyppi: 'K35 LITECOMPACT', status: 'In Stock', space: 'Cabinet A', teriaLaatikko: 10 },
  { name: 'v001010', storePaikka: 9, carryPaikka: 3.3, ileKoodi: 'ILEV000749', positionNimi: 'SYLINTERIT 71,72', terämateriaali: 'VALTECH 4', teränDimensiot: '1.5*75*5800', pidintyyppi: 'LITECOMPACT.DST', status: 'Out of Stock', space: 'Dec Cabinet', teriaLaatikko: 10 },
  { name: 'v001011', storePaikka: 10, carryPaikka: 3.4, ileKoodi: 'ILE0003815', positionNimi: 'MATTAKALANTERIN ALATELA', terämateriaali: 'VALGLASS', teränDimensiot: '2.0*75*5670', pidintyyppi: 'CONFORMATIC', status: 'Out of Stock', space: 'Dec Cabinet', teriaLaatikko: 10 },
  { name: 'v001012', storePaikka: 11, carryPaikka: 1.3, ileKoodi: 'ILE0003808', positionNimi: 'VIRAOSA. PICK-UP TELA', terämateriaali: 'VALPOLY', teränDimensiot: '5.0*75*6070', pidintyyppi: 'K35A', status: 'In Stock', space: 'Cabinet A', teriaLaatikko: 10 },
  { name: 'v001013', storePaikka: 12, carryPaikka: 3.2, ileKoodi: 'ILEV000633', positionNimi: 'RULLAIN', terämateriaali: 'VALTECH 2', teränDimensiot: '1.5*75*5992', pidintyyppi: 'LITECOMPACT', status: 'In Stock', space: 'Cabinet A', teriaLaatikko: 5 },
  { name: 'v001014', storePaikka: 13, carryPaikka: 3.1, ileKoodi: 'ILE0015921', positionNimi: 'VALIKALANTERI.MATTAKALANTERI', terämateriaali: 'VALGLASS', teränDimensiot: '2.0*75*5670', pidintyyppi: 'LITECOMPACT', status: 'In Stock', space: 'Cabinet A', teriaLaatikko: 10 },
  { name: 'v001015', storePaikka: 14, carryPaikka: 3.6, ileKoodi: 'ILE0016192', positionNimi: 'KUIVATUSOSA ALASLASKUPAIKAT SYL. 13,25,39,51,65', terämateriaali: 'VALTECH 2', teränDimensiot: '2.0*75*5800', pidintyyppi: 'DST', status: 'In Stock', space: 'Cabinet A', teriaLaatikko: 0 },
]

export default function App() {
  const history = useHistory();

  function buttonColor(e) {
    if (e.column.dataField === 'status' && e.rowType !== "header") {
      if (e.data !== undefined) {
        if (e.data.status === 'In Stock') {
          e.cellElement.style.background = "#92CD52";
        }
        if (e.data.status === 'In Transit') {
          e.cellElement.style.background = "#01B1F1";
        }
        if (e.data.status === 'In Production') {
          e.cellElement.style.background = "#FCD168";
        }
        if (e.data.status === 'Out of Stock') {
          e.cellElement.style.background = "#FF0103";
        }
      }
    }
  }

  function showInfo(e) {
    if (e.column.name === 'name') {
      e.cellElement.style.color = "#337ab7";
      history.push({
        pathname: `/asset/${e.data.id}`,
        state: {
          response: ""
        }
      })
    }
  }
  return (
    <DataGrid
      onCellClick={showInfo}
      onCellPrepared={buttonColor}
      showColumnLines={true}
      allowColumnResizing={true}
      showRowLines={true}
      dataSource={valmetData}>
      <Paging enabled="false" />
      <Editing
        allowUpdating={true}
        allowDeleting={true}
        allowAdding={true}
        mode="inline"
      >
        <Texts
          confirmDeleteMessage="Are you sure you want to delete this Asset?"
        />
      </Editing>
      <FilterRow visible="true" apply-filter="true" />
      <HeaderFilter visible="showHeaderFilter" />
      <SearchPanel visible="true" width="240" placeholder="Search..." />
      <Column
        dataField={'name'}
      />
      <Column
        dataField={'storePaikka'}
      />
      <Column
        dataField={'carryPaikka'}
      />
      <Column
        dataField={'ileKoodi'}
        caption={'ILE-Koodi'}
      />
      <Column
        dataField={'positionNimi'}
      />
      <Column
        dataField={'terämateriaali'}
      />
      <Column
        dataField={'teränDimensiot'}
      />
      <Column
        dataField={'pidintyyppi'}
      />
      <Column
        dataField={'status'}
      />
      <Column
        dataField={'space'}
      />
      <Column
        dataField={'teriaLaatikko'}
        caption={'Teria/Laatikko'}
      />
    </DataGrid>
  );
}