import React, { useState, useEffect } from 'react';
import 'devextreme/data/odata/store';
import SelectBox from 'devextreme-react/select-box';
import { DateRangePicker } from 'react-date-range';
import DataGrid, {
  MasterDetail,
  Selection,
  Column,
  Scrolling,
  HeaderFilter,
  SearchPanel,
  Button,
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { useRef } from 'react';
import TenantService from "../../api/tenant.service";
import NetworkToolService from "../../api/networkTool.service";
import SpacesService from '../../api/spaces.service';
import { Toast } from 'devextreme-react/toast';
import diamondMarker from "../../assets/markers/diamond2.png";
import circle from "../../assets/markers/circle.png";
import { addDays } from 'date-fns';
import { Button as Button2 } from 'devextreme-react/button';
import { MapContainer, Marker, ImageOverlay } from 'react-leaflet'
import { latLngBounds } from 'leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {
  Tooltip,
  Polyline,
} from "react-leaflet";
import { CRS } from 'leaflet';
import "leaflet-draw/dist/leaflet.draw-src.css";
import 'leaflet/dist/leaflet.css'
import './networkTool.css'

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default function App() {
  const listRef = useRef();
  const markerRef = useRef(null)
  const messagesEndRef = useRef(null)
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [spaceData, setSpaceData] = useState([])
  const [tenants, setTenants] = useState([])
  const [anchors, setAnchors] = useState([]);
  const [mode, setMode] = useState("los")
  const [validSpace, setValidSpace] = useState([])
  const [nbors1, setNbors1] = useState()
  const [SA, setSA] = useState()
  const [popupVisible2, setPopupVisible2] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState()
  const [tagLocation, setTagLocation] = useState({ x: 0, y: 0 })
  const [selectedTag, setSelectedTag] = useState()
  const [bounds, setBounds] = useState(new latLngBounds([0, 0], [100, 100]));
  const [center, setCenter] = useState([0, 0])
  const [dataGrid1Visible, setDataGrid1Visible] = useState(false)
  const [dataGrid2Visible, setDataGrid2Visible] = useState(false)
  const [dateRangeVisible, setDateRangeVisible] = useState(false)
  const [mapVisible, setMapVisible] = useState(false)
  const [btnText, setBtnText] = useState("Select Date Range")
  const [toggleEditBool, setToggleEditBool] = useState(false)
  const [floorplanUrl, setFloorplanUrl] = useState("http://rpo.redlore.com/api/places/floorplan/a32b2298-bff7-44f8-a823-fa6b7fe1b8f7");
  const [isActive, setActive] = useState(false)
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 5000
  });
  const [state2, setState2] = useState([
    {
      startDate: addDays(new Date(), - 7),
      endDate: new Date(),
      key: 'selection',
    }
  ]);

  function checkRole() {
    let spaceConfig = false
    if (window.location.href.includes("config/device")) {
      spaceConfig = true
    }
    var role = JSON.parse(localStorage.getItem("role"));
    let canEdit = false
    for (let i = 0; i < role.length; i++) {
      if (role[i].name === "Asset Attributes") {
        if (role[i].write === true) {
          canEdit = true
        }
      }
    }
    if (canEdit && spaceConfig) {
      setToggleEditBool(true)
    }
  }

  function getSpaces() {
    let tenant = TenantService.getTenant();
    SpacesService.getSpaces(tenant).then((value) => {
      let mydata = value.data.data
      mydata.unshift({
        "name": "None",
        "id": "null"
      })
      setSpaceData(mydata)
    })
  }

  function getTenants() {
    TenantService.getTenants().then((value) => {
      setTenants(value.data.data)
    })
  }

  function getSpacesFloorplan(tenant) {
    // setFloorplanUrl("https://upload.wikimedia.org/wikipedia/commons/7/72/Pattern_Grid_16x16.png")
    SpacesService.getSpaces(tenant).then((value) => {
      let res = value.data.data
      let filteredRes = []
      for (let i = 0; i < res.length; i++) {
        if (res[i].floorplan !== null) {
          filteredRes.push(res[i])
        }
      }
      setValidSpace(filteredRes)
    })
  }

  function getTags(spaceId) {
    let zones = [spaceId]
    let zoneStr = `'${spaceId}'`
    SpacesService.getChildZones(spaceId).then((value) => {
      let children = value.data.data
      for (let i = 0; i < children.length; i++) {
        zones.push(children[i].id)
        zoneStr = zoneStr + ", " + `'${children[i].id}'`
      }
      NetworkToolService.getAnhcorsInZone(zoneStr).then((value) => {
        setData(value.data.data)
      })
    })
  }

  useEffect(() => {
    checkRole()
    getSpaces()
    getTenants()
  }, []);


  async function onRowClick2(e) {
    console.log("e!Hi",e)
    await NetworkToolService.getAnchorLocation(selectedTag).then((value) => {
      if (value.data.data !== null) {
        console.log("myvalue",value)
        setTagLocation(value.data.data.space_coordinates)
      } else {
        setTagLocation({ x: 0, y: 0 })
      }

    })
    let nbors = e.data.data.nbors
    setNbors1(e.data.nbors)
    setSA(e.data.serving)
    let anchorLocations = []
    let myLength = nbors.length
    if (myLength > 50) {
      console.log("Too much data!")
      ///@@@
      myLength = 50
    }
    for (let i = 0; i < myLength; i++) {
      await NetworkToolService.getAnchorLocation(nbors[i].address.toString()).then((value) => {
        if (value.data.data !== null) {
          console.log("getAnchorLocation", value)
          anchorLocations.push({
            address: nbors[i].address,
            losqi: nbors[i].losQuality,
            rssi: nbors[i].rssi,
            rst: nbors[i].rst,
            location: value.data.data.space_coordinates
          })
          console.log("nbors[i].losQuality",nbors[i].losQuality)
        }
      })
    }
    setAnchors(anchorLocations)
    setMapVisible(true)
    scrollToBottom()
  }

  function anchorMarkers() {
    let tagPos = [tagLocation.y, tagLocation.x]

    let anchorsFiltered = []
    for (let i = 0; i < anchors.length; i++) {
      if (anchors[i].location !== null) {
        anchorsFiltered.push(anchors[i])
      }
    }
    var tagIcon = L.icon({
      iconUrl: diamondMarker,
      iconSize: [30, 30], // size of the icon
      iconAnchor: [15, 15], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })

    var myIcon = L.icon({
      iconUrl: circle,
      iconSize: [10, 10], // size of the icon
      iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })

    let selector = mode
    return (
      <>
        {anchorsFiltered.map((anchor, i) =>
          <>
            <Marker id={anchor.address} key={i} position={[anchor.location.y, anchor.location.x]} icon={ myIcon} draggable={false} ref={markerRef}>
              <Tooltip permanent>
                {anchor.address}
              </Tooltip>
            </Marker>
            <Polyline positions={[tagPos, [anchor.location.y, anchor.location.x]]}>

              {selector === "los" ? <Tooltip >
                <b style={{ color: 'red' }}>
                  LOS: {anchor.losqi}
                </b>
              </Tooltip> : <></>}
            </Polyline>
          </>
        )}
        <Marker id={4321} key={4321} position={tagPos} draggable={false} icon={tagIcon} ref={markerRef}>
          {/* <Tooltip >
            Serial Number: 10099
          </Tooltip> */}
        </Marker>
      </>
    )
    // }
  }

  function onRowClick(e) {
    setSelectedTag(e.data.serial_number)
    setDataGrid2Visible(false)
    setMapVisible(false)
    setBtnText("Select Date Range")
    setDateRangeVisible(true)

  }

  function saveClose() {
    let from = state2[0].startDate
    let to1 = state2[0].endDate
    let to = addDays(to1, 1)
    setBtnText(`${from.toDateString()} - 
      ${to1.toDateString()}`)

    NetworkToolService.getDiagnosticsScans(selectedTag, from, to).then((value) => {
      let response = value.data.data
      setData2(response)
      console.log("getDiagnosticsScans",response)

    })


    setMapVisible(false)
    setPopupVisible2(false)
    setDataGrid2Visible(true)
  }

  function onHiding2() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }

  function getSpacesFloorplan2(spaceId) {
    console.log("spaceid", spaceId)
    try {
      SpacesService.getSpaceFloorplan(spaceId).then((value) => {
        console.log("space value", value)
        setFloorplanUrl(value)
      }).catch(error => {
        console.log("myerror",error)
      })
    } catch (error) {
      console.log(error)
      
    }
    
  }

  function getFloorplan(spaceId) {
    SpacesService.getSpaceFloorplan(spaceId).then((res) => {
      let myFloorplan = URL.createObjectURL(res.data)
      setFloorplanUrl(myFloorplan)
    })
    SpacesService.getSpace(spaceId).then((res2) => {
      let response2 = res2.data
      let refSpaceBounds = response2.data.localCoordinates
      let correctedRefSpaceBounds = []
      for (let i = 0; i < refSpaceBounds.length; i++) {
        correctedRefSpaceBounds.push([refSpaceBounds[i][1], refSpaceBounds[i][0]])
      }
      let coords = correctedRefSpaceBounds
      let xMax = 0
      let yMax = 0
      for (let i = 0; i < coords.length; i++) {
        if (coords[i][0] > xMax) {
          xMax = coords[i][0]
        }
        if (coords[i][1] > yMax) {
          yMax = coords[i][1]
        }
      }
      setBounds(new latLngBounds([0, 0], [xMax, yMax]))
      setCenter([0 + xMax / 2, 0 + yMax / 2])
    })
  }


  async function onValueChanged2(e) {
    getFloorplan(e.value)
    // getSpacesFloorplan2(e.value)
    getTags(e.value)
    getTagData(e.value)
    setDataGrid2Visible(false)
    setDateRangeVisible(false)
    setMapVisible(false)
    setDataGrid1Visible(true)
  }

  async function onValueChanged(e) {
    await setSelectedTenant(e.value)
    getSpacesFloorplan(e.value)
    setDataGrid2Visible(false)
    setDateRangeVisible(false)
    setMapVisible(false)
    setDataGrid1Visible(false)
  }

  function getTagData(selectedSpace2) {
    console.log("getTagData", selectedSpace2)
  }

  function selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  function onClick() {
    setPopupVisible2(true)
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  function onHiding() {
    setPopupVisible2(false)
  }
  function losClick() {
    setMode('los')
  }

  const dataGridRef = useRef()
  const dataGridRef2 = useRef()

  return (
    <div className='networkTool-container'>
      <div className='networkTool-spaceDropdown'>
        <div className='dateBtn'>
          <SelectBox
            placeholder="Select a Tenant"
            focusStateEnabled={false}
            width='400px'
            height={40}
            items={tenants}
            stylingMode="outlined"
            onValueChanged={onValueChanged}
            valueExpr="id"
            displayExpr="name"
          >
          </SelectBox>
        </div>
        <div className='dateBtn'>
          <SelectBox
            placeholder="Select a Space"
            focusStateEnabled={false}
            width='400px'
            height={40}
            items={validSpace}
            stylingMode="outlined"
            onValueChanged={onValueChanged2}
            valueExpr="id"
            displayExpr="name"
          >
          </SelectBox>
        </div>
      </div>
      <DataGrid
        loadPanel={false}
        showColumnLines={false}
        showRowLines={true}
        height={'400px'}
        dataSource={data}
        showBorders={false}
        onRowClick={onRowClick}
        visible={dataGrid1Visible}
        keyExpr="id"
        ref={dataGridRef}
        noDataText="No Data found"
      >
        <Selection mode="single" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible={true} width="240" placeholder="Search..." />
        <Scrolling mode="infinite" />
        <Column
          dataField={'serial_number'}
          caption={'Serial Number'}
          width={190}
          allowHeaderFiltering={false}
          allowEditing={false}
        />
       
        <Column
          dataField={'last_update'}
          dataType="datetime"
          format="EEE MMM dd yyyy, hh:mm a"
          caption={'Last Update'}
          allowFiltering={false}
          allowEditing={false}
        />
        <Column type="buttons" visible={toggleEditBool} width={150}>
          <Button name="edit" />
        </Column>
      </DataGrid>
      <div className={dateRangeVisible ? 'dateBtn3' : 'dateBtn3-none'}>
        <div className={isActive ? 'customBtnAfter' : 'customBtn'} onClick={onClick}>
          {btnText} <i className="dx-icon-event"></i>
        </div>
      </div>
      <Popup
        visible={popupVisible2}
        onHiding={onHiding}
        dragEnabled={false}
        height={560}
        width={960}
        showCloseButton={true}
        showTitle={true}
        title="Select Date Range"
        container=".dx-viewport"
      >
        <div className='dateRange'>
          <DateRangePicker
            onChange={item => {
              setState2([item.selection])
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state2}
            direction="horizontal"
            hasCustomRendering={true}
          />
          <div className='saveBtn3' >
            {/* <Button2
              width={200}
              height={50}
              text="Text"
              type="normal"
              stylingMode="outlined"
              onClick={saveClose}
            >
              Save
            </Button2> */}
            <button className='profile-save' onClick={saveClose}>SAVE</button>
          </div>
        </div>
      </Popup>
      <DataGrid
        loadPanel={false}
        showColumnLines={false}
        showRowLines={true}
        height={'400px'}
        dataSource={data2}
        showBorders={false}
        onRowClick={onRowClick2}
        visible={dataGrid2Visible}
        // onSelectionChanged={selectionChanged}
        keyExpr="time"
        ref={dataGridRef2}
        noDataText="No Data Found"
      >
        <Selection mode="single" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible={true} width="240" placeholder="Search..." />
        <Scrolling mode="infinite" />
        
        <Column
          dataField={'time'}
          dataType="datetime"
          format="EEE MMM dd yyyy, hh:mm a"
          caption={'Time'}
          allowFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'data.sequence'}
          caption={'Sequence'}
          allowEditing={false}
          alignment="left"
          
        />
        <Column
          dataField={'data.nborsNum'}
          caption={'Number of Neighbours'}
          allowEditing={false}
          alignment="left"
          
        />

        <Column type="buttons" visible={toggleEditBool} width={150}>
          <Button name="edit" />
        </Column>
      </DataGrid>
      <div style={{ height: '100%', width: '100%' }} ref={listRef} className={mapVisible ? 'networkTool-visible' : 'dateBtn3-none'}>
        <div className='networkTool-btnsContainer'>
          <div onClick={losClick} className={mode === "los" ? 'networkTool-btnsActive' : 'networkTool-btns'}>
            LOS Quality
          </div>
        </div>
        <MapContainer
          id="map"
          center={center}
          style={{ height: '800px', width: '100%' }}
          zoom={2}
          min-zoom={4.2}
          maxZoom={7}
          zoomSnap={0}
          crs={CRS.Simple}
        >
          <ImageOverlay
            url={floorplanUrl}
            bounds={bounds}
            style={{ height: '100px', width: '100px' }}
            opacity={1}
            zIndex={10}
          />
          {anchorMarkers()}
          <div className='legend'>
            <div className='legendText'>
            <img  src={diamondMarker} className='networkTool-logo' alt=''/><br></br>
            <img  src={circle} className='networkTool-logo' alt=''/><br></br>
            </div>
            <div className='legendTextBeside'>
              <div className='legendTextBesideEach'> 
              Anchor Position
              </div>
              <div className='legendTextBesideEach'> 
              Nbor Anchor
              </div>
            </div>
          </div>
        </MapContainer>
      </div>
      <div ref={messagesEndRef} />
      <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding2}
        displayTime={toastConfig.displayTime}
      />
    </div>
  )
}