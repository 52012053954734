import axios from 'axios';

const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token) {
        localStorage.setItem('token', null);
    } else {
        return token;
    }
}

const errorHandler = (error) => {
    if (error.response && error.response.status && error.response.status === 401 && window) {
        localStorage.setItem('token', null);
        localStorage.clear();
        window.location.href = '/';
    } else {
        let myError = error
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.status === 500 || error.response.data.status === 422) {
                    myError = error.response.data
                }
            }
        }
        return myError;
    }
}

const get = async (url) => {
    try {
        const token = getToken();
        const config = {
            headers: {
                'x-access-token': token
            }
        }
        const result = await axios.get(url, config);
        return result;
    } catch (error) {
        return errorHandler(error)
    }
}

const getImage = async (url, body) => {
    try {
        const token = getToken();
        const config = {
            headers: {
                'x-access-token': token
            },
            responseType: 'blob'
        }
        const result = await axios.post(url, body, config);
        return result;
    } catch (error) {
        return errorHandler(error)
    }
}

const post = async (url, body) => {
    try {
        const token = getToken();
        const config = {
            headers: {
                'x-access-token': token
            }
        }
        const result = await axios.post(url, body, config);
        return result;
    } catch (error) {
        return errorHandler(error)
    }
}

const del = async (url, data) => {
    try {
        const token = getToken();
        const config = {
            headers: {
                'x-access-token': token
            },
            data
        }
        const result = await axios.delete(url, config);
        return result;
    } catch (error) {
        return errorHandler(error)
    }
}

const patch = async (url, data) => {
    try {
        const token = getToken();
        const config = {
            headers: {
                'x-access-token': token
            }
        }
        const result = await axios.patch(url, data, config);
        return result;
    } catch (error) {
        return errorHandler(error)
    }
}

export { get, post, del, getToken, getImage, patch }
