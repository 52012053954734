

import React from 'react';

const Blue = () => {
  return (

<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="14px" height="14px"><linearGradient id="ha16qtydoB37A98aizz0Sa" x1="15.002" x2="33.584" y1="6.494" y2="42.647" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#2aa4f4"/><stop offset="1" stopColor="#007ad9"/></linearGradient><path fill="url(#ha16qtydoB37A98aizz0Sa)" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/></svg>
  )
}
export default Blue;
