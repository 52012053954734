import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  Selection,
  FilterPanel
} from 'devextreme-react/data-grid';
import moment from 'moment-timezone';
import SelectBox from 'devextreme-react/select-box';
import CheckBox from 'devextreme-react/check-box';
import TenantService from "../../api/tenant.service";
import AssetsService from "../../api/assets.service";
import * as DOMPurify from 'dompurify';
import FloorplanPopup from '../components/floorplan'
import { DateBox } from 'devextreme-react/date-box';
import './asset.css'
import Toast from 'components/Toast';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import DataSource from 'devextreme/data/data_source';
import hash from 'hash.js';

export default function App(props) {
  const assetId = props.assetId
  const assetName = props.assetName
  const [data, setData] = useState([])
  const [spaces, setSpaces] = useState([])
  const [tags, setTags] = useState([])
  const [selectedSpace2, setSelectedSpace2] = useState()
  const [showMyFloorplan, setShowMyFloorplan] = useState(true)
  const [showMyFloorplan2, setShowMyFloorplan2] = useState(true)
  const tenant = TenantService.getTenant()
  const [lines, setLines] = useState(true)
  const dropDataGridRef = useRef();
  const [selectedValue, setSelectedValue] = useState('Last 24 hours');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dateBoxRefStart = useRef(null);
  const dateBoxRefEnd = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingText, setLoadingText] = useState('Loading...');
  const [focusedRowKey, setFocusedRowKey] = useState(null);
  // const [totalDistance, setTotalDistance] = useState(0);
  // const scrollPositionRef = useRef({ top: 0, left: 0 });
  // const [hoveredRowData, setHoveredRowData] = useState(null);
  const [hoveredCoordinates, setHoveredCoordinates] = useState(null);
  // const [scrollPosition, setScrollPosition] = useState({ left: 0, top: 0 });
  const [isDisableAutoUpdate, setIsDisableAutoUpdate] = useState(false)
  // const [checkBoxValue, setCheckBoxValue] = useState(true);
  const [checkAutoUpdate, setCheckAutoUpdate] = useState(true);
  const WAIT_TIME = 10000;
  const intervalRef = useRef(null);
  const isUserInteracting = useRef(false); // Track user interaction

  // const dataSource = new DataSource({
  //   store: [],
  //   key: 'id',
  //   searchMode: 'delay', // Use 'delay' mode for better user experience
  //   searchTimeout: 800, // Delay in milliseconds
  // })

  // const dataSource = new DataSource({
  //   store: {
  //     type: 'array',
  //     key: 'id', // Ensure this is a unique field in your data
  //     data,  // Initialize with an empty array
  //   },
  // });

  const clearDataSource = () => {
    // Set the data to an empty array
    dataSource.store().clear(); // Clears all data in the store
    dataSource.reload(); // Refresh the DataSource to reflect the changes
  };

  const dataSource = useMemo(() => new DataSource({
    store: {
      type: 'array',
      key: 'id',
      data: [],
    },
  }), []);
  

  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 15000,
  });

  // useEffect(() => {
  //   if (checkAutoUpdate) {
  //     console.log("Auto-update enabled");
  //     intervalRef.current = setInterval(() => {
  //       const periodVal = localStorage.getItem(`${tenant}dateFilterText`);
  //       handleValueChangeRecursive(periodVal);
  //       getData(selectedSpace2);
  //     }, WAIT_TIME);
  //   } else {
  //     console.log("Auto-update disabled");
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //   }
  //   // Cleanup on unmount
  //   return () => clearInterval(intervalRef.current);
  // }, [checkAutoUpdate]);
  
  function isEndDateWithinLastThreeMinutes(endDate) {
    const now = moment(); // Current time
    const lastThreeMinutesFromNow = moment().subtract(3, 'minutes'); // 3 minutes from now

    const endMoment = moment(endDate); // Convert endDate to a moment instance

    console.log('NOW: ', now);
    console.log('endDate: ', endMoment);
    console.log('lastThreeMinutesFromNow: ', lastThreeMinutesFromNow)
    console.log('RESULT: ', endMoment.isBetween(lastThreeMinutesFromNow, now));

    return endMoment.isBetween(lastThreeMinutesFromNow, now);
  }


  useEffect(() => {
    const startAutoUpdate = () => {
      console.log("Starting auto-update");
      intervalRef.current = setInterval(() => {
        const periodVal = localStorage.getItem(`${tenant}dateFilterText`);
        if (periodVal !== 'Yesterday' && periodVal !== 'Last Week' && periodVal !== 'Last Month'){
          if (periodVal === 'Custom'){
            const callAPIForCustom = isEndDateWithinLastThreeMinutes(endDate)
            if (callAPIForCustom) {
              handleValueChangeRecursive(periodVal);
              getData(selectedSpace2);
            }
          } else {
            handleValueChangeRecursive(periodVal);
            getData(selectedSpace2);
          }
          
        }
        
      }, WAIT_TIME);
    };

    const stopAutoUpdate = () => {
      console.log("Stopping auto-update");
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };

    if (checkAutoUpdate) {
      stopAutoUpdate(); // Clear any previous intervals to avoid duplicates
      startAutoUpdate();
    } else {
      stopAutoUpdate();
    }

    // Cleanup on unmount
    return () => stopAutoUpdate();
  }, [checkAutoUpdate, selectedSpace2]);

  function generateDeterministicId(record) {
    return hash.sha256().update(`${record.time}-${record.motion}`).digest('hex');
  }

  function appendAssetsData(newRecords){
    console.log('NEW RECORS: ', newRecords);

    newRecords.forEach((record) => {
      record.id = generateDeterministicId(record); // Replace uuid with a deterministic id
    });
    console.log('NEW RECORDS2: ', newRecords);
    
    // const store = dataSource.store();
    // // Insert each record into the store
    // newRecords.forEach((record) => {
    //   store.insert(record);
    // });
    // dataSource.load()
    const store = dataSource.store();

  newRecords.forEach((record) => {
    store.byKey(record.id)
      .then(() => {
        console.log('Duplicate record found, skipping:', record);
      })
      .catch(() => {
        // Only insert if the key doesn't exist
        try {
          store.insert(record);
        } catch (error) {
          console.log('Error inserting record:', record, error);
        }
      });
  });

  dataSource.reload();


  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => 
      item.id === arr2[index].id &&
      item.serialNumber === arr2[index].serialNumber &&
      item.spaceCoordinates.x === arr2[index].spaceCoordinates.x &&
      item.spaceCoordinates.y === arr2[index].spaceCoordinates.y
    );
  };
  
  let markers = [];
  if (newRecords && newRecords.length > 0) {
    console.log('no loading text');
    // setLoadingText("Loading...");
    for (let i = 0; i < newRecords.length; i++) {
      if (newRecords[i].isFloorplan && newRecords[i].space.localCoordinates !== null) {
        markers.push({
          parentId: newRecords[i].id,
          id: newRecords[i].space.id,
          serialNumber: moment(newRecords[i].time).format("YYYY-MM-DD HH:mm:ss"),
          spaceCoordinates: {
            x: newRecords[i].space.localCoordinates.x,
            y: newRecords[i].space.localCoordinates.y
          }
        });
      }
    }
  
    const sortedArray = markers.sort((a, b) => new Date(a.serialNumber) - new Date(b.serialNumber));
  
      // Compare sortedArray with current tags
      if (!areArraysEqual(sortedArray, tags)) {
        console.warn('UPdating tags');
        setTags(sortedArray); // Update state only if there are changes
      } else {
        console.log("No new data, skipping update.");
      }
    } else {
      console.log('no loading text 211');
      setLoadingText("No Data");
      clearDataSource();
      setTags([]);
    }
  
  }

  async function resetData(){
    setShowMyFloorplan(false)
    setShowMyFloorplan2(false)
    setIsDisableAutoUpdate(false)
    console.log('no loading text 77');
    setLoadingText('Loading...')
    setSelectedRowKeys([])
    setSelectedSpace2()
    setTags([])
    setData([])
    clearDataSource()
    setSpaces([])
    // setTotalDistance(0)
  }


  function handleDataChanges(reload=false){
    if (reload){
      setShowMyFloorplan(false)
      setShowMyFloorplan2(false)
      console.log('no loading text 98');
      // setLoadingText('Loading...')
      setSelectedRowKeys([])
      setSelectedSpace2()
      setTags([])
      setData([])
      clearDataSource()
      setSpaces([])
    }
    
    // setTotalDistance(0)
  }

  function getSpaces() {
    const from = moment(localStorage.getItem(`${tenant}dateFilterFrom`)).utc().format();
    const to = moment(localStorage.getItem(`${tenant}dateFilterTo`)).utc().format();
    if (from === "Invalid date" || to === "Invalid date"){
      setLoadingText("No Data");
      return true;
    }
    AssetsService.locationHistory(assetId, from, to, null, "asset").then((value) => {
      console.log('aset-location-history 82');
      if (value?.data?.data?.floorplans?.length !== 0) {
        setSelectedSpace2(value.data.data.floorplans[0].id)
        setShowMyFloorplan(true)
        setShowMyFloorplan2(true)
        // console.log('log 124');
        // console.log('START DATE&END DATE 125: ', startDate, endDate);
        getData(value.data.data.floorplans[0].id)
      } else {
        console.log('No floorplan');
        setShowMyFloorplan(false);
        setShowMyFloorplan2(false)
        console.log('no loading text 125');
        setLoadingText("No Data");
      }
      setSpaces(value.data.data.floorplans);
      props.onSpaceLoad(value.data.data.floorplans);
    })
  }

  function getData(space) {
    if (space === undefined || space === null) return;
    const from = moment(localStorage.getItem(`${tenant}dateFilterFrom`)).utc().format();
    const to = moment(localStorage.getItem(`${tenant}dateFilterTo`)).utc().format();
    
    if (from !== null && to !== null){
      AssetsService.locationHistory(assetId, from, to, space, "asset").then((value) => {
        if (value.data.data.floorplans.length !== 0) {
          setShowMyFloorplan(true)
          setShowMyFloorplan2(true)
        } else {
          setShowMyFloorplan(false)
          setShowMyFloorplan2(false)
        }
        if (value.data && value.data.data && value.data.data.history.length > 0) {
          console.log('327')
          setLoadingText('Loading...')
          appendAssetsData(value.data.data.history);
        } else {
          setLoadingText('No Data')
        }
        
        // setData(value.data.data.history);
        // setTotalDistance(value.data.data.totalDistance);
        if(value?.data?.data?.message){
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: value?.status===200 ? 'success-warning' : 'error', 
            status: value?.data?.status ? 320 : value.status,
            message: value?.data?.data?.message ? value?.data.data.message : value.message,
          });
        }
        
        // let markers = []
        // if (value.data && value.data.data && value.data.data.history.length > 0) {
        //   console.log('no loading text');
        //   // setLoadingText("Loading...");
        //   for (let i = 0; i < value.data.data.history.length; i++){
        //     if (value.data.data.history[i].isFloorplan && value.data.data.history[i].space.localCoordinates !== null){
        //       markers.push({
        //         parentId: value.data.data.history[i].id,
        //         // highlight:false,
        //         id: value.data.data.history[i].space.id,
        //         serialNumber: moment(value.data.data.history[i].time).format("YYYY-MM-DD HH:mm:ss"),// myTime.toString(),
        //         spaceCoordinates: {
        //           x: value.data.data.history[i].space.localCoordinates.x,
        //           y: value.data.data.history[i].space.localCoordinates.y
        //         }
        //       })
        //     }
        //   }
        //   const sortedArray = markers.sort((a, b) => new Date(a.serialNumber) - new Date(b.serialNumber));
        //   setTags(sortedArray)
        // } else {
        //   console.log('no loading text 211');
        //   setLoadingText("No Data");
        //   // setShowZonesBtn(false)
        //   setTags([])  
        //   // setTotalDistance(0)
        // }
      })
    } 
  }

  const onTaskIdChanged = useCallback((val) => {
    setFocusedRowKey(val);
  }, []);

  const highlightRow = (rowIndex) => {
    const dataGridInstance = dropDataGridRef.current.instance.option('dataSource');
    const dataLength = dataGridInstance.length
    const rowId = dataGridInstance[dataLength - 1 - rowIndex].id
    const rowIndexFound = dataGridInstance.findIndex(obj => obj.id === rowId);
    dropDataGridRef.current.instance.selectRows([rowId]);
    if (rowIndexFound !== -1){
      onTaskIdChanged(dataGridInstance[rowIndexFound].id)
    }
  }

  function showFloorplan() {
    // if (selectedSpace2 !== undefined) {
    //   SpacesService.getSpace(selectedSpace2).then((value) => {
    //     let response = value.data
    //     if (response?.data?.referenceSpace !== null) {
    //       setShowMyFloorplan2(true)
    //     }
    //     else {
    //       setShowMyFloorplan2(false)
    //     }
    //   })
    // }
    if (selectedSpace2 !== undefined && tags !== undefined) {
      return (
        <FloorplanPopup hoveredCoordinates={hoveredCoordinates} spaceId={selectedSpace2} myMarkers={tags} type={lines ? 'polyline' : 'polyline2'} zoneEditControl={false} highlightRow={highlightRow}>
        </FloorplanPopup>
      )
    }
  }


  const onSelectionChanged = useCallback(({ selectedRowKeys: changedRowKeys, selectedRowsData }) => {
    console.log('HELLO', changedRowKeys);
    // console.log('ROWS: ', selectedRowsData);
      setSelectedRowKeys(changedRowKeys);
      if (selectedRowsData.length > 0){
        let markers = []
      for (let i = 0; i < selectedRowsData.length; i++) {
        // let myTime = new Date(selectedRowsData[i].time);
        if (selectedRowsData[i].isFloorplan && selectedRowsData[i].space && selectedRowsData[i].space.localCoordinates !== null) {
          markers.push({
            parentId: selectedRowsData[i].id,
            // highlight:false,
            id: selectedRowsData[i].space.id,
            serialNumber: moment(selectedRowsData[i].time).format("YYYY-MM-DD HH:mm:ss"), //myTime.toString(),
            spaceCoordinates: {
              x: selectedRowsData[i].space.localCoordinates.x,
              y: selectedRowsData[i].space.localCoordinates.y
            }
          });
        }
      }
      const sortedArray = markers.sort((a, b) => new Date(a.serialNumber) - new Date(b.serialNumber));
      console.log('SET TAGS 281:', sortedArray);
      setTags(sortedArray);
      } else {
        let markers = []
        const dataItems = dropDataGridRef.current.instance.option('dataSource');
        for (let i = 0; i < dataItems.length; i++) {
          // let myTime = new Date(selectedRowsData[i].time);
          if (dataItems[i].isFloorplan && dataItems[i].space && dataItems[i].space.localCoordinates !== null) {
            markers.push({
              parentId: dataItems[i].id,
              // highlight:false,
              id: dataItems[i].space.id,
              serialNumber: moment(dataItems[i].time).format("YYYY-MM-DD HH:mm:ss"), //myTime.toString(),
              spaceCoordinates: {
                x: dataItems[i].space.localCoordinates.x,
                y: dataItems[i].space.localCoordinates.y
              }
            });
          }
        }
      const sortedArray = markers.sort((a, b) => new Date(a.serialNumber) - new Date(b.serialNumber));
      setTags(sortedArray);
      }
      
    },
    [],
  );

  useEffect(() => {
    // Fetch data when the component mounts
    getSpaces();
  }, []);

  useEffect(() => {
    console.log('checkauto', checkAutoUpdate);
    
    if (startDate && endDate && !checkAutoUpdate) {
      getSpaces();
    }
}, [startDate, endDate]);

  const handleStartDate = () => {
    dateBoxRefStart.current.instance.open()
    setSelectedValue("Custom")
  }

  const handleEndDate = () => {
    isUserInteracting.current = true;
    dateBoxRefEnd.current.instance.open()
    setSelectedValue("Custom")
  }

  const handleStartDateChange = async (value) => {
    // setCheckAutoUpdate(false)
    await setStartDate(value);
    if (value && endDate && moment(value).isAfter(endDate)) {
      await setEndDate(moment(value).add(1, 'days').toDate());
      localStorage.setItem(`${tenant}dateFilterTo`, moment(value).add(1, 'days').toDate())
    }
    localStorage.setItem(`${tenant}dateFilterFrom`, moment(value).format())

    if (endDate) {
      localStorage.setItem(`${tenant}dateFilterTo`, moment(endDate).format());
    }
    handleDataChanges(true)
    getSpaces()
  };

  const handleEndDateChange = async (value) => {
    // setCheckAutoUpdate(false)
    if (isUserInteracting.current) {

      await setEndDate(value);
      if (value && startDate && moment(value).isBefore(startDate)) {
        console.log('start date 361: ', value);
        await setStartDate(moment(value).subtract(1, 'days').toDate());
        localStorage.setItem(`${tenant}dateFilterFrom`, moment(value).subtract(1, 'days').toDate())
      }
      localStorage.setItem(`${tenant}dateFilterTo`, moment(value).format())
      // getAnchors()
      if (startDate) {
        localStorage.setItem(`${tenant}dateFilterFrom`, moment(startDate).format());
      }
      handleDataChanges(true)
      getSpaces()
      isUserInteracting.current = false; // Reset after handling
    }
  };

  function onFloorplanValueChanged(e) {
    setSelectedSpace2(e.value)
    getData(e.value)
  }

  const handleValueChange = async (value) => {
    await resetData();
    console.log('value: ', value);
    setSelectedValue(value);
    if (value === 'Custom') {
      localStorage.setItem(`${tenant}dateFilterText`, value);
    } else {
      setStartDate(null);
      setEndDate(null);
      let start, end;
      switch (value) {
        case 'Last 24 hours':
          start = moment().subtract(24, 'hours').format();
          end = moment().format();
          break;
        case 'Today':
          start = moment().startOf('day').format();
          end = moment().endOf('day').format();
          break;
        case 'Last 5 min':
          start = moment().subtract(5, 'minutes').format();
          end = moment().format();
          break;
        case 'Last 20 min':
          start = moment().subtract(20, 'minutes').format();
          end = moment().format();
          break;
        case 'Last 1 hour':
          start = moment().subtract(1, 'hour').format();
          end = moment().format();
          break;
        case 'Last 4 hours':
          start = moment().subtract(4, 'hours').format();
          end = moment().format();
          break;
        case 'Last 12 hours':
          start = moment().subtract(12, 'hours').format();
          end = moment().format();
          // if (moment(end).diff(start, 'hours') <= 12) {
          //   end = moment().endOf('hour').format(); // Set end to end of current hour
          // }
          break;
        case 'Yesterday':
          start = moment().subtract(1, 'day').startOf('day').format();
          end = moment().subtract(1, 'day').endOf('day').format();
          setCheckAutoUpdate(false)
          setIsDisableAutoUpdate(true)
          break;
        case 'This Week':
          start = moment().startOf('week').format();
          end = moment().endOf('week').format();
          break;
        case 'Last Week':
          start = moment().subtract(1, 'weeks').startOf('week').format();
          end = moment().subtract(1, 'weeks').endOf('week').format();
          setCheckAutoUpdate(false)
          setIsDisableAutoUpdate(true)
          break;
        case 'This Month':
          start = moment().startOf('month').format();
          end = moment().endOf('month').format();
          break;
        case 'Last Month':
          start = moment().subtract(1, 'month').startOf('month').format();
          end = moment().subtract(1, 'month').endOf('month').format();
          setCheckAutoUpdate(false)
          setIsDisableAutoUpdate(true)
          break;
        // case 'Last 1 day':
        //   start = moment().subtract(1, 'day').format();
        //   end = moment().format();
        //   break;
        case 'Last 7 days':
          start = moment().subtract(7, 'days').format();
          end = moment().format();
          break;
        default:
          break;
      }
      console.log('start 510: ', start, ',end: ', end);
      await setStartDate(start);
      await setEndDate(end);
      localStorage.setItem(`${tenant}dateFilterFrom`, start)
      localStorage.setItem(`${tenant}dateFilterTo`, end)
      localStorage.setItem(`${tenant}dateFilterText`, value);
    }
  };

  const handleValueChangeRecursive = async (value) => {
      // setSelectedValue(value);
      let start, end;
      switch (value) {
        case 'Last 24 hours':
          start = moment().subtract(24, 'hours').format();
          end = moment().format();
          break;
        case 'Today':
          start = moment().startOf('day').format();
          end = moment().endOf('day').format();
          break;
        case 'Last 5 min':
          start = moment().subtract(5, 'minutes').format();
          end = moment().format();
          break;
        case 'Last 20 min':
          start = moment().subtract(20, 'minutes').format();
          end = moment().format();
          break;
        case 'Last 1 hour':
          start = moment().subtract(1, 'hour').format();
          end = moment().format();
          break;
        case 'Last 4 hours':
          start = moment().subtract(4, 'hours').format();
          end = moment().format();
          break;
        case 'Last 12 hours':
          start = moment().subtract(12, 'hours').format();
          end = moment().format();
          // if (moment(end).diff(start, 'hours') <= 12) {
          //   end = moment().endOf('hour').format(); // Set end to end of current hour
          // }
          break;
        // case 'Yesterday':
        //   start = moment().subtract(1, 'day').startOf('day').format();
        //   end = moment().subtract(1, 'day').endOf('day').format();
        //   break;
        case 'This Week':
          start = moment().startOf('week').format();
          end = moment().endOf('week').format();
          break;
        // case 'Last Week':
        //   start = moment().subtract(1, 'weeks').startOf('week').format();
        //   end = moment().subtract(1, 'weeks').endOf('week').format();
        //   break;
        case 'This Month':
          start = moment().startOf('month').format();
          end = moment().endOf('month').format();
          break;
        // case 'Last Month':
        //   start = moment().subtract(1, 'month').startOf('month').format();
        //   end = moment().subtract(1, 'month').endOf('month').format();
        //   break;
        case 'Last 7 days':
          start = moment().subtract(7, 'days').format();
          end = moment().format();
          break;
        case 'Custom':
          end = moment().format();
          localStorage.setItem(`${tenant}dateFilterTo`, moment(end).format());
          break;
        default:
          break;
    }
    console.log('start 510: ', start, ',end: ', end);
      // if (start) setStartDate(start);
      setEndDate(end);
      localStorage.setItem(`${tenant}dateFilterTo`, moment(end).format())
      localStorage.setItem(`${tenant}dateFilterText`, value);
  };

  const downloadReport = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Asset');
    exportDataGrid({
      component: dropDataGridRef.current.instance,
      worksheet
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Asset-${assetName}-${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`);
      });
    });
  };

  useEffect(() => {
    const periodVal = localStorage.getItem(`${tenant}dateFilterText`);
    if (periodVal !== null && periodVal !== 'null') {
      console.log('period val: ', periodVal);
      if (periodVal === 'Custom') {
          const startDate = moment(localStorage.getItem(`${tenant}dateFilterFrom`));
          const endDate = moment(localStorage.getItem(`${tenant}dateFilterTo`));
          setStartDate(startDate);
          setEndDate(endDate);
      }
      handleValueChange(periodVal);
    } else {
      handleValueChange('Last 24 hours');
    }
    getSpaces();
}, [props.selectedTabIndex]);

const handleCellHoverChanged = useCallback((e) => {
  if (e.eventType === 'mouseover') {
    console.log('E DATA: ', e.data);
    
    // setHoveredRowData(e.data);
    if (e.data && e.data.space && e.data.space.localCoordinates){
      setHoveredCoordinates(e.data.space.localCoordinates)
    }
    
  } else if (e.eventType === 'mouseout') {
    // setHoveredRowData(null);
    setHoveredCoordinates(null);
  }
}, []);

  const onAutoUpdateValueChange = useCallback((args) => {
    // setCheckBoxValue(args.value);
    const periodVal = localStorage.getItem(`${tenant}dateFilterText`);
    if (periodVal !== 'Yesterday' && periodVal !== 'Last Week' && periodVal !== 'Last Month'){
      setCheckAutoUpdate(args.value)
    } else {
      setCheckAutoUpdate(false)
      setIsDisableAutoUpdate(true)
    }
    
  }, []);


  return (
    <>
    <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
    <div className='assetContainer'>
      <div className='btnContainer'>
        <div className='dateBtn'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SelectBox
              style={{ marginRight: '10px', width: '20%' }}
              dataSource={['Today', 'Last 5 min', 'Last 20 min', 'Last 1 hour', 'Last 4 hours', 'Last 12 hours', 'Last 24 hours', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Last 7 days', 'Custom']}
              value={selectedValue}
              // onValueChanged={handleValueChange}
              onValueChanged={(e) => handleValueChange(e.value)}
              labelMode="static"
              label="Period"
              stylingMode="outlined"
            />
            <div onClick={() => handleStartDate()} >
              <DateBox
                // applyValueMode="instantly"
                ref={dateBoxRefStart}
                style={{ marginRight: '10px'}}
                // disabled={manageDateBox}
                type="datetime"
                value={startDate}
                // onValueChanged={(e) => handleStartDateChange(e.value)}
                onValueChanged={async (e) => await handleStartDateChange(e.value)}

                placeholder="Start Date"
                labelMode="static"
                label="Start Date"
                stylingMode="outlined"
                displayFormat="yyyy-MM-dd HH:mm"
                valueFormat="yyyy-MM-dd HH:mm"
              />
            </div>
            <div onClick={() => handleEndDate()}>
              <DateBox
                ref={dateBoxRefEnd}
                // applyValueMode="instantly"
                style={{ marginRight: '10px'}}
                // disabled={manageDateBox}
                type="datetime"
                value={endDate}
                // onValueChanged={(e) => handleEndDateChange(e.value)}
                onValueChanged={async (e) => await handleEndDateChange(e.value)}
                placeholder="End Date"
                labelMode="static"
                label="End Date"
                stylingMode="outlined"
                displayFormat="yyyy-MM-dd HH:mm"
                valueFormat="yyyy-MM-dd HH:mm"
              />
            </div>
          
      {/* </div>
      <div> */}
          <SelectBox
            style={{ marginRight: '10px'}}
            labelMode="static"
            label="Floorplan"
            items={spaces}
            stylingMode="outlined"
            value={selectedSpace2}
            onValueChanged={onFloorplanValueChanged}
            // onSelectionChanged={(e) => onFloorplanValueChanged(e)}
            valueExpr="id"
            displayExpr="name"
          >
          </SelectBox>
          <CheckBox 
            value={checkAutoUpdate} 
            text="Auto Update" 
            disabled={isDisableAutoUpdate} 
            stylingMode="outlined" 
            labelMode="static"
            onValueChanged={onAutoUpdateValueChange}
            style={{cursor:"pointer"}}
            className='check-auto-update'
          >
          </CheckBox>

          <div className='uploadBtnMechanicalReport'>
            <button id="generatePdfButton" alt="Export Data" title="Export Data" type="button" onClick={downloadReport}>
              <img src="../icons/excelNew.svg" alt="Export Data" style={{height: "24px", width:"24px"}} />
            </button>
          </div>
          
          {/* <Button
            width={"160px"}
            height={"88%"}
            style={{marginTop: "5px",  marginRight: '10px'}}
            type="normal"
            stylingMode="outlined"
            onClick={clearSelection}
            text="Clear Filters"
            disabled={isDisabled}
          /> */}
        </div>
        </div>
      </div>
      {/* <div className='dg-text-above'>
        <span>Total Distance: {totalDistance}m</span>
      </div> */}
      <section className='column_two'>
      <DataGrid
        height={(showMyFloorplan && showMyFloorplan2) ? 'calc(100vh - 303px)' : 'calc(100vh - 329px)' }
        // dataSource={data}
        dataSource={dataSource}
        ref={dropDataGridRef}
        columnAutoWidth={true}
        allowColumnResizing={true}
        showBorders={true}
        // autoNavigateToFocusedRow={autoNavigateToFocusedRow}
        // focusedRowKey={focusedRowKey}
        // onFocusedRowChanging={onFocusedRowChanging}
        focusedRowKey={focusedRowKey}
        onFocusedRowChanged={e => setFocusedRowKey(e.row ? e.row.id : null)}
        selectedRowKeys={selectedRowKeys}
        onSelectionChanged={onSelectionChanged}
        // onRowClick={(e)=> handleSelectionChange(e)}
        // onRowClick={handleRowClick}
        // hoverStateEnabled={true}
        noDataText={loadingText}
        // keyExpr="id"
        onCellHoverChanged={handleCellHoverChanged}
        scrolling={{ mode: 'standard', showScrollbar: 'always' }}
        repaintChangesOnly={true}
      >
        {/* <Export enabled={true} allowExportSelectedData={true} /> */}
        {/* <Scrolling mode="virtual" /> */}

        <Selection
          mode="multiple"
          showCheckBoxesMode="none"
        />
        <FilterPanel visible={true} texts={{createFilter: '', clearFilter:'Clear Filter', filterEnabledHint: ''}}/>
        <Paging enabled={false} />
        <FilterRow visible="true" apply-filter="true" />
        <HeaderFilter visible="showHeaderFilter" />
        <Column
          allowSorting={true}
          dataField={DOMPurify.sanitize('time')}
          caption={'Date'}
          dataType="datetime"
          format="yyyy-MM-dd HH:mm"
          allowFiltering={false}
          defaultSortIndex={1}
          defaultSortOrder="desc"
          // width={"40%"}
          fixed={true}
          hidingPriority={8}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('space.name')}
          caption={'Space'}
          fixed={true}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('motion')}
          caption={'Event'}
          fixed={true}
          width={"40%"}
          alignment="left"
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('distance')}
          caption={'Distance [m]'}
          fixed={true}
          alignment="left"
        >
        </Column>
      </DataGrid>
      <div className={(showMyFloorplan && showMyFloorplan2) ? 'showFloorplan' : 'hideFloorplan'}>
        {showFloorplan()}
      </div>
      <div className='dg-text'>
        <span>Use the CTRL/CMD keys to select multiple rows</span>
      </div>
      </section>
      </div>
    </>
  );
}