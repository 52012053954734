import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import Chart, { Tick, CommonSeriesSettings, Series, ArgumentAxis, ValueAxis, Label, Size, Tooltip } from 'devextreme-react/chart';
import TreeList, { Column, SearchPanel, Selection } from 'devextreme-react/tree-list';
import AssetsService from "../../../api/assets.service";
import TenantService from 'api/tenant.service';
import { SelectBox } from 'devextreme-react';
import { useHistory } from "react-router-dom";
import { Title } from 'devextreme-react/bar-gauge';


export default function AssetsAnalyze(props) {
    const history = useHistory();
    // const [groups, setGroups] = useState([{"id":"process_state", "name":"Process State"}]);
    const [groups, setGroups] = useState([{"id":"process_state", "name":"Process State"},{"id":"space", "name":"Space"}]);
    const [attributes, setAttributes] = useState([{id:'dwell_time', name: 'Dwell Time'}]);
    // const [attributes, setAttributes] = useState([{id:'dwell_time', name: 'Dwell Time'}, {id:'wear_level', name: 'Wear Level'}]);
    const [selectedStates, setSelectedStates] = useState();
    const [selectedAttributes, setSelectedAttributes] = useState();
    const [histogramData, setHistogramData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [selectedProcessState, setSelectedProcessState] = useState(null);
    const [assetAnalyseData, setAssetAnalyseData] = useState([])
    const [dataTree, setTreeData] = useState([])
    const [minHistVal, setMinHistVal] = useState(0)
    const [maxHistVal, setMaxHistVal] = useState(0)
    const tenant = TenantService.getTenant()
    // const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [totalAssetsCount, setTotalAssetsCount] = useState(0)
    const [dynamicCaption, setDynamicCaption] = useState('Select a Column');
    const [dynamicCaption3, setDynamicCaption3] = useState('Select a Column');
    const [parentIdExpr, setParentIdExpr] = useState(null);


    useEffect(() => {
      // Retrieve data from localStorage
      const storedData = localStorage.getItem(`analyze-filter-data-${tenant}`);
      const assetCount = localStorage.getItem(`total-assets-count-${tenant}`);
      if (storedData) {
        setAssetAnalyseData(JSON.parse(storedData));
        setTotalAssetsCount(assetCount)
      }
      // getData()
    }, []);

  //   const calculateHistogramData = (data, isCategorical = false) => {
  //     if (!data || data.length === 0) {
  //         return [];
  //     }
  
  //     // Handle categorical data
  //     if (isCategorical) {
  //         const categories = Array.from(new Set(data.map(item => item.process_time)));
  //         const bins = categories.slice(0, Math.min(categories.length, 10)).map((category) => ({
  //             label: category,
  //             count: 0
  //         }));
  
  //         // Count occurrences in each category
  //         data.forEach(item => {
  //             const index = bins.findIndex(bin => bin.label === item.process_time);
  //             if (index !== -1) {
  //                 bins[index].count++;
  //             }
  //         });
  
  //         return bins.map(bin => ({
  //             bin_min: bin.label,
  //             bin_max: bin.label,
  //             count: bin.count
  //         }));
  //     }
  
  //     // Handle numerical data
  //     const dwellTimes = data.map(item => item.process_time);
  //     const max_data_value = Math.max(...dwellTimes);
  //     const min_data_value = Math.min(...dwellTimes);
  
  //     const max_hist_value = Math.ceil(max_data_value / 10) * 10;
  //     const min_hist_value = Math.floor(min_data_value / 10) * 10;
  //     setMinHistVal(min_hist_value)
  //     setMaxHistVal(max_hist_value)
  //     const bin_width = (max_hist_value - min_hist_value) / 10;
  
  //     const bins = Array.from({ length: 10 }, (_, i) => {
  //         const bin_min = min_hist_value + i * bin_width;
  //         const bin_max = (i === 9) ? max_hist_value : bin_min + bin_width;
  //         return { bin_min, bin_max, count: 0 };
  //     });
  
  //     // Count occurrences in each bin
  //     dwellTimes.forEach(process_time => {
  //         for (let i = 0; i < bins.length; i++) {
  //             if (process_time <= bins[i].bin_max) {
  //                 bins[i].count++;
  //                 break;
  //             }
  //         }
  //     });
  
  //     // Format bins to represent ranges
  //     const formattedBins = bins.map(bin => ({
  //         range: `${bin.bin_max}`,
  //         count: bin.count
  //     }));
  
  //     console.log("BINS: ", formattedBins);
  //     return formattedBins;
  // };
  

  //   const calculateHistogramData = (data) => {
  //     const dwellTimes = data.map(item => item.dwell_time);
  //     const max_data_value = Math.max(...dwellTimes);
  //     const min_data_value = Math.min(...dwellTimes);
    
  //     const max_hist_value = Math.ceil(max_data_value / 10) * 10;
  //     const min_hist_value = Math.floor(min_data_value / 10) * 10;
  //     setMinHistVal(min_hist_value)
  //     setMaxHistVal(max_hist_value)
  //     const bin_width = (max_hist_value - min_hist_value) / 10;
    
  //     const bins = Array.from({ length: 10 }, (_, i) => {
  //         const bin_min = min_hist_value + i * bin_width;
  //         const bin_max = (i === 9) ? max_hist_value : bin_min + bin_width;
  //         return { bin_min, bin_max, count: 0 };
  //     });
    
  //     // Count occurrences in each bin
  //     dwellTimes.forEach(dwell_time => {
  //         for (let i = 0; i < bins.length; i++) {
  //             if (dwell_time <= bins[i].bin_max) {
  //                 bins[i].count++;
  //                 break;
  //             }
  //         }
  //     });
  //     console.log("BINS: ", bins);
  //     return bins;
  // };

//   const calculateHistogramData = (data) => {
//     if (!data || data.length === 0) {
//         return [];
//     }

//     // Extract process_time values, filtering out invalid ones
//     const processTimes = data.map(item => item.process_time).filter(pt => pt !== null && pt !== undefined);

//     if (processTimes.length === 0) {
//         return []; // Handle case where all process_time values are null or undefined
//     }

//     // Calculate min and max values for process_time
//     const max_data_value = Math.max(...processTimes);
//     const min_data_value = Math.min(...processTimes);

//     // Align to nearest 10 for histogram binning
//     const max_hist_value = Math.ceil(max_data_value / 10) * 10;
//     const min_hist_value = Math.floor(min_data_value / 10) * 10;
//     const bin_width = (max_hist_value - min_hist_value) / 10;

//     // Create bins
//     const bins = Array.from({ length: 10 }, (_, i) => {
//         const bin_min = min_hist_value + i * bin_width;
//         const bin_max = bin_min + bin_width - 1;
//         return { bin_min, bin_max, count: 0 };
//     });

//     // Assign each process_time to a bin
//     processTimes.forEach(process_time => {
//         for (let i = 0; i < bins.length; i++) {
//             const bin = bins[i];
//             if (process_time >= bin.bin_min && process_time <= bin.bin_max) {
//                 bin.count++;
//                 break;
//             }
//         }
//     });

//     // Format bins for output
//     return bins.map(bin => ({
//         range: `${bin.bin_max}`,
//         count: bin.count
//     }));
// };
    const calculateHistogramData = (data) => {
      if (!data || data.length === 0) {
          return [];
      }
    
      // Extract process_time values, filtering out invalid ones
      const processTimes = data.map(item => item.process_time).filter(pt => pt !== null && pt !== undefined);
    
      if (processTimes.length === 0) {
          return []; // Handle case where all process_time values are null or undefined
      }
    
      // Calculate min and max values for process_time
      const max_data_value = Math.max(...processTimes);
      const min_data_value = Math.min(...processTimes);
    
      // Define bin configuration
      const num_bins = 10; // Fixed number of bins
      const bin_width = Math.ceil((max_data_value - min_data_value) / num_bins) || 1; // Avoid division by zero
    
      // Create bins
      const bins = Array.from({ length: num_bins }, (_, i) => ({
          range: min_data_value + i * bin_width,
          count: 0,
      }));
    
      // Assign each process_time to the appropriate bin
      processTimes.forEach((process_time) => {
          for (let i = 0; i < bins.length; i++) {
              if (i === bins.length - 1 || process_time < bins[i + 1].range) {
                  bins[i].count++;
                  break;
              }
          }
      });
    
      // Fill empty bins with zero count up to the max range
      const filledBins = bins.map(bin => ({
          range: bin.range.toString(), // Convert range to string
          count: bin.count,
      }));
    
      return filledBins;
    };

  useEffect(() => {
    if (selectedStates) {
      const caption = groups.find(item => item.id === selectedStates);
      setDynamicCaption(caption ? caption.name : 'Select a Column'); 
      const caption3 = attributes.find(item => item.id === selectedAttributes);
      setDynamicCaption3(caption3 ? caption3.name && caption3.name === "Dwell Time" ? "Dwell Time (Avg Days)" : caption3.name : 'Select a Column'); 
    } else {
      setDynamicCaption('Select a Column');
      setDynamicCaption3('Select a Column');
    }
}, [selectedStates, selectedAttributes]);

useEffect(() => {
  console.log('Parent ID Expr:', parentIdExpr);
  console.log('DataTree:', dataTree);
}, [parentIdExpr, dataTree]);

useEffect(() => {
  if (selectedStates === 'space') {
    setParentIdExpr("headId");
  } else {
    setParentIdExpr(null);
  }

  // Fetch data after setting parentIdExpr
  if (selectedAttributes && selectedStates) {
    AssetsService.getAnalyzeData(selectedStates, assetAnalyseData, selectedAttributes)
      .then((response) => {
        setTreeData(response.data.data.treeListData);
        setRawData(response.data.data.rawData);
      });
  } else {
    setHistogramData([]);
  }
}, [selectedAttributes, selectedStates]);

    const handleStateChange = (e) => {
      console.log('value of selected states: ',e.value);
      setSelectedStates(e.value);
    };

    const handleAttributeChange = (e) => {
      console.log('value of selected attributes: ',e.value);
      setSelectedAttributes(e.value);
    };

    // const handleRowClick = (e) => {
    //   setSelectedRowKey(e.key);
    //   setSelectedProcessState(e.data.process_state); // Capture the clicked process_state
    //   const filteredData = histogramData.filter(item => item.process_state === e.data.process_state);
    //   console.log('FILTERED DATA: ', filteredData);
    //   const histogram = calculateHistogramData(filteredData);
    //   console.log('HISTOGRAM: ', histogram);
    //   setHistogramData(histogram);
    // };
  //   const handleSelectionChanged = (e) => {
  //     console.log('selected state e: ', e);
  //     const selectedState = e.selectedRowsData[0].process_state; // Capture the clicked process_state
  //     setSelectedProcessState(selectedState);     // Store the selected process_state
  //     const filteredData = rawData.filter(item => item.process_state === selectedState);
      
  //     console.log('filteredData: ', filteredData);
  //     setSelectedRowKeys(e.selectedRowKeys);
  //     if (filteredData.length > 0) {
  //         const histogram = calculateHistogramData(filteredData);  // Calculate histogram for the selected process state
  //         setHistogramData(histogram);  // Update the chart data
  //     } else {
  //         setHistogramData([]);  // Clear chart data if no data available
  //     }
  // };

  const handleSelectionChanged = (e) => {
    // Check if selectedRowsData array exists and has at least one element
    if (e.selectedRowsData && e.selectedRowsData.length > 0) {
      const selectedState = e.selectedRowsData[0].name;
      
      // Check if selectedState is not undefined or null
      if (selectedState != null) {
        setSelectedProcessState(selectedState);
        
        // Filter rawData based on selected name
        const filteredData = rawData.filter(item => item.name === selectedState);
        
        console.log('filteredData: ', filteredData);
        
        // Update selectedRowKeys
        setSelectedRowKeys(e.selectedRowKeys);
        
        // Check if filteredData has elements before calculating histogram
        if (filteredData.length > 0) {
          const histogram = calculateHistogramData(filteredData);
          setHistogramData(histogram);
        } else {
          setHistogramData([]);
        }
      } else {
        console.warn('Selected row does not have a name property');
        setHistogramData([]);
      }
    } else {
      console.warn('No row selected');
      setSelectedProcessState(null);
      setSelectedRowKeys([]);
      setHistogramData([]);
    }
  };

  // const handleSelectionChanged = (e) => {
  //   setSelectedRowKeys(e.selectedRowKeys);
  // };

    function closeTenant(){
      history.push("/assets");
    }

    const customizeEventsLabel = (arg) => {
      return {
        text: `${arg.valueText}`,
      };
    }

    useEffect(() => {
      if (selectedAttributes && selectedStates){
        AssetsService.getAnalyzeData(selectedStates, assetAnalyseData, selectedAttributes).then((response) => {
        setTreeData(response.data.data.treeListData);
        setRawData(response.data.data.rawData);
        })
      } else {
        setHistogramData([])
      }
    }, [selectedAttributes, selectedStates]);

  return (
    <>
    <div className='headerHeadingPage'>
            <div className='pageFullWidthAnalyze'>
                <div className='editentities_col'>
            <div style={{ fontSize: "large", display: "inline-block" }}><b>Assets {'>'} Analyze</b> &nbsp; &nbsp; <span style={{fontSize:"14px"}}>Analyzing {assetAnalyseData.length} Assets out of total {totalAssetsCount} Assets</span></div>
            {/* <i style={{top:"5px"}} className="entity-group-add dx-icon-close" onClick={closeTenant}></i> */}
            <div className='backBtn' onClick={closeTenant}>
              <i className="dx-icon-close"></i>
            </div>
        </div>
    <div style={{marginTop: "50px", marginBottom: "150px"}}>
        <div className='uploadBtnAssetsReport' style={{position: "absolute",
        padding: "6px", borderRadius: "30px", lineHeight: "normal", marginLeft: "10px", left: "0",
        width: "292px", right: "486px", top: "52px"}}>
            <SelectBox
            style={{ marginRight: '10px', width: '100%' }}
            // dataSource={processStates}
            value={selectedStates}
            onValueChanged={handleStateChange}
            dataSource={groups}
            labelMode="static"
            label="Grouping"
            stylingMode="outlined"
            valueExpr="id"
            displayExpr="name"
            // applyValueMode="useButtons"
            // placeholder="Select items"
            // showSelectionControls={true}
            />
        </div>
      <div className='upload2' style={{position: "absolute",
        padding: "6px", borderRadius: "30px", lineHeight: "normal", marginLeft: "320px", left: "0",
        width: "292px"}}>
        <SelectBox
          style={{ marginRight: '10px', width: '100%', marginTop:"4px" }}
          value={selectedAttributes}
          onValueChanged={handleAttributeChange}
          dataSource={attributes}
          labelMode="static"
          label="Attribute Analyzed"
          stylingMode="outlined"
          valueExpr="id"
          displayExpr="name"
          // applyValueMode="useButtons"
          // showSelectionControls={true}
          />
      </div>
    </div>
    { selectedAttributes && selectedStates ? (
    <TreeList
        dataSource={dataTree}
        idField="name" // Unique field for each row
        defaultExpandedRowKeys={[]} // Adjust as needed for initial expansion
        showRowLines={true}
        showBorders={true}
        className='analyze-treelist'
        scrolling={{ mode: 'standard', showScrollbar: 'always' }}
        height={'calc(100vh - 503px)'}
        {...(selectedStates === 'space' ? { parentIdExpr } : null)}
        selectedRowKeys={selectedRowKeys}
        onSelectionChanged={handleSelectionChanged}
        style={{marginLeft: "10px", marginRight: "10px"}}
      >
        <Column 
        dataField="name" 
        caption={dynamicCaption || 'Select a Column'} 
        />
        <Column dataField="count" caption="Asset Count" alignment="center" />
        {/* <Column dataField="total_dwell_time" caption="Total Dwell Time" alignment="center" /> */}
        <Column dataField="avg" 
        // caption="Dwell Time (Avg Days)" 
        alignment="center" caption={dynamicCaption3} />
        {/* <Paging defaultPageSize={5} /> */}
        <SearchPanel visible={false} />
        <Selection mode="single" />
      </TreeList>
        ): ""}
      {selectedProcessState && selectedAttributes.length && selectedStates.length ? (
      
      <div style={{width: "90%", marginTop: "50px", marginBottom: "30px", marginLeft: "50px"}}>
          <h5 style={{textAlign: "center",fontSize: "14px", marginBottom: "0px"}}>Asset Count by Dwell Time For {selectedProcessState}</h5>
          <Chart
            dataSource={histogramData}
            idField="process_state"
            width={'90%'}
            // height={150}
            legend={{ visible: false }}
            style={{"--height": "500px"}}
            animation={{"enabled":false}} 
          >
            <CommonSeriesSettings
              argumentField="range"
            />
            <Tooltip
              enabled={true}
              horizontalAlignment="left"
              customizeTooltip={customizeEventsLabel}
            />
            <Size
            width={'100%'}
            height={250}
          />
          <Series
            argumentField="range"
            valueField="count"
            name=""
            type="bar"
            color="#FFC001"
          />
          {/* <ArgumentAxis
        tickInterval={1}
        tick={{ visible: true }}
        minorTick={{ visible: false }}
        label={{
          overlappingBehavior: 'none', // Prevents overlapping
          alignment: 'center',         // Aligns text with ticks
          position: 'outside',         // Moves the labels outside the chart
        }}
      > */}
            <ArgumentAxis visible={true} discreteAxisDivisionMode="crossLabels" // Ensures ticks align with labels
              type="discrete" // Forces alignment of labels and ticks
              label={{
                overlappingBehavior: 'none',
                alignment: 'center',
              }}
            >
              <Label overlappingBehavior="none" alignment="center">
                {item => {
                  // const nextValue = item.value + (item.index < 9 ? Math.ceil((maxHistVal - minHistVal) / 10) : 0);
                  return `${item.value}`; // Custom bin labels
                }}
              </Label>
              <Tick visible={true} />
              <Title text="Dwell Time (Avg Days)"></Title>
            </ArgumentAxis>
            <ValueAxis
        visible={true}
        tickInterval={1}   // Control the interval of the ticks on the y-axis
        min={0}            // Ensure the y-axis starts from 0
        max={Math.max(...histogramData.map(item => item.count))+1} // Set the maximum value based on the data
    >
        <Label />
        <Title text="Asset Count" />
    </ValueAxis>
          </Chart>
                </div>
            ) : ""}
      {/* </div> */}
    </div> 
    </div> 
    </>
  );
}
