import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import ScrollView from 'devextreme-react/scroll-view';
import { useHistory } from "react-router-dom";
import DataGrid, {
    Editing,
    Column,
    Paging,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    RequiredRule,
} from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import { Form as Form2 } from 'devextreme-react/form'
import { Item as Item2 } from 'devextreme-react/form';
import TenantService from "../../api/tenant.service";
import SpacesService from "../../api/spaces.service";
import EntityService from "../../api/entity.service";
import Toast from 'components/Toast';
import { Popup as Popup2 } from 'devextreme-react/popup';
import './entityGroup.css'
import { CheckBox } from 'devextreme-react';
import { Tooltip } from 'devextreme-react/tooltip';

export default function App(props) {
    const history = useHistory();
    const entityGroupId = props.match.params.entityGroupId
    const [data, setData] = useState([])
    const [popupVisible2, setPopupVisible2] = useState(false)
    const [formVisible, setFormVisible] = useState(false)
    // const [emails, setEmails] = useState([])
    const [spaces, setSpaces] = useState([])
    const [batteryData, setBatteryData] = useState([])
    const [selectedSpace2, setSelectedSpace2] = useState()
    const [transmitPeriodHours, setTransmitPeriodHours] = useState()
    const [reTransmitPeriodHours, setReTransmitPeriodHours] = useState()
    const [thresholdHours, setThresholdHours] = useState()
    const [processState, setProcessState] = useState()
    const [thresholdMinutes, setThresholdMinutes] = useState()
    const [createNotification, setCreateNotification] = useState(true)
    const [offsite, setOffsite] = useState(true)
    const [batteryLevel, setBatteryLevel] = useState()
    const [severityLabel, setSeverityLabel] = useState()
    const [severity, setSeverity] = useState(null)
    const [detectionType, setDetectionType] = useState()
    const [ruleLabel, setRuleLabel] = useState()
    const [ruleType, setRuleType] = useState(null)
    const [name, setName] = useState()
    const [editAdd, setEditAdd] = useState("add")
    const [editId, setEditId] = useState()
    const [pageName, setPageName] = useState("")
    const [entityNotes, setEntityNotes] = useState("")
    // const [emailFormData, setEmailFormData] = useState({
    //     "email": ""
    // })
    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
        displayTime: 5000
    });
    const tenant = TenantService.getTenant()
    const dropDataGridRef = useRef()
    const reTransmitPeriodHoursLabel = {
        text : 'Retransmit period [hours]'
    }

    const processStateLabel = {
        text : 'New Process State'
    }

    const thresholdHoursLabel = {
        text: 'Threshold time [hours]'
    }

    const validationRules = {
        thresholdHours: [{ type: 'required', message: 'Threshold hour(s) required.' }],
        thresholdMinutes: [{ type: 'required', message: 'Threshold hour(s) required.' }],
        retransmitHours: [{ type: 'required', message: 'Retransmit hour(s) required.' }],
        severity: [{ type: 'required', message: 'Severity is required.' }],
        battery: [{ type: 'required', message: 'Battery is required.' }],
        space: [{ type: 'required', message: 'Space is required.' }],
        detectionType: [{ type: 'required', message: 'Detection Type is required.' }],
      };

    function getSpaceData() {
        SpacesService.getSpaces(tenant).then((value) => {
            let response=value.data
            setSpaces(response.data)
        })
    }

    function onValueChanged2(e) {
        setSelectedSpace2(e.value)
    }

    function onValueChangedBattery(e) {
        setBatteryLevel(e.value)
    }

    function onValueChangedDetection(e) {
        setDetectionType(e.value)
    }

    function updateCheckBoxValue(e){
        setCreateNotification(e)
    }

    function updateCheckBoxOffsite(e){
        setOffsite(e)
    }

    function getData() {
        EntityService.getEntityRuleByEntityGroup(entityGroupId).then((value) => {
            setData(value.data.data.rules)
            setPageName(value.data.data.groupName)
        })
        EntityService.getTenantEntityRules(entityGroupId).then((value) => {
            let rules = []
            for (let i = 0; i < value.data.data.length; i++) {
                rules.push(value.data.data[i].name)
            }
            setRuleLabel(rules)
        })

        EntityService.getSeverity().then((values) => {
            setSeverityLabel(values.data.data)
        })
        setBatteryData([{name:'90%',value: 90}, {name:'80%',value:80}, {name:'70%',value:70}, {name:'60%',value:60}, {name:'50%',value:50}, {name:'40%',value:40}, {name:'30%',value:30}, {name:'20%',value:20}, {name:'10%',value:10}])
    }

    function onRowInserted(e) {
        EntityService.createEntityGroup(e.data.name).then((response) => {
             setToastConfig({
                ...toastConfig,
                isVisible: true,
                status: response.status,
                type: response?.status === 200 ? 'success' : 'error',
                message: response?.data?.message ? response.data.message : response.message,
            });
            getData()
        })
    }

    function onRowRemoved(e) {
        EntityService.deleteRuleFromEntityGroupRuleMap(e.data.id).then((response) => {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                status: response.status,
                type: response.status === 200 ? 'success' : 'error',
                message: response?.data?.message ? response.data.message : response.message,
            });
            getData()
        })
    }

    function onRowUpdating(e) {
        EntityService.editEntityGroup(e.newData.name, e.oldData.id).then((res) => {
            getData()
        })
    }

    function onHiding() {
        setEditId(null)
        setEditAdd("add")
        setTransmitPeriodHours(null)
        setReTransmitPeriodHours(null)
        setProcessState(null)
        setThresholdHours(null)
        setThresholdMinutes(null)
        setDetectionType("")
        setRuleType(null)
        setName(null)
        // setEmails([])
        setSelectedSpace2([])
        setPopupVisible2(false)
        setCreateNotification(true)
        setEntityNotes("")
        setSeverity(null)
        setBatteryLevel("")
    }

    function createGroupMap() {
        let ruleId
        let ruleInfo
        let hasCheckIn = true
        if (formVisible === 'Gateway Offline') {
            ruleId = 5
            ruleInfo = {
                // "emailAlert": {
                //     "recipients": emails
                // },
                "severity": severity,
                "thresholdHours": thresholdHours,
                "retransmitPeriodHours": reTransmitPeriodHours,
                "createNotification": createNotification
            }
        }
        if (formVisible === 'Change State') {
            ruleId = 9
            ruleInfo = {
                "severity": severity,
                "detectionType": detectionType,
                "spaceId": selectedSpace2,
                "processState": processState,
                "createNotification": createNotification,
                "offsite": offsite
            }
        }
        if (formVisible === 'Geofence') {
            ruleId = 4
            ruleInfo = {
                "severity": severity,
                "spaceId": selectedSpace2,
                // "emailAlert": {
                //     "recipients": emails
                // },
                "detectionType": detectionType,
                "createNotification": createNotification
            }
        }
        if (formVisible === 'Device Offline') {
            ruleId = 2
            ruleInfo = {
                "severity": severity,
                "emailAlert": {
                    // "recipients": emails,
                    "transmitPeriodHours": 1,
                    "reTransmitPeriodHours": reTransmitPeriodHours
                },
                "thresholdHours": thresholdHours,
                "createNotification": createNotification
            }
        }
        if (formVisible === 'Battery Level') {
            ruleId = 7
            ruleInfo = {
                "severity": severity,
                "emailAlert": {
                    "thresholdHours": thresholdHours,
                    "reTransmitPeriodHours": reTransmitPeriodHours
                },
                "batteryLevel": batteryLevel,
                "createNotification": createNotification
            }
        }
        if (formVisible === 'Valmet Process') {
            ruleId = 3
            ruleInfo = {
                "severity": severity,
                // "emailAlert": {
                //     "recipients": emails,
                // },
                "createNotification": createNotification
            }
        }
        if (formVisible === 'Check In/Out') {
            if (data.some(e => e.ruleId === 1)) {
                hasCheckIn = false
            }
            ruleId = 1
            ruleInfo = {
            }
        }
        if (formVisible === 'Device Offsite') {
            ruleId = 6
            ruleInfo = {
                "severity": severity,
                // "emailAlert": {
                //     "recipients": emails
                // },
                "thresholdMinutes": thresholdMinutes,
                "createNotification": createNotification
            }
        }
        if (editAdd === "edit"){
            let id = editId
            EntityService.editEntityGroupRuleMap(id, ruleId, ruleInfo, name).then((response) => {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    status: response.status,
                    type: response.status === 200 ? 'success' : 'error',
                    message: response.data.message,
                });
                getData()
            })
        }
        if (hasCheckIn && editAdd === "add") {
            EntityService.createEntityGroupRuleMap(entityGroupId, ruleId, ruleInfo, name).then((response) => {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    status: response.status,
                    type: response.status === 200 ? 'success' : 'error',
                    message: response?.data?.message ? response.data.message : response.message,
                });
                getData()
            })
        } else if(editAdd === "add"){
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                status: 500,
                message: 'Check In/Out rule already exists',
                displayTime: 3000,
            });
        }
        setPopupVisible2(false)
    }

    function addOnClick() {
        setPopupVisible2(true)
    }

    function setForm(e) {
        if (e.dataField === "transmitPeriodHours") {
            setTransmitPeriodHours(1)
        }
        if (e.dataField === "reTransmitPeriodHours") {
            setReTransmitPeriodHours(e.value ? parseInt(e.value): "")
        }
        if (e.dataField === "thresholdHours") {
            setThresholdHours(e.value ? parseInt(e.value) : "")
        }
        if (e.dataField === "detectionType") {
            setDetectionType(e.value)
        }
        if (e.dataField === "thresholdMinutes") {
            setThresholdMinutes(e.value ? parseInt(e.value) : "")
        }
        if (e.dataField === "createNotification") {
            setCreateNotification(e.value)
        }
        if (e.dataField === "offsite") {
            setOffsite(e.value)
        }
        if (e.dataField === "batteryLevel") {
            setBatteryLevel(e.value)
        }
        if (e.dataField === "processState") {
            setProcessState(e.value)
        }
    }

    // function setFormEmail(e) {
    //     let myEmails = emails
    //     if (validateEmail(e.value)) {
    //         myEmails.push(e.value)
    //         setEmails(myEmails)
    //     }
    //     setEmailFormData({
    //         "email": null
    //     })
    // }

    function setRuleName(e) {
        setName(e.value)
    }

    function onRuleChanged(e) {
        setRuleType(e.value)
        setFormVisible(e.value)
        if (e.value === 'Gateway Offline'){
            setEntityNotes("Note: This rule applies to the 'Gateway' entity only.")
        } else if (e.value === 'Check In/Out'){
            setEntityNotes("Note: This rule applies to the 'Device' entity only.")
        } else if (e.value === 'Device Offline'){
            setEntityNotes("Note: This rule applies to the 'Device' entity only.")
        } else if (e.value === 'Geofence'){
            setEntityNotes("Note: This rule applies to the 'Device' and 'Asset' entity only.")
        } else if (e.value === 'Device Offsite'){
            setEntityNotes("Note: This rule applies to the 'Device' entity only.")
        } else if (e.value === 'Battery Level'){
            setEntityNotes("Note: This rule applies to the 'Devices' with role Tag and Anchor only.")
        } else if (e.value === 'Change State'){
            setEntityNotes("Note: This rule applies to the 'Devices' entity only.")
        }
    }

    function onSeverityChanged(e){
        setSeverity(e.value)
    }

    useEffect(() => {
        getData()
        getSpaceData()
    }, []);

    // function deleteEmail(em) {
    //     let arr = emails
    //     arr = arr.filter(function (item) {
    //         return item !== em
    //     })
    //     setEmails(arr)
    // }

    // function myList() {
    //     return (
    //         <>
    //             {
    //                 emails.map((email, i) =>
    //                     <div className='entityRuleMap-emailListContainer'>
    //                         <div onClick={() => { deleteEmail(email) }} className="entityRuleMap-emailList">
    //                             <i className="dx-icon-close" style={{ "fontSize": "15px" }}></i> &nbsp;
    //                         </div>
    //                         {email}
    //                     </div>
    //                 )
    //             }
    //         </>
    //     )
    // }

    // function validateEmail(email) {
    //     var re = /\S+@\S+\.\S+/;
    //     return re.test(email);
    // }

    function rulesText() {
        return (
            <>
                <i className="dx-icon-edit" style={{ "fontSize": "16px", "color": "#B51716" }}></i>
            </>
        )
    }

    function buttonColor(e) {
        if ((e.column.caption === '') && e.rowType !== "header") {
            e.cellElement.style.setProperty('cursor', 'pointer', 'important')
        }
    }

    function closeTenant(){
        history.push("/config/behaviors");
    }

    function showInfo(e) {
        if (e.column.caption === '') {
            setEditAdd("edit")
            setName(e.data.name)
            setRuleType(e.data.ruleName)
            setEditId(e.data.id)
            setSeverity(e.data.ruleInfo.severity)
            // if(e.data?.ruleInfo?.emailAlert?.recipients !== undefined){
            //     setEmails(e.data.ruleInfo.emailAlert.recipients)
            // }
            if(e.data?.ruleInfo?.emailAlert?.reTransmitPeriodHours !== undefined){
                setReTransmitPeriodHours(e.data.ruleInfo.emailAlert.reTransmitPeriodHours)
            }
            if(e.data?.ruleInfo?.spaceId !== undefined){
                setSelectedSpace2(e.data.ruleInfo.spaceId)
            }
            if(e.data?.ruleInfo?.retransmitPeriodHours !== undefined){
                setReTransmitPeriodHours(e.data.ruleInfo.retransmitPeriodHours)
            }
            if(e.data?.ruleInfo?.thresholdHours !== undefined){
                setThresholdHours(e.data.ruleInfo.thresholdHours)
            }
            if(e.data?.ruleInfo?.emailAlert?.thresholdHours !== undefined){
                setThresholdHours(e.data.ruleInfo.emailAlert.thresholdHours)
            }
            if(e.data?.ruleInfo?.detectionType !== undefined){
                setDetectionType(e.data.ruleInfo.detectionType)
            }
            if(e.data?.ruleInfo?.thresholdMinutes !== undefined){
                setThresholdMinutes(e.data.ruleInfo.thresholdMinutes)
            }
            if(e.data?.ruleInfo?.createNotification !== undefined){
                setCreateNotification(e.data.ruleInfo.createNotification)
            }
            if(e.data?.ruleInfo?.offsite !== undefined){
                setOffsite(e.data.ruleInfo.offsite)
            }
            if(e.data?.ruleInfo?.batteryLevel !== undefined){
                setBatteryLevel(e.data.ruleInfo.batteryLevel)
            }
            if(e.data?.ruleInfo?.processState !== undefined){
                setProcessState(e.data.ruleInfo.processState)
            }
            setPopupVisible2(true)
        }
    }

    return (
        <>
            <Popup2
                visible={popupVisible2}
                onHiding={onHiding}
                dragEnabled={false}
                width={600}
                showCloseButton={true}
                showTitle={true}
                title="Configure Rule"
                container=".dx-viewport"
            >
                <ScrollView>
                <div className='entityRuleMap-name' style={{paddingBottom:"12px"}}>
                    <Form2
                        labelLocation={"left"}
                        labelMode={"outside"}
                        id={'form'}
                        formData={{
                            "name": name,
                        }}
                        onFieldDataChanged={setRuleName}
                    >
                        <Item2 dataField={"name"} editorOptions={{
                        elementAttr: {
                            class: 'rule-name-input' // Apply the custom CSS class
                        }
                    }}>
                        </Item2>
                    </Form2>
                </div>
                <div className='entityRuleMap-select' style={{paddingBottom:"20px"}}>
                    <span style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingRight:"21%", paddingTop:"15px"}}>Rule&nbsp;Type</span>
                    <SelectBox
                        items={ruleLabel}
                        value={ruleType}
                        onValueChanged={onRuleChanged}
                        placeholder="Select a Rule"
                    />
                </div>
                <div className='severity-select'>
                    <span style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingRight:"23%", paddingTop:"15px"}}>Severity</span>
                    <SelectBox
                        placeholder="Select severity"
                        focusStateEnabled={false}
                        height={40}
                        items={severityLabel}
                        value={severity}
                        onValueChanged={onSeverityChanged}
                        valueExpr="id"
                        displayExpr="name"
                    >
                    </SelectBox>
                </div>
                <div className={formVisible === 'Geofence' || formVisible === 'Change State' ? 'entityRuleMap-space entityRuleMap-select' : 'entityRuleMap-spaceNone'}>
                <span style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingRight:"18%", paddingTop:"15px"}}>Select&nbsp;Space</span>
                    <SelectBox
                        placeholder="Select a Space"
                        focusStateEnabled={false}
                        height={40}
                        items={spaces}
                        value={selectedSpace2}
                        onValueChanged={onValueChanged2}
                        valueExpr="id"
                        displayExpr="name"
                        visible={(formVisible === 'Geofence' || formVisible === 'Change State')}
                    >
                    </SelectBox>
                </div>

                <div className={formVisible === 'Battery Level' ? 'entityRuleMap-space entityRuleMap-select' : 'entityRuleMap-spaceNone'}>
                <span style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingRight:"18%", paddingTop:"15px"}}>Select&nbsp;Battery</span>
                    <SelectBox
                        placeholder="Select Battery Level"
                        focusStateEnabled={false}
                        height={40}
                        items={batteryData}
                        value={batteryLevel}
                        onValueChanged={onValueChangedBattery}
                        valueExpr="value"
                        displayExpr="name"
                        visible={(formVisible === 'Battery Level')}
                    >
                    </SelectBox>
                </div>

                <div className={formVisible === 'Geofence' ? 'entityRuleMap-space2 entityRuleMap-select' : 'entityRuleMap-spaceNone'}>
                <span style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingRight:"16%", paddingTop:"15px"}}>Detection&nbsp;Type</span>
                    <SelectBox
                        placeholder="Select a Detection Type"
                        focusStateEnabled={false}
                        height={40}
                        items={[{ id: 'inside', name: 'Enters' }, { id: 'outside', name: 'Exits' }]}
                        value={detectionType}
                        onValueChanged={onValueChangedDetection}
                        valueExpr="id"
                        displayExpr="name"
                        visible={(formVisible === 'Geofence')}
                    >
                    </SelectBox>
                </div>
                <div className={formVisible === 'Change State' ? 'entityRuleMap-space2 entityRuleMap-select' : 'entityRuleMap-spaceNone'}>
                    <span style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingRight:"16%", paddingTop:"15px"}}>Detection&nbsp;Type</span>
                    <SelectBox
                        placeholder="Select a Detection Type"
                        focusStateEnabled={false}
                        height={40}
                        items={[{ id: 'ENTERED', name: 'Entered' }, { id: 'EXITED', name: 'Exited' }]}
                        value={detectionType}
                        onValueChanged={onValueChangedDetection}
                        valueExpr="id"
                        displayExpr="name"
                        visible={(formVisible === 'Change State')}
                    >
                    </SelectBox>
                </div>
                <Form2
                    labelLocation={"left"}
                    labelMode={"outside"}
                    id={'form'}
                    className='process-state-form'
                    formData={{
                        "processState": processState, 
                        "createNotification": createNotification
                    }}
                    onFieldDataChanged={setForm}
                    visible={(formVisible === 'Change State')}
                >
                    <Item2 dataField={"processState"} label={processStateLabel} editorType="dxTextBox"
                    editorOptions={{
                        elementAttr: {
                            class: 'process-state-input' // Apply the custom CSS class
                        }
                    }} cssClass={"process-state-label"}></Item2>
                </Form2>

                <div className={(formVisible === 'Geofence' || formVisible === 'Change State') ? 'entityRuleMap-space2 entityRuleMap-select' : 'entityRuleMap-spaceNone'}>
                    <span style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingRight:"12%", paddingTop:"40px"}}>Create&nbsp;Notification</span>
                    <CheckBox defaultValue={true} onValueChange={updateCheckBoxValue} style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingLeft:"0px", paddingTop:"40px"}}></CheckBox>
                </div>
                <div className={formVisible === 'Change State' && detectionType === 'EXITED' ? 'entityRuleMap-space2 entityRuleMap-select' : 'entityRuleMap-spaceNone'}>
                    <span style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingRight:"23%", paddingTop:"40px"}}>Offsite</span>
                    <CheckBox id="offsite" defaultValue={true} onValueChange={updateCheckBoxOffsite} style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingLeft:"0px", paddingTop:"40px"}}></CheckBox>
                    <Tooltip
                        target="#offsite"
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        position="right"
                        hideOnOutsideClick={false}
                    >When selected, the rule only triggers if the Asset or Device left the tracking area through the selected Space.
                    </Tooltip>
                </div>
                <Form2
                    labelLocation={"left"}
                    labelMode={"outside"}
                    id={'form'}
                    formData={{
                        "thresholdHours": thresholdHours,
                        "reTransmitPeriodHours": reTransmitPeriodHours,
                        // "transmitPeriodHours": transmitPeriodHours,
                        "createNotification": createNotification
                    }}
                    onFieldDataChanged={setForm}
                    visible={(formVisible === 'Device Offline')}
                    style={{paddingTop:"10px"}}
                >
                    <Item2 dataField={"thresholdHours"} label={thresholdHoursLabel} editorOptions={{
                        elementAttr: {
                            class: 're-transmit-input-hours' // Apply the custom CSS class
                        }
                    }}>
                    </Item2>
                    <Item2 dataField={"reTransmitPeriodHours"} label={reTransmitPeriodHoursLabel} editorOptions={{
                        elementAttr: {
                            class: 're-transmit-input-hours' // Apply the custom CSS class
                        }
                    }}>
                    </Item2>
                    <Item2 dataField={"createNotification"} label={createNotification}>
                    </Item2>
                    {/* <Item2 dataField={"transmitPeriodHours"}>
                    </Item2> */}
                </Form2>
                <Form2
                    labelLocation={"left"}
                    labelMode={"outside"}
                    id={'form'}
                    formData={{
                        "thresholdHours": thresholdHours,
                        "reTransmitPeriodHours": reTransmitPeriodHours,
                        // "transmitPeriodHours": transmitPeriodHours,
                        "createNotification": createNotification
                    }}
                    onFieldDataChanged={setForm}
                    visible={(formVisible === 'Battery Level')}
                >
                    <Item2 dataField={"thresholdHours"} label={thresholdHoursLabel} editorOptions={{
                        elementAttr: {
                            class: 're-transmit-input-hours' // Apply the custom CSS class
                        }
                    }}>
                    </Item2>
                    <Item2 dataField={"reTransmitPeriodHours"} label={reTransmitPeriodHoursLabel} editorOptions={{
                        elementAttr: {
                            class: 're-transmit-input-hours' // Apply the custom CSS class
                        }
                    }}>
                    </Item2>
                    <Item2 dataField={"createNotification"} label={createNotification}>
                    </Item2>
                    {/* <Item2 dataField={"transmitPeriodHours"}>
                    </Item2> */}
                </Form2>
                <Form2
                    labelLocation={"left"}
                    labelMode={"outside"}
                    id={'form'}
                    formData={{
                        "thresholdHours": thresholdHours,
                        "reTransmitPeriodHours": reTransmitPeriodHours,
                        "createNotification": createNotification
                    }}
                    onFieldDataChanged={setForm}
                    visible={(formVisible === 'Gateway Offline')}
                >
                    <Item2 dataField={"thresholdHours"} label={thresholdHoursLabel} editorOptions={{
                        elementAttr: {
                            class: 're-transmit-input-hours' // Apply the custom CSS class
                        }
                    }}>
                    </Item2>
                    <Item2 dataField={"reTransmitPeriodHours"} label={reTransmitPeriodHoursLabel} editorOptions={{
                        elementAttr: {
                            class: 're-transmit-input-hours' // Apply the custom CSS class
                        }
                    }}>
                    </Item2>
                    <Item2 dataField={"createNotification"} label={createNotification} >
                    </Item2>
                </Form2>
                <Form2
                    labelLocation={"left"}
                    labelMode={"outside"}
                    id={'form'}
                    formData={{
                        "thresholdMinutes": thresholdMinutes
                    }}
                    onFieldDataChanged={setForm}
                    visible={(formVisible === 'Device Offsite')}
                >
                    <Item2 dataField={"thresholdMinutes"} editorOptions={{
                        elementAttr: {
                            class: 'process-state-input-minutes' // Apply the custom CSS class
                        }
                    }}></Item2>
                </Form2>
                <div className={formVisible === 'Device Offsite' ? 'entityRuleMap-space2 entityRuleMap-select' : 'entityRuleMap-spaceNone'}>
                    <span style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingRight:"12%", paddingTop:"40px"}}>Create&nbsp;Notification</span>
                    <CheckBox defaultValue={true} onValueChange={updateCheckBoxValue} style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingLeft:"0px", paddingTop:"40px"}}></CheckBox>
                </div>
                <Form2>
                    <Item2></Item2>
                </Form2>
                {/* <div className='entityRuleMap-email'>
                    <Form2
                        labelLocation={"left"}
                        labelMode={"outside"}
                        id={'form'}
                        onFieldDataChanged={setFormEmail}
                        formData={emailFormData}
                        visible={((formVisible === 'Gateway Offline') || (formVisible === 'Geofence') || (formVisible === 'Valmet Process') || (formVisible === 'Device Offline') || (formVisible === 'Device Offsite'))}
                    >
                    </Form2>
                </div> */}
                {/* <div style={{ height: '25px', width: '100%' }}>
                    {myList()}
                </div> */}
                </ScrollView>
                <div>
                <div className='entityGroup-setNote'>{entityNotes}</div>
                <div className={editAdd === "edit"?"entityGroup-setEntities": "entityRuleMap-spaceNone"} onClick={createGroupMap}>
                    UPDATE
                </div>
                <div className={editAdd === "add"?"entityGroup-setEntities": "entityRuleMap-spaceNone"} onClick={createGroupMap}>
                    ADD
                </div>
                </div>
                
            </Popup2>
            <div className='headerHeadingPage'>
                <div className='pageFullWidthEvents'>
                <div className='editentities_col'>
                <div style={{fontSize: "large", display: "inline-block"}}>Rules of Behavior: {pageName}</div><i className="entity-group-add dx-icon-close" onClick={closeTenant}></i><i onClick={addOnClick} className="entity-group-add dx-icon-add" style={{ "fontSize": "24px" }}></i>
            </div>
            <DataGrid
                id="entity-rule-map-grid"
                ref={dropDataGridRef}
                showColumnLines={false}
                showRowLines={true}
                onRowInserted={onRowInserted}
                onRowRemoved={onRowRemoved}
                onRowUpdating={onRowUpdating}
                onCellClick={showInfo}
                onCellPrepared={buttonColor}
                height={'calc(100vh - 51px)'}
                allowColumnResizing={true}
                dataSource={data}>
                <Paging enabled="false" />
                <FilterRow visible="true" apply-filter="true" />
                <HeaderFilter visible="showHeaderFilter" />
                <SearchPanel visible="true" width="240" placeholder="Search..." />
                <Editing
                    allowDeleting={true}
                    use-icons={true}
                    mode="popup"
                    width={10}
                >
                </Editing>
                <Column
                    dataField={'name'}
                    caption={"Rule Name"}
                    hidingPriority={8}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'ruleName'}
                    hidingPriority={8}
                    caption={"Rule type"}
                >
                    <RequiredRule />
                </Column>
                <Column
                    caption={''}
                    width={20}
                    cellRender={rulesText}
                ></Column>
            </DataGrid>
                </div>
            </div>
            <Toast
                setParentToastConfig={setToastConfig}
                toastBody={toastConfig}
            />
        </>
    );
}
