import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTags(tenantId, spaceId) {
    return Axios.post(
      "/api/network-tool/tags",
      {
        tenantId: tenantId,
        spaceId: spaceId
      }
    );
  },

  getTagLocationDebug(serialNumber) {
    return Axios.post(
      "/api/network-tool/tag-location-debug",
      {
        serialNumber: serialNumber
      }
    );
  },

  getTagLocation(time, entityId) {
    return Axios.post(
      "/api/network/tag-location",
      {
        time: time,
        entityId: entityId
      }
    );
  },

  getOffsets(time, serialNumber) {
    return Axios.post(
      "/api/network-tool/offsets",
      {
        time: time,
        serialNumber: serialNumber
      }
    );
  },

  getTagsInSpace(spaceId) {
    return Axios.post(
      "/api/device/tags/space",
      {
        spaceId: spaceId
      }
    );
  },

  getTagsInZone(zones) {
    return Axios.post(
      "/api/network/tags-zones",
      {
        zones: zones
      }
    );
  },

  getAnhcorsInZone(zones) {
    return Axios.post(
      "/api/network/anchors-zones",
      {
        zones: zones
      }
    );
  },

  getLocationScans(deviceId, fromDate, toDate, limit) {
    return Axios.post(
      "/api/network-tool/location-scans",
      {
        deviceId: deviceId,
        fromDate: fromDate,
        toDate: toDate,
        limit: limit
      }
    );
  },

  getLocationScansLegacy(deviceId, fromDate, toDate) {
    return Axios.post(
      "/api/network-tool/location-scans-legacy",
      {
        deviceId: deviceId,
        fromDate: fromDate,
        toDate: toDate
      }
    );
  },

  getLocationScansSa(serialNumber, time, sequence) {
    console.log("sequence",sequence)
    return Axios.post(
      "/api/network-tool/location-scans-sa",
      {
        serialNumber: serialNumber,
        time: time,
        sequence: sequence
      }
    );
  },

  getDiagnosticsScans(serialNumber, fromDate, toDate) {
    return Axios.post(
      "/api/network-tool/diagnostics-scans",
      {
        serialNumber: serialNumber,
        fromDate: fromDate,
        toDate: toDate
      }
    );
  },
  getParticipatingScans(serialNumber, fromDate, toDate) {
    return Axios.post(
      "/api/network-tool/participating-scans",
      {
        serialNumber: serialNumber,
        fromDate: fromDate,
        toDate: toDate
      }
    );
  },
  getParticipatingScansHour(serialNumber, fromDate, toDate) {
    return Axios.post(
      "/api/network-tool/participating-scans-hour",
      {
        serialNumber: serialNumber,
        fromDate: fromDate,
        toDate: toDate
      }
    );
  },

  getAnchorLocation(deviceId) {
    return Axios.post(
      "/api/network-tool/anchor-locations",
      {
        deviceId: deviceId
      }
    );
  },

  getSpaceFloorplan(spaceId) {
    return Axios.post(
      "/api/network/floorplan",
      {
        spaceId: spaceId
      }
    );
  },

  getSubSpaces(spaceId) {
    return Axios.post(
      "/api/network/subspace",
      {
        spaceId: spaceId
      }
    );
  }

};