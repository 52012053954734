import React from 'react';
import { SelectBox } from 'devextreme-react';

const thresholdValues = [
	{"name":"Small Vibrations (50mG)", "id": 50},
	{"name":"Moderate Vibrations (100mG)", "id": 100},
	{"name":"Soft Drop (750mG)", "id": 750},
	{"name":"Hard Drop (2000mG)", "id": 2000},
	{"name":"Custom", "id": 0},
];

function DropDownShockThreshold({onValueChanged, selectedThresholdValue, showInput, OnTextChange, error}) {
	return (
		<>
			{/* <div className={'btnContainer'}> */}
				<div className={'utilizationdateBtnDropdown'} style={{ marginRight: '10px' }}>
					{/* <div className={'forumText'}>Shock threshold</div> */}
					<SelectBox 
						value={selectedThresholdValue}
						placeholder="Select shock threshold"
						focusStateEnabled={false}
						width='210px'
						labelMode="static"
              			label="Shock threshold"
              			stylingMode="outlined"
						height={40}
						items={thresholdValues}
						onValueChanged={(e)=>onValueChanged(e)}
						valueExpr="id"
						displayExpr="name"
					/>
					{showInput && (
						<div style={{ paddingLeft: '0px',paddingTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
							<input
								type="number"
								id="customInput"
								placeholder="Enter custom value"
								height={40}
								onChange={(e)=> OnTextChange(parseInt(e.target.value)) }
								style={{height: '40px', paddingLeft: '10px', width: '100%'}}
							/>
							&nbsp;
							<label htmlFor="customInput" style={{marginTop: '10px'}}>mG</label>
							{error && <p style={{marginLeft: 10, color: "#C5260C"}}>{error}</p>}
						</div>
					)}
				</div>
			{/* </div> */}
			{/* <p className={'ptagThreshold'}>Total shocks above threshold:&nbsp;<b>{totalShocks}</b>&nbsp;in selected date range</p> */}
		</>
  );
}

export default DropDownShockThreshold;