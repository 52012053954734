import { Redirect } from 'react-router-dom';
import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTenant() {
    const user = localStorage.getItem('user');
    if (!user || user === "null") {
      <Redirect to='/login' />
    } else {
      return JSON.parse(user).tenantId;
    }
  },

  getTenants() {
    return Axios.get("/api/manage/tenant");
  },
  
  // add tenant
  addTenant(name, email, password, firstName, lastName, mobileNumber) {
    return Axios.post(
      "/api/manage/tenant/add",
      {
          name: name,
          email: email,
          password: password,
          firstName: firstName,
          lastName: lastName,
          mobileNumber: mobileNumber
      }
    );
  },
}
