import React, { useState, useEffect, useMemo } from 'react';
import 'devextreme/data/odata/store';
import SelectBox from 'devextreme-react/select-box';
import { CSVLink } from "react-csv";
import DataGrid, {
  Selection,
  Column,
  Scrolling,
  HeaderFilter,
  SearchPanel,
  Button,
} from 'devextreme-react/data-grid';
import { useRef } from 'react';
import TenantService from "../../api/tenant.service";
import NetworkToolService from "../../api/networkTool.service";
import SpacesService from '../../api/spaces.service';
import { Toast } from 'devextreme-react/toast';
import starMarker from "../../assets/markers/star.png";
import diamondMarker from "../../assets/markers/diamondBlack2.png";
import diamondMarkerRed from "../../assets/markers/diamondRed2.png";
import circle from "../../assets/markers/circle.png";
import greenCircle from "../../assets/markers/greenCircle.png";
import { addHours } from 'date-fns';
import { Button as Button2 } from 'devextreme-react/button';
import { MapContainer, Marker, ImageOverlay } from 'react-leaflet'
import { latLngBounds } from 'leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Tooltip, Polyline, Popup as Popup2, Circle } from "react-leaflet";
import { CRS } from 'leaflet';
import "leaflet-draw/dist/leaflet.draw-src.css";
import 'leaflet/dist/leaflet.css'
import './networkTool.css'
import { Switch } from 'devextreme-react/switch';
import { Popup } from 'devextreme-react';
import Papa from 'papaparse';
import { useHistory } from "react-router";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default function App() {
  const listRef = useRef();
  const [csvData, setCsvData] = useState([])
  const [actualPos, setActualPos] = useState([1, 1])
  const markerRef = useRef(null)
  const messagesEndRef = useRef(null)
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [tenants, setTenants] = useState([])
  const [anchors, setAnchors] = useState([]);
  const [mode, setMode] = useState("distance")
  const [validSpace, setValidSpace] = useState([])
  const [tagLocation, setTagLocation] = useState({ x: 0, y: 0 })
  const [tagError, setTagError] = useState(0)
  const [tagHeight, setTagHeight] = useState(0)
  const [selectedTag, setSelectedTag] = useState()
  const [selectedTagSerial, setSelectedTagSerial] = useState()
  const [bounds, setBounds] = useState(new latLngBounds([0, 0], [100, 100]));
  const [center, setCenter] = useState([0, 0])
  const [dataGrid1Visible, setDataGrid1Visible] = useState(false)
  const [dataGrid2Visible, setDataGrid2Visible] = useState(false)
  const [dateRangeVisible, setDateRangeVisible] = useState(false)
  const [toggleSwitch, setToggleSwitch] = useState(false)
  const [mapVisible, setMapVisible] = useState(false)
  const [toggleEditBool, setToggleEditBool] = useState(false)
  const [floorplanUrl, setFloorplanUrl] = useState("http://rpo.redlore.com/api/places/floorplan/a32b2298-bff7-44f8-a823-fa6b7fe1b8f7");
  const [show5m, setShow5m] = useState(false)
  const [show1m, setShow1m] = useState(false)
  const [height, setHeight] = useState(0)
  const [visibleLines, setVisibleLines] = useState([])
  const [circleAnchors, setCircleAnchors] = useState([])
  const [uploadPopupVisible, setUploadPopupVisible] = useState(false)
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [csvTagData, setCsvTagData] = useState([]);
  const [debugTagData, setDebugTagData] = useState([]);
  const [legacy, setLegacy] = useState(false);
  const [limit, setLimit] = useState(3000);
  const [datetime, setDatetime] = useState([
    {
      start: addHours((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)), - 1).toISOString().slice(0, -8),
      end: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -8)
    }
  ]);
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 5000
  });

  function checkRole() {
    let spaceConfig = false
    if (window.location.href.includes("config/device")) {
      spaceConfig = true
    }
    var role = JSON.parse(localStorage.getItem("role"));
    let canEdit = false
    for (let i = 0; i < role.length; i++) {
      if (role[i].name === "Asset Attributes") {
        if (role[i].write === true) {
          canEdit = true
        }
      }
    }
    if (canEdit && spaceConfig) {
      setToggleEditBool(true)
    }
  }

  function getTenants() {
    TenantService.getTenants().then((value) => {
      setTenants(value.data.data)
    })
  }

  function getSpacesFloorplan(tenant) {
    setFloorplanUrl("blob:https://locus.redlore.com/c3cf693b-eaf2-43f8-a51c-115f15ff2e58")
    SpacesService.getSpaces(tenant).then((value) => {
      let res = value.data.data
      let filteredRes = []
      for (let i = 0; i < res.length; i++) {
        if (res[i].floorplan !== null) {
          filteredRes.push(res[i])
        }
      }
      setValidSpace(filteredRes)
    })
  }

  function getTags(spaceId) {
    let zones = [spaceId]
    let zoneStr = `'${spaceId}'`
    SpacesService.getChildZones(spaceId).then((value) => {
      let children = value.data.data
      for (let i = 0; i < children.length; i++) {
        zones.push(children[i].id)
        zoneStr = zoneStr + ", " + `'${children[i].id}'`
      }
      NetworkToolService.getTagsInZone(zoneStr).then((value) => {
        setData(value.data.data)
      })
    })
  }

  useEffect(() => {
    checkRole()
    getTenants()
  }, []);

  function getBattery(rowData) {
    if (rowData.battery === null) {
      return
    }
    return rowData.battery + "v";
  }

  async function onRowClick2(e) {
    setMapVisible(false)
    await NetworkToolService.getTagLocation(e.data.time, selectedTag).then(async (valueA) => {
      if (valueA.data.data !== null && valueA.data.data !== undefined) {
        if (valueA.data.data[0] !== null && valueA.data.data[0] !== undefined) {
          if (valueA.data.data[0].error !== null && valueA.data.data[0].error !== undefined && !isNaN(valueA.data.data[0].error)) {
            setTagError(valueA.data.data[0].error)
          } else {
            setTagError(0)
          }
          if (valueA.data.data[0].height !== null && valueA.data.data[0].height !== undefined) {
            setTagHeight(valueA.data.data[0].height)
          } else {
            setTagHeight(0)
          }
          // setTagLocation(value.data.data.local_coordinates) //@@@
          setTagLocation(valueA.data.data[0].local_coordinates)

        } else {
          setTagLocation({ x: 0, y: 0 })
          setTagError(0)
          setTagHeight(0)
          setToastConfig({
            type: 'error',
            message: 'No Position Found',
            displayTime: 2000,
            isVisible: true,
          });
        }
      } else {
        setTagLocation({ x: 0, y: 0 })
        setTagError(0)
        setTagHeight(0)
        setToastConfig({
          type: 'error',
          message: 'No Position Found',
          displayTime: 2000,
          isVisible: true,
        });
      }
      let selectedTagSerialChar = selectedTagSerial.toString()
      let offsetTime = new Date(e.data.time)
      await NetworkToolService.getOffsets(offsetTime, selectedTagSerialChar).then(async (value1) => {
        let nbors = e.data.nbors
        let anchorLocations = []
        let myLength = nbors.length
        if (myLength > 50) {
          console.log("Too much data!")///@@@
          myLength = 50
        }
        let saLocation = nbors.filter((nbor) => nbor.rst === 0)
        await NetworkToolService.getLocationScansSa(saLocation[0].address.toString(), offsetTime, e.data.sequence).then(async (value2) => {
          let saLocationScans = value2.data.data[0].data.nbors
          for (let i = 0; i < myLength; i++) {
            await NetworkToolService.getAnchorLocation(nbors[i].address.toString()).then(async (value) => {
              if (value.data.data !== null && value.data.data !== undefined) {
                let losqiRssiSa = saLocationScans.filter((nbor) => nbor.address === nbors[i].address)
                let losqiSa = 0
                let rssiSa = 0
                if (losqiRssiSa.length === 0) {
                  losqiSa = 0
                  rssiSa = 0
                } else {
                  rssiSa = losqiRssiSa[0].rssi
                  losqiSa = losqiRssiSa[0].losqi
                }
                let offsets = []
                if (value1.data.data[0] !== undefined) {
                  offsets = value1.data.data[0].uwb_offset.offsets
                  let currOffset = offsets.filter((offset) => offset.address === nbors[i].address)
                  let myOffset = 0
                  if (currOffset.length > 0) {
                    myOffset = currOffset[0]
                  }
                  if (value.data.data.space_coordinates !== null) {
                    anchorLocations.push({
                      address: nbors[i].address,
                      losqi: nbors[i].losqi,
                      rssi: nbors[i].rssi,
                      losqiSa: losqiSa,
                      rssiSa: rssiSa,
                      rst: nbors[i].rst,
                      location: value.data.data.space_coordinates,
                      offset: myOffset.offset,
                      vector: [],
                      xDist: 0
                    })
                  }
                } else {
                  if (value.data.data.space_coordinates !== null) {
                    anchorLocations.push({
                      address: nbors[i].address,
                      losqi: nbors[i].losqi,
                      rssi: nbors[i].rssi,
                      losqiSa: losqiSa,
                      rssiSa: rssiSa,
                      rst: nbors[i].rst,
                      location: value.data.data.space_coordinates,
                      offset: NaN,
                      vector: [],
                      xDist: 0
                    })
                  }
                }
              }
            })
          }
        })
        setAnchors(anchorLocations)
        setMapVisible(true)
        setMapVisible(true)
        // scrollToBottom()
      })
    })
  }

  function test1() {
    let myAnchors = anchors
    let sa = myAnchors.filter((nbor) => nbor.rst === 0)
    let saLocation = []
    let xDist
    let xDist3d
    if (sa.length > 0) {
      saLocation = sa[0].location
      xDist = Math.sqrt((saLocation.y - actualPos[0]) ** 2 + (saLocation.x - actualPos[1]) ** 2 + (0 - height) ** 2)
      sa[0].offset = 0
    }
    let nborAnchors = myAnchors
    for (let i = 0; i < nborAnchors.length; i++) {
      let anchorPos = [myAnchors[i].location.y, myAnchors[i].location.x]
      let offset = myAnchors[i].offset
      // two points that define the line
      const point1 = { x: anchorPos[1], y: anchorPos[0], z: 0 };
      const point2 = { x: actualPos[1], y: actualPos[0], z: Number(height) };
      // the distance from the starting point
      // const distance = Math.abs(offset) + Math.abs(xDist);
      // xDist = Math.sqrt(xDist**2 - height**2)
      var distance = offset + xDist;
      // distance = Math.sqrt(distance ** 2 - height ** 2)
      // Calculate the direction vector of the line
      const directionVector = {
        x: point2.x - point1.x,
        y: point2.y - point1.y,
        z: point2.z - point1.z
      };
      // Calculate the magnitude of the direction vector
      const magnitude = Math.sqrt(directionVector.x ** 2 + directionVector.y ** 2 + directionVector.z ** 2);
      // Normalize the direction vector
      const normalizedDirection = {
        x: directionVector.x / magnitude,
        y: directionVector.y / magnitude,
        z: directionVector.z / magnitude
      };
      // Calculate the new point's coordinates
      const newX = point1.x + normalizedDirection.x * distance;
      const newY = point1.y + normalizedDirection.y * distance;
      var newPoint
      // The new point's coordinates
      newPoint = { x: newX, y: newY };
      let error = Math.sqrt((newX - actualPos[1]) ** 2 + (newY - actualPos[0]) ** 2)
      myAnchors[i].error = error
      myAnchors[i].vector = newPoint
      myAnchors[i].xDist = xDist
      myAnchors[i].xDist3d = xDist3d

    }
    return myAnchors
  }

  const eventHandlers = useMemo(() => ({
    dragend(e) {
      let latLng = e.target.getLatLng()
      setActualPos([latLng.lat, latLng.lng])
    },
  }), [])

  function anchorMarkers() {
    let tagPos = [tagLocation.y, tagLocation.x]
    let actualTagPos = actualPos
    let anchorsFiltered = []
    let anchorsLocal = test1()
    let totalError = 0
    // const matchingTag = csvTagData.filter((tag) => tag.serial_number === selectedTagSerial);
    const matchingTag = debugTagData
    let realTagPos = [0, 0, 0]
    if (matchingTag !== undefined) {
      if (matchingTag.length !== 0) {
        realTagPos = [matchingTag.y, matchingTag.x, 0]
      }
    }
    let rmse
    if (tagPos[0] !== 0 && tagPos[1] !== 0) {
      rmse = Math.sqrt((realTagPos[0] - tagPos[0]) ** 2 + (realTagPos[1] - tagPos[1]) ** 2).toFixed(3);
    }

    for (let i = 0; i < anchorsLocal.length; i++) {
      if (anchorsLocal[i].location !== null) {
        anchorsFiltered.push(anchorsLocal[i])
      }
    }
    for (let i = 0; i < circleAnchors.length; i++) {
      if (!isNaN(circleAnchors[i].error)) {
        totalError += circleAnchors[i].error
      }
    }
    var tagActualIcon = L.icon({
      iconUrl: starMarker,
      iconSize: [30, 30], // size of the icon
      iconAnchor: [15, 15], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })
    var tagIcon = L.icon({
      iconUrl: diamondMarker,
      iconSize: [30, 30], // size of the icon
      iconAnchor: [15, 15], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })
    var realTagIcon = L.icon({
      iconUrl: diamondMarkerRed,
      iconSize: [30, 30], // size of the icon
      iconAnchor: [15, 15], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })
    var myIcon = L.icon({
      iconUrl: circle,
      iconSize: [10, 10], // size of the icon
      iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })
    var firstIcon = L.icon({
      iconUrl: greenCircle,
      iconSize: [10, 10], // size of the icon
      iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
    })
    let saLocationObj = anchorsFiltered.filter((nbor) => nbor.rst === 0)
    let saLocation
    let saLocationArr
    let xDist = 0
    if (saLocationObj.length > 0) {
      saLocation = saLocationObj[0].location
      xDist = Math.sqrt((saLocation.y - tagPos[0]) ** 2 + (saLocation.x - tagPos[1]) ** 2)
      saLocationObj.offset = 0
      saLocationArr = [saLocation.y, saLocation.x]
    }
    let selector = mode
    return (
      <>
        {anchorsFiltered.map((anchor, i) =>
          <>
            <Marker id={anchor.address} key={i} position={[anchor.location.y, anchor.location.x]} icon={anchor.rst === 0 ? firstIcon : myIcon} draggable={false} ref={markerRef}>
              <Tooltip permanent >
                <b style={anchor.rst === 0 ? { color: 'red' } : { color: 'black' }} >
                  {anchor.address}
                </b>
              </Tooltip>
              <Popup2 >
                <b>
                  <table>
                    <tbody>
                      <tr><td>Coordinates:&nbsp;</td><td>{anchor.location.x.toFixed(3)}, {anchor.location.y.toFixed(3)}</td></tr>
                    </tbody>
                  </table>
                </b>
              </Popup2>
            </Marker>
            {selector === "interactive" ? <>
              {!isNaN(anchor.vector.y) && visibleLines.includes(anchor.address) ?
                <Polyline positions={[[anchor.vector.y, anchor.vector.x], [anchor.location.y, anchor.location.x]]} pathOptions={anchor.rst === 0 ? { color: 'red' } : anchor.losqi < 10 && anchor.losqiSa < 10 ? { color: 'rgb(90, 90, 90)' } : { color: 'rgb(51, 136, 255)' }}>
                  <Tooltip >
                    <b style={{ color: 'red' }}>
                      <table>
                        <tbody>
                          <tr><td>LOS Tag:</td><td>{anchor.losqi}</td></tr>
                          <tr><td>LOS Anchor:&nbsp;</td><td>{anchor.losqiSa}</td></tr>
                        </tbody>
                      </table>
                    </b>
                  </Tooltip>
                  <Popup2 >
                    <b>
                      <table>
                        <tbody>
                          <tr><td>endpoint:&nbsp;</td><td>{anchor.vector.x.toFixed(3)}, {anchor.vector.y.toFixed(3)}</td></tr>
                          <tr><td>x:&nbsp;</td><td>{anchor.xDist.toFixed(3)}</td></tr>
                          <tr><td>x3d:&nbsp;</td><td>{anchor.xDist3d}</td></tr>
                          <tr><td>length:&nbsp;</td><td>{(anchor.xDist + anchor.offset).toFixed(3)}</td></tr>
                          <tr><td>flat error:&nbsp;</td><td>{(Math.sqrt((anchor.location.y - actualTagPos[0]) ** 2 + (anchor.location.x - actualTagPos[1]) ** 2) - (anchor.xDist + anchor.offset)).toFixed(3)}</td></tr>
                          <tr><td>3d error:&nbsp;</td><td>{(Math.sqrt((anchor.location.y - actualTagPos[0]) ** 2 + (anchor.location.x - actualTagPos[1]) ** 2 + (0 - height) ** 2) - (anchor.xDist + anchor.offset)).toFixed(3)}</td></tr>
                        </tbody>
                      </table>
                    </b>
                  </Popup2>
                </Polyline>
                :
                <></>
              }
            </>
              :
              <Polyline positions={toggleSwitch && (selector === "rssi" || selector === "los") ? [saLocationArr, [anchor.location.y, anchor.location.x]] : [tagPos, [anchor.location.y, anchor.location.x]]}>
                {selector === "distance" ? <Tooltip >
                  <b style={{ color: 'red' }}>
                    <table>
                      <tbody>
                        <tr><td>{'a) Estimated Distance: '}</td><td>{typeof anchor.offset === "number" ? (anchor.offset + xDist).toFixed(3) + "m" : (xDist).toFixed(3) + "m"}</td></tr>
                        <tr><td>{'b) Actual Distance to Estimated Position (Blue Line)'}:&nbsp;</td><td>{Math.sqrt((anchor.location.y - tagPos[0]) ** 2 + (anchor.location.x - tagPos[1]) ** 2).toFixed(3) + "m"}</td></tr>
                        <tr><td>{'c) Actual Distance to Actual Position (to Star)'}: &nbsp;</td><td>{(Math.sqrt((anchor.location.y - actualTagPos[0]) ** 2 + (anchor.location.x - actualTagPos[1]) ** 2)).toFixed(3) + "m"}</td></tr>
                        <tr><td>{'d) Error (b-c)'}: &nbsp;</td><td>{(Math.sqrt((anchor.location.y - tagPos[0]) ** 2 + (anchor.location.x - tagPos[1]) ** 2) - Math.sqrt((anchor.location.y - actualTagPos[0]) ** 2 + (anchor.location.x - actualTagPos[1]) ** 2)).toFixed(3) + "m"}</td></tr>
                      </tbody>
                    </table>
                  </b>
                </Tooltip> : <></>}
                {selector === "rssi" ? <Tooltip >
                  <b style={{ color: 'red' }}>
                    RSSI: {toggleSwitch ? anchor.rssiSa : anchor.rssi}
                  </b>
                </Tooltip> : <></>}
                {selector === "los" ? <Tooltip >
                  <b style={{ color: 'red' }}>
                    LOS: {toggleSwitch ? anchor.losqiSa : anchor.losqi}
                  </b>
                </Tooltip> : <></>}
              </Polyline>
            }
          </>
        )}
        <Marker id={54321} key={54321} position={actualTagPos} zIndexOffset={80000} eventHandlers={eventHandlers} draggable={true} icon={tagActualIcon} ref={markerRef}>
          <Tooltip >
            Position: [{actualTagPos[1].toFixed(2)},{actualTagPos[0].toFixed(2)}]
          </Tooltip>
        </Marker>
        <Marker id={4321} key={4321} position={tagPos} zIndexOffset={1} draggable={false} icon={tagIcon} ref={markerRef}>
          <Tooltip >
            Serial Number: {selectedTagSerial} <br></br>
            Position: [{tagPos[1]},{tagPos[0]}, {tagHeight}]
          </Tooltip>
        </Marker>
        {realTagPos[0] !== 0 && realTagPos[1] !== 0 ?
          <Marker id={43212} key={43212} position={realTagPos} zIndexOffset={1} draggable={false} icon={realTagIcon} ref={markerRef}>
            <Tooltip >
              Position: [{realTagPos[0]},{realTagPos[1]},{realTagPos[2]}] <br></br>
              rmse: {rmse}
            </Tooltip>
          </Marker> :
          <></>
        }
        <Circle center={tagPos} radius={tagError} />
        <Circle center={actualTagPos} pathOptions={{ color: 'gray' }} radius={totalError} >
          <Tooltip >
            Radius: {totalError.toFixed(3)}
          </Tooltip>
        </Circle>

      </>
    )
  }

  function onRowClick(e) {
    setSelectedTagSerial(e.data.serial_number)
    getDebugTags(e.data.serial_number)
    setSelectedTag(e.data.id)
    setDataGrid2Visible(false)
    setMapVisible(false)
    setDateRangeVisible(true)
  }
  // serving: response[i].data.nbors[response[i].data.nbors.length - 1].address, //@@@ rst 0
  function saveClose() {
    let from
    let to
    let startString = datetime[0].start
    if(startString.length === 23){
      let startStringMs = startString.substring(20, startString.length);
      startString = startString.substring(0, startString.length - 4);
      from = new Date(new Date(startString).getTime() + Number(startStringMs))
    } else {
      from = new Date(datetime[0].start)
    }
    let endString = datetime[0].end
    if(endString.length === 23){
      let endStringMs = endString.substring(20, endString.length);
      endString = endString.substring(0, endString.length - 4);
      to = new Date(new Date(endString).getTime() + Number(endStringMs))
    } else {
      to = new Date(datetime[0].end)
    }
    setToastConfig({
      type: 'Warning',
      message: 'Loading',
      displayTime: 2000,
      isVisible: true,
    });
    if (legacy) {
      NetworkToolService.getLocationScansLegacy(selectedTag, from, to).then((value) => {
        let response = value.data.data
        let response2 = []
        for (let i = 0; i < response.length; i++) {
          response2.push({
            serving: response[i].data.nbors[0].address, //@@@ rst 0
            nbors: response[i].data.nbors,
            time: response[i].time,
            time2: "N/A",
            nborLength: response[i].data.nbors.length - 1,
            sequence: response[i].data.sequence,
            coords: "N/A",
            error: "N/A",
            rmse: "N/A",
            rssi: response[i].data.nbors[0].rssi,
            losqi: response[i].data.nbors[0].losqi
          })
        }
        setData2(response2)
        setToastConfig({
          type: 'Success',
          message: 'Data loaded',
          displayTime: 2000,
          isVisible: true,
        });
        let myCsv = []
        for (let i = 0; i < response2.length; i++) {
          let csvNbors = ""
          for (let k = 0; k < response2[i].nbors.length; k++) {
            csvNbors += `, ${response2[i].nbors[k].address}`
          }
          myCsv.push({
            serving: response2[i].serving,
            time: response2[i].time,
            nborLength: response2[i].nborLength,
            nbors: csvNbors,
            rssi: response[i].data.nbors[0].rssi,
            losqi: response[i].data.nbors[0].losqi
          })
        }
        setCsvData(myCsv)

      })
    } else {
      NetworkToolService.getLocationScans(selectedTag, from, to, limit).then((value) => {
        let response = value.data.data
        let response2 = []

        // const matchingTag = csvTagData.filter((tag) => tag.serial_number === selectedTagSerial);
        const matchingTag = debugTagData
        let realTagPos = [0, 0, 0]
        if (matchingTag !== undefined) {
          if (matchingTag.length !== 0) {
            // realTagPos = [matchingTag[0].x_axis, matchingTag[0].y_axis, matchingTag[0].height]
            realTagPos = [matchingTag.x, matchingTag.y, 0]
          }
        }
        let rmse

        for (let i = 0; i < response.length; i++) {
          let tagPos = null
          let tagCoords = null
          let locationFound = "Yes"
          let error = response[i].error
          let time = response[i].tslocationtime
          if (response[i].local_coordinates !== null){
            tagPos = [response[i].local_coordinates.x, response[i].local_coordinates.y, response[i].height]
            if (tagPos[0] !== 0 && tagPos[1] !== 0) {
              rmse = Math.sqrt((realTagPos[0] - tagPos[0]) ** 2 + (realTagPos[1] - tagPos[1]) ** 2).toFixed(3);
            }
            tagCoords = [response[i].local_coordinates.x.toFixed(2), response[i].local_coordinates.y.toFixed(2), response[i].height.toFixed(2)]
          } else {
            locationFound = "No"
            tagPos = "N/A"
            tagCoords = "N/A"
            rmse = "N/A"
            error = "N/A"
            time = "N/A"
          }
          response2.push({
            serving: response[i].data.nbors[0].address, //@@@ rst 0
            nbors: response[i].data.nbors,
            time: response[i].time,
            time2: time,
            nborLength: response[i].data.nbors.length - 1,
            sequence: response[i].data.sequence,
            coords: tagCoords,
            error: error,
            rmse: rmse,
            rssi: response[i].data.nbors[0].rssi,
            losqi: response[i].data.nbors[0].losqi,
            locationFound: locationFound
          })
        }
        setData2(response2)
        setToastConfig({
          type: 'Success',
          message: 'Data loaded',
          displayTime: 2000,
          isVisible: true,
        });
        let myCsv = []
        for (let i = 0; i < response2.length; i++) {
          let csvNbors = ""
          for (let k = 0; k < response2[i].nbors.length; k++) {
            csvNbors += `, ${response2[i].nbors[k].address}`
          }
          myCsv.push({
            serving: response2[i].serving,
            tslocationtime: response[i].tslocationtime,
            tsuwbTdoaTime: response[i].time,
            sequence: response[i].sequence,
            coords: response2[i].coords,
            error: response[i].error,
            rmse: response2[i].rmse,
            nborLength: response2[i].nborLength,
            nbors: csvNbors,
            rssi: response[i].data.nbors[0].rssi,
            losqi: response[i].data.nbors[0].losqi
          })
        }
        setCsvData(myCsv)

      })
    }
    setMapVisible(false)
    setDataGrid2Visible(true)
  }

  function onHiding2() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }

  function getFloorplan(spaceId) {
    SpacesService.getSpaceFloorplan(spaceId).then((res) => {
      let myFloorplan = URL.createObjectURL(res.data)
      setFloorplanUrl(myFloorplan)
    })
    SpacesService.getSpace(spaceId).then((res2) => {
      let response2 = res2.data
      let refSpaceBounds = response2.data.localCoordinates
      let correctedRefSpaceBounds = []
      for (let i = 0; i < refSpaceBounds.length; i++) {
        correctedRefSpaceBounds.push([refSpaceBounds[i][1], refSpaceBounds[i][0]])
      }
      let coords = correctedRefSpaceBounds
      let xMax = 0
      let yMax = 0
      for (let i = 0; i < coords.length; i++) {
        if (coords[i][0] > xMax) {
          xMax = coords[i][0]
        }
        if (coords[i][1] > yMax) {
          yMax = coords[i][1]
        }
      }
      setBounds(new latLngBounds([0, 0], [xMax, yMax]))
      setCenter([0 + xMax / 2, 0 + yMax / 2])
    })
  }

  async function getDebugTags(serialNumber) {
    NetworkToolService.getTagLocationDebug(serialNumber).then((value) => {
      setDebugTagData(value.data.data[0].actual_coordinates)
    })
  }

  function onToggle() {
    setToggleSwitch(!toggleSwitch)
  }
  async function onValueChanged2(e) {
    getFloorplan(e.value)
    getTags(e.value)
    setDataGrid2Visible(false)
    setDateRangeVisible(false)
    setMapVisible(false)
    setDataGrid1Visible(true)
  }

  async function onValueChanged(e) {
    getSpacesFloorplan(e.value)
    setDataGrid2Visible(false)
    setDateRangeVisible(false)
    setMapVisible(false)
    setDataGrid1Visible(false)
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  function interactiveClick() {
    setMode('interactive')
  }
  function distanceClick() {
    setMode('distance')
  }
  function rssiClick() {
    setMode('rssi')
  }
  function losClick() {
    setMode('los')
  }

  function MyGridlineLat() {
    if (show1m) {
      let latMax = bounds._northEast.lat
      let latMin = bounds._southWest.lat
      let lngMax = bounds._northEast.lng
      let lngMin = bounds._southWest.lng
      let lngDiff = lngMax - lngMin
      let lineArray = []
      let spacing = 1
      for (let i = 0; i < (lngDiff / spacing); i++) {
        lineArray.push([[latMin, i * spacing], [latMax, i * spacing]])
      }
      return (
        <>
          {lineArray.map((line, i) =>
            <Polyline key={i} positions={line} color={"red"} weight={1}>
            </Polyline>
          )}
        </>
      )
    }
    else {
      return (<></>)
    }
  }

  function MyGridlineLng() {
    if (show1m) {
      let latMax = bounds._northEast.lat
      let latMin = bounds._southWest.lat
      let latDiff = latMax - latMin
      let lngMax = bounds._northEast.lng
      let lngMin = bounds._southWest.lng
      let lineArray = []
      let spacing = 1
      for (let i = 0; i < (latDiff / spacing); i++) {
        lineArray.push([[i * spacing, lngMin], [i * spacing, lngMax]])
      }
      return (
        <>
          {lineArray.map((line, i) =>
            <Polyline key={i} positions={line} color={"red"} weight={1}>
            </Polyline>
          )}
        </>
      )
    }
    else {
      return (<></>)
    }
  }

  function MyGridlineLat5() {
    if (show5m) {
      let latMax = bounds._northEast.lat
      let latMin = bounds._southWest.lat
      let lngMax = bounds._northEast.lng
      let lngMin = bounds._southWest.lng
      let lngDiff = lngMax - lngMin
      let lineArray = []
      let spacing = 5
      for (let i = 0; i < (lngDiff / spacing); i++) {
        lineArray.push([[latMin, i * spacing], [latMax, i * spacing]])
      }
      return (
        <>
          {lineArray.map((line, i) =>
            <Polyline key={i} positions={line} color={"red"} weight={2} visible={false}>
            </Polyline>
          )}
        </>
      )
    }
    else {
      return (<></>)
    }
  }

  function MyGridlineLng5() {
    if (show5m) {
      let latMax = bounds._northEast.lat
      let latMin = bounds._southWest.lat
      let latDiff = latMax - latMin
      let lngMax = bounds._northEast.lng
      let lngMin = bounds._southWest.lng
      let lineArray = []
      let spacing = 5
      for (let i = 0; i < (latDiff / spacing); i++) {
        lineArray.push([[i * spacing, lngMin], [i * spacing, lngMax]])
      }
      return (
        <>
          {lineArray.map((line, i) =>
            <Polyline key={i} positions={line} color={"red"} weight={2}>
            </Polyline>
          )}
        </>
      )
    }
    else {
      return (<></>)
    }
  }

  function setGrid5() {
    setTimeout(function () {
      setShow5m(!show5m)
    }, 1);
  }

  function setGrid() {
    setTimeout(function () {
      setShow1m(!show1m)
    }, 1);
  }

  const handleChangeLimit = (event) => {
    setLimit(Number(event.target.value))
  };

  const handleChange = (event) => {
    setDatetime([{
      start: event.target.value,
      end: datetime[0].end
    }])
  };

  const handleChange2 = (event) => {
    setDatetime([{
      start: datetime[0].start,
      end: event.target.value
    }])
  };

  const handleChangeHeight = (event) => {
    setHeight(event.target.value)
  };

  const handleChangeAnchorSelection = (event) => {
    setVisibleLines(event.selectedRowKeys)
    setCircleAnchors(event.selectedRowsData)
  };

  function uploadClick(e) {
    setUploadPopupVisible(true)
  }

  function onHidingUpload() {
    setUploadPopupVisible(false)
  }

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleSubmit = (event) => {
    Papa.parse(selectedFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setCsvTagData(results.data)
      },
    });
    onHidingUpload()
  }

  const legacyValueChanged = (event) => {
    setLegacy(!legacy)
  };

  const dataGridRef = useRef()
  const dataGridRefL = useRef()


  return (
    <div className='networkTool-container'>
      <div className='networkTool-spaceDropdown'>
        <div className='dateBtn'>
          <SelectBox
            placeholder="Select a Tenant"
            focusStateEnabled={false}
            width='400px'
            height={40}
            items={tenants}
            stylingMode="outlined"
            onValueChanged={onValueChanged}
            valueExpr="id"
            displayExpr="name"
          >
          </SelectBox>
        </div>
        <div className='dateBtn'>
          <SelectBox
            placeholder="Select a Space"
            focusStateEnabled={false}
            width='400px'
            height={40}
            items={validSpace}
            stylingMode="outlined"
            onValueChanged={onValueChanged2}
            valueExpr="id"
            displayExpr="name"
          >
          </SelectBox>
        </div>
        {/* <div className="uploadBtntags" title={"Upload tags"} onClick={uploadClick} key={'uploadbtntagsbtns'}>
          <i className="dx-icon-upload" style={{ "fontSize": "24px" }}></i>
        </div> */}
      </div>
      <DataGrid
        loadPanel={false}
        showColumnLines={false}
        showRowLines={true}
        height={'400px'}
        dataSource={data}
        showBorders={false}
        onRowClick={onRowClick}
        visible={dataGrid1Visible}
        keyExpr="id"
        ref={dataGridRef}
        noDataText="No Data found"
      >
        <Selection mode="single" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible={true} width="240" placeholder="Search..." />
        <Scrolling mode="infinite" />
        <Column
          dataField={'serial_number'}
          caption={'Serial Number'}
          width={190}
          allowHeaderFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'device_type_id'}
          caption={'Type ID'}
          allowEditing={false}
          alignment={"left"}
        />
        <Column
          dataField={'last_update'}
          dataType="datetime"
          format="EEE MMM dd yyyy, hh:mm:ss a"
          caption={'Last Update'}
          allowFiltering={false}
          allowEditing={false}
        />
        <Column
          dataField={'battery'}
          calculateDisplayValue={getBattery}
          dataType="percent"
          caption={'Battery'}
          allowEditing={false}
        />
        <Column type="buttons" visible={toggleEditBool} width={150}>
          <Button name="edit" />
        </Column>
      </DataGrid>
      <div className={dateRangeVisible ? 'dateBtn3' : 'dateBtn3-none'}>
        <div className='networkTool-datetime'>
          <form type="formDevX">
            <div className='form-label'>
              Start
            </div>
            <input type="textDevX" value={datetime[0].start} onChange={handleChange} />
            <div className='form-label'>
              End
            </div>
            <input type="textDevX" value={datetime[0].end} onChange={handleChange2} />
          </form>
          <div className='networkTool-datetimeBtn'>
            <Button2
              width={200}
              height={40}
              text="Text"
              type="normal"
              stylingMode="outlined"
              onClick={saveClose}
              className='red-btn'
            >
              SAVE
            </Button2>
          </div>
          {/* <div className='networkTool-legacy-switch-text'>
            Fast
          </div>
          <div className='networkTool-legacy-switch'>
            <Switch
              value={legacy}
              onValueChanged={legacyValueChanged}
            />
          </div> */}
          <div className={!legacy ? 'networkTool-limit' : 'dateBtn3-none'}>
            <form type="formDevX">
              <div className='form-label'>
                Limit
              </div>
              <input type="textDevX" value={limit} onChange={handleChangeLimit} />
            </form>
          </div>
        </div>
      </div>
      <DataGrid
        loadPanel={false}
        showColumnLines={false}
        showRowLines={true}
        height={'400px'}
        dataSource={data2}
        showBorders={false}
        onRowClick={onRowClick2}
        visible={dataGrid2Visible}
        keyExpr="time"
        ref={dataGridRef}
        noDataText="No Data Found"
      >
        <Selection mode="single" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible={true} width="240" placeholder="Search..." />
        <Scrolling mode="infinite" />
        <Column
          dataField={'serving'}
          caption={'Serving Anchor'}
          allowEditing={false}
          alignment='left'
        />
        {/* <Column
          dataField={'time'}
          dataType="datetime"
          format="EEE MMM dd yyyy, HH:mm:ss:SSS"
          caption={'ts_location Time'}
          allowFiltering={false}
          allowEditing={false}
          width={250}
        /> */}
        <Column
          dataField={'time2'}
          dataType="datetime"
          format="yyyy-MM-ddTHH:mm:ss:SSS"
          caption={'ts_uwb_tdoa Time'}
          allowFiltering={false}
          allowEditing={false}
          width={250}
        />
        <Column
          dataField={'sequence'}
          allowEditing={false}
        />
        <Column
          dataField={'coords'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'error'}
          caption={'Error (m)'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'rmse'}
          caption={'RMSE'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'rssi'}
          caption={'RSSI'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'losqi'}
          caption={'LOSQI'}
          allowEditing={false}
          allowFiltering={false}
        />
        <Column
          dataField={'nborLength'}
          caption={'Nbor Anchors (Total)'}
          allowEditing={false}
          alignment="left"
          width={"180px"}
        />
        <Column
          dataField={'locationFound'}
          allowEditing={false}
          alignment="left"
          width={"180px"}
        />
      </DataGrid>
      <div className={dataGrid2Visible ? 'networkTool-export' : 'dateBtn3-none'}>
        <CSVLink style={{ textDecoration: 'none', color: 'black' }} filename={`Tag-${selectedTagSerial}-redboard.csv`} data={csvData}>Export</CSVLink>
      </div>
      <div style={{ height: '100%', width: '100%' }} ref={listRef} className={mapVisible ? 'networkTool-visible' : 'dateBtn3-none'}>
        <div className='networkTool-btnsContainer'>
          <div onClick={interactiveClick} className={mode === "interactive" ? 'networkTool-btnsActive' : 'networkTool-btns'}>
            Interactive
          </div>
          <div onClick={distanceClick} className={mode === "distance" ? 'networkTool-btnsActive' : 'networkTool-btns'}>
            Distance (Error)
          </div>
          <div onClick={rssiClick} className={mode === "rssi" ? 'networkTool-btnsActive' : 'networkTool-btns'}>
            RSSI
          </div>
          <div onClick={losClick} className={mode === "los" ? 'networkTool-btnsActive' : 'networkTool-btns'}>
            LOS Quality
          </div>
          <div>
            <div className={mode === "interactive" ? 'networkTool-datetime' : 'display-none'}>
              <form type="formDevX">
                <div className='form-label'>
                  Y distance to Anchors
                </div>
                <input type="textDevX" value={height} onChange={handleChangeHeight} />
              </form>
            </div>
          </div>
          <div className={mode === "rssi" || mode === "los" ? 'networkTool-toggle' : 'dateBtn3-none'}>
            Tag &nbsp; &nbsp;
            <Switch
              value={toggleSwitch}
              onValueChanged={onToggle}
            />
            &nbsp; &nbsp;
            Serving Anchor
          </div>
        </div>
        <MapContainer
          id="map"
          center={center}
          style={{ height: '800px', width: '100%' }}
          zoom={4}
          min-zoom={2}
          maxZoom={12}
          zoomSnap={0}
          crs={CRS.Simple}
        >
          <ImageOverlay
            url={floorplanUrl}
            bounds={bounds}
            style={{ height: '100px', width: '100px' }}
            opacity={1}
            zIndex={10}
          />
          {anchorMarkers()}
          <MyGridlineLng></MyGridlineLng>
          <MyGridlineLat></MyGridlineLat>
          <MyGridlineLng5></MyGridlineLng5>
          <MyGridlineLat5></MyGridlineLat5>
          <div className='legend'>
            <div className='legendText'>
              <img src={diamondMarkerRed} className='networkTool-logo' alt='' /><br></br>
              <img src={diamondMarker} className='networkTool-logo' alt='' /><br></br>
              <img src={starMarker} className='networkTool-logo' alt='' /><br></br>
              <img src={circle} className='networkTool-logo' alt='' /><br></br>
              <img src={greenCircle} className='networkTool-logo' alt='' />
            </div>
            <div className='legendTextBeside'>
              <div className='legendTextBesideEach'>
                Real Tag Position
              </div>
              <div className='legendTextBesideEach'>
                Tag Estimated Position
              </div>
              <div className='legendTextBesideEach'>
                Tag Actual Position
              </div>
              <div className='legendTextBesideEach'>
                Nbor Anchor
              </div>
              <div className='legendTextBesideEach'>
                Serving Anchor
              </div>
            </div>
          </div>
          <div className='floorplan-5mGrid' onClick={setGrid5}>
            5m Grid
          </div>
          <div className='floorplan-1mGrid' onClick={setGrid}>
            1m Grid
          </div>
        </MapContainer>
        <div style={{ height: '100%', width: '100%' }} ref={listRef} className={mapVisible ? 'networkTool-visible' : 'networkTool-visible'}>
          &nbsp; &nbsp; &nbsp; Height: {tagHeight}m &nbsp; &nbsp; &nbsp; Total Tag Error: {tagError}m
          <DataGrid
            loadPanel={false}
            showColumnLines={false}
            showRowLines={true}
            max-height={'600px'}
            dataSource={anchors}
            showBorders={false}
            visible={dataGrid2Visible}
            keyExpr="address"
            ref={dataGridRefL}
            noDataText="No Data Found"
            onSelectionChanged={handleChangeAnchorSelection}
            defaultSelectedRowKeys={[]}
          >
            <Selection
              mode="multiple"
              selectAllMode={'allPages'}
              showCheckBoxesMode={'always'}
            />
            <HeaderFilter visible={false} />
            <SearchPanel visible={false} width="240" placeholder="Search..." />
            <Scrolling mode="infinite" />
            <Column
              dataField={'address'}
              allowEditing={false}
              alignment='left'
            />
            <Column
              dataField={'losqi'}
              caption={'LOSQI Tag'}
              allowEditing={false}
              alignment='left'
            />
            <Column
              dataField={'losqiSa'}
              caption={'LOSQI SA'}
              allowEditing={false}
              alignment='left'
            />
            <Column
              dataField={'rssi'}
              caption={'RSSI Tag'}
              allowEditing={false}
              alignment='left'
            />
            <Column
              dataField={'rssiSa'}
              caption={'RSSI SA'}
              allowEditing={false}
              alignment='left'
            />
            <Column
              dataField={'rst'}
              caption={'RST'}
              allowEditing={false}
              alignment='left'
            />
            <Column
              dataField={'offset'}
              allowEditing={false}
              alignment='left'
            />
          </DataGrid>
        </div>
      </div>
      <div ref={messagesEndRef} />
      <Popup
        visible={uploadPopupVisible}
        onHiding={onHidingUpload}
        dragEnabled={false}
        height={150}
        width={600}
        showCloseButton={true}
        showTitle={true}
        title="Upload CSV"
        container=".dx-viewport"
        key={'uploadCsv2'}
      >
        <>
          <Toast
            setParentToastConfig={setToastConfig}
            toastBody={toastConfig}
          />
          <form onSubmit={handleSubmit}>
            <input type="file" onChange={handleFileSelect} />
            <input type="submit" value="Upload File" />
          </form>
        </>
      </Popup>
      <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding2}
        displayTime={toastConfig.displayTime}
      />
    </div>
  )
}