import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import GatewayService from "api/gateway.service";
import Toast from 'components/Toast';
import GatewayStatus from "./gatewayDetail.js"
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import moment from 'moment-timezone';

export default function App(props) {
    const tabPanelRef = useRef(null);
    const gatewayId = props.match.params.gatewayId;
    const [pageName, setPageName] = useState("");
    const [dateRange, setDateRange] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
        displayTime: 5000,
    });
    const history = useHistory();

    function getData() {
        GatewayService.getGatewayData(gatewayId).then((value) => {
            setPageName(value.data.data.name)
        })
    }

    function goBack() {
        history.push({
            pathname: `/config/gateway`,
            state: {
                response: ""
            }
        })
    }

    useEffect(() => {
        getData()
    }, []);

    function onSave() {
        goBack()
    }

    const downloadReport = () => {
        const elementToCapture = document.getElementById('full-page-content-gateway-tab');
    
        domtoimage.toPng(elementToCapture)
          .then((dataUrl) => {
            // Handle the captured image dataUrl as needed
            console.log(dataUrl);
            const pdf = new jsPDF();
    
            // Add the captured image as a page in the PDF
            pdf.addImage(dataUrl, 'PNG', 10, 10, 190, 120); // Adjust the position and size as needed
    
            // Save the PDF with a specified filename
            pdf.save(`Events-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`);
            
            
    
          })
          .catch((error) => {
            
            console.error('Error capturing element:', error);
          });
      };

    return (
        <>
        <div id='full-page-content-gateway-tab'>
            <div className='topBarAssetTab'>
                <div className='title' >
                     Gateway: {pageName} &nbsp;&nbsp;
                </div>
                <div className='uploadBtnAssetsReport' style={{right: "35px", top: "5px"}}>
                  <button id="generatePdfButton" alt="Download Report" title="Download Report" type="button" onClick={() => downloadReport('events')}>
                    <img src="../icons/reports.svg" alt="Download Report" />
                  </button>
                </div>
                <div className='backBtn' onClick={onSave}>
                    <i className="dx-icon-close"></i>
                </div>
            </div>
            <TabPanel ref={tabPanelRef}
            selectedIndex={selectedTabIndex}
            onOptionChanged={(e) => {
              if (e.name === "selectedIndex") {
                setSelectedTabIndex(e.value);
              }
            }}>
                <Item title="Status" >
                    <GatewayStatus 
                        gatewayId={gatewayId}
                        onDateChange={(dateRange)=>setDateRange(dateRange)}
                        dateRange={dateRange}
                        selectedTabIndex={selectedTabIndex}
                    />
                </Item>
            </TabPanel>
            </div>
            <Toast
                setParentToastConfig={setToastConfig}
                toastBody={toastConfig}
            />
        </>
    );
}