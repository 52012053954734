import React, { useState, useEffect, useRef } from 'react'
import 'devextreme/data/odata/store'
import DataGrid, {
  Column,
  Paging,
  FilterRow,Editing,
  Form,
  SearchPanel,
  RequiredRule
} from 'devextreme-react/data-grid'
import { Item, EmailRule, PatternRule } from 'devextreme-react/form'
import TenantService from "../../api/tenant.service"
import * as DOMPurify from 'dompurify';
import Toast from 'components/Toast';
import { useHistory } from "react-router";
import { Popup as AssignTenantPopup } from 'devextreme-react/popup';

export default function App() {
  const [data, setData] = useState([]);
  const dropDataGridRef = useRef();
  const [loadingText, setloadingText] = useState('Loading...');
  const [showPopup, setShowPopup] = useState(false)
  const history = useHistory();
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 5000,
  });

  function getData() {
    TenantService.getTenants().then((value) => {
      let response=value.data
      setData(response.data)
    })
  }

  function onRowInserted(e) {
    TenantService.addTenant(e.data.name, e.data.email, e.data.password, e.data.firstName, e.data.lastName, e.data.mobileNumber).then((tenant) => {
      getData()
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: tenant?.status===200 ? 'success' : 'error', 
        status: tenant?.data?.status ? tenant?.data?.status : tenant.status,
        message: tenant?.data?.message ? tenant.data.message : tenant.message,
      });
      
    })
  }

  function rulesText() {
    return (
      <>
        Edit
      </>
    )
  }

  function buttonColor(e) {
    if (((e.column.caption === 'Rules')) && e.rowType !== "header") {
      e.cellElement.style.color = "#C5260C";
      e.cellElement.style.setProperty('cursor', 'pointer', 'important')
    }
  }

  function showInfo(e) {
     if (e.column.caption === 'Rules') {
      e.cellElement.style.color = "#337ab7";
      history.push({
        pathname: `/behaviors/rule/available/${e.data.id}`,
        state3: {
          response: ""
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, []);

  const onHiding = function() {
    setShowPopup(false)
  }
  return (
    <>
    <div className='headerHeadingPage'>
		  <div className='pageFullWidthEvents'>
        <h5 className='marginZeroEvents'>
          <b>Tenants</b>
        </h5>
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
      <DataGrid
        ref={dropDataGridRef}
        showColumnLines={false}
        showRowLines={true}
        onCellClick={showInfo}
        onCellPrepared={buttonColor}
        onRowInserted={onRowInserted}
        allowColumnResizing={true}
        height={'calc(100vh - 51px)'}
        noDataText={loadingText}
        onColumnsChanging={function (e) {
          if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
            setloadingText('No Data');
          }
        }}
        dataSource={data}>
        <Paging enabled={false} key={'paging'}/>
         <Editing
          allowUpdating={false}
          allowDeleting={false}
          allowAdding={true}
          use-icons={true}
          mode="popup"
          texts={{
            addRow: 'Add Tenant',
          }}
        >
          <Form colCount={1}>
            <Item dataField="name" >
              <RequiredRule message="Name is required" />
            </Item>
            <Item dataField="email">
              <RequiredRule message="Email is required" />
              <EmailRule message="Email is invalid" />
            </Item>
            <Item
              dataField="mobileNumber"
              helpText="Please provide a phone number that begins with a plus sign and includes the corresponding country code."
            />
            <Item dataField="password" >
              <RequiredRule message="Password is required" />
            </Item>
            <Item dataField="firstName" >
              <RequiredRule message="First name is required" />
            </Item>
            <Item dataField="lastName" >
              <RequiredRule message="Last name is required" />
            </Item>
          </Form>
        </Editing> 
        <FilterRow visible="true" apply-filter="true" />
        <SearchPanel visible="true" width="240" placeholder="Search..." />
        <Column
          dataField={DOMPurify.sanitize('name')}
          caption={"Tenant Name"}
          width={390}
          hidingPriority={8}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={DOMPurify.sanitize('firstName')}
          caption={"First Name"}
          visible={false}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('lastName')}
          caption={"Last Name"}
          visible={false}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('email')}
          caption={"Email"}
          visible={false}
        >
        </Column>
        <Column
          dataField={`mobileNumber`}
          caption={"Mobile Number"}
          width={300}
          hidingPriority={8}
        >
          <PatternRule
            pattern={/^\+(?:[0-9] ?){6,14}[0-9]$/}
            message={'Your phone must have "+11234567890" format!'}
          />
        </Column>
        <Column
          dataField={DOMPurify.sanitize('password')}
          caption={"Password"}
          visible={false}
        >
          <PatternRule
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
            message="Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character"
          />
        </Column>
        <Column
          dataField={DOMPurify.sanitize('type')}
          caption={'Role'}
          allowSorting={false}
          allowFiltering={false}
        >
        </Column>
        <Column
          caption={'Rules'}
          cellRender={rulesText}
        ></Column>
      </DataGrid>
      <AssignTenantPopup
          visible={showPopup}
          onHiding={onHiding}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={false}
          showTitle={true}
          title="Add Behavior"
          container=".dx-viewport"
          width={500}
          height={350}
        >
      </AssignTenantPopup>
      </div>
      </div>
    </>
  );
}
