import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getGateways() {
    return Axios.get("/api/gateway");
  },
  addGateway(name) {
    return Axios.post(
      "/api/gateway/add",
      {
        name: name
      }
    );
  },
  getGatewayData(gatewayId) {
    if (gatewayId === undefined) {
      return ""
    }
    return Axios.get(
      `/api/gateway/data/${gatewayId}`
    );
  },
  getGatewayDetail(gatewayId, from, to) {
    if (gatewayId === undefined) {
      return ""
    }
    return Axios.post(
      "/api/gateway-detail",
      {
        from: from,
        to: to,
        gatewayId: gatewayId
      }
    );
  },
  updateGatewayTenant(selectedTenant, gatewayId){
    return Axios.patch(
      "/api/gateway/tenant/update",
      {
        tenantId: selectedTenant,
        gatewayId: gatewayId
      }
    );
  },
  updateGatewaySpace(gatewayId, spaceId){
    return Axios.patch(
      "/api/gateway/space/update",
      {
        gatewayId: gatewayId,
        spaceId: spaceId
      }
    );
  }
};