import React from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { TextBox } from 'devextreme-react/text-box';
import Box, { Item as BoxItem } from 'devextreme-react/box';

function DeviceConfigItems({configData, handleChange}, props) {
  const cellTemplate = (data, column) => {
    if (data.id === 1) { // it is for device_setting_type for Uplink Rate, Stationary Uplink Rate and Motion Uplink Rate
      if (column === 'prefilled') {
        return <TextBox value={data.value} disabled={true} onValueChange={(text)=> handleChange(text, data)} width={200} />;
      } else{
        return <TextBox value={data.newValue} onValueChange={(text)=> handleChange(text, data)} width={200} />;
      }
      
    } else if (data.id === 2) { // it is for device_setting_type for Device Offline Sleep Support  
      if (column === 'prefilled') {
        return <CheckBox disabled={true} defaultValue={data.value === true} />;
      } else {
        return <CheckBox defaultValue={data.newValue === true} onValueChanged={(value)=> handleChange(value, data)} />;
      }
    } else {
      return "";
    }
  };

  return (
    <div className='form' style={{ border: '1px solid #efefef', borderBottom: 0}}>
      <>
        <Box
          key={'config'}
          direction="row"
          style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#efefef', borderBottom: '1px solid #efefef', padding: 10 }}
        >
          <BoxItem ratio={1}>
            <div className="box-item"><b>Type</b></div>
          </BoxItem>
          <BoxItem ratio={1}>
            <div className="box-item green"><b>Existing Info</b></div>
          </BoxItem>
          <BoxItem ratio={1}>
            <div className="box-item green"><b>Update Info</b></div>
          </BoxItem>
        </Box>
        {configData.length > 0 ? configData.map((deviceConfig)=> {
          return (
            <Box
              key={deviceConfig.id+Math.random()}
              direction="row"
              style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid #efefef', padding: 10 }}
            >
              <BoxItem ratio={1}>
                <div className="box-item orange">{deviceConfig.name}</div>
              </BoxItem>
              <BoxItem ratio={1}>
                {cellTemplate(deviceConfig, 'prefilled')}
              </BoxItem>
              <BoxItem ratio={1}>
                {cellTemplate(deviceConfig, 'entered')}
              </BoxItem>
            </Box>
          )
        }) : <div style={{textAlign: "center", paddingTop: "10px", fontSize: "16px", display: "contents"}}>
            <p >No Data</p>
          </div>}
      </>
    </div>
  );
}

export default DeviceConfigItems;
