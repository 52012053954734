import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getTenantEntityGroup() {
        return Axios.get(`/api/entity/tenant/entity-group`);
    },

    getTenantEntityRules() {
        return Axios.get(`/api/entity/tenant/rules`);
    },
    getSeverity() {
        return Axios.get(`/api/severity`);
    },

    getTenantEntityRulesAvailable(tenantId) {
        return Axios.post(
            "/api/entity/tenant/rules",
            {
                tenantId: tenantId,
            }
        );
    },

    addRuleToTenant(tenantId, ruleId){
        return Axios.post(
            "/api/entity/tenant/add/rule",
            {
                tenantId: tenantId,
                ruleId : ruleId
            }
        );
    },

    getEntitiesInGroup(groupId) {
        return Axios.post(
            "/api/entity/group",
            {
                groupId: groupId,
            }
        );
    },

    getEntityRuleByEntityGroup(groupId) {
        return Axios.post(
            "/api/entity/rule/entity-group",
            {
                groupId: groupId,
            }
        );
    },

    createEntityGroupRuleMap(entityGroupId, ruleId, ruleInfo, name) {
        return Axios.post(
            "/api/entity/entity-group-rule-map/create",
            {
                entityGroupId: entityGroupId,
                ruleId: ruleId,
                name: name,
                ruleInfo: ruleInfo
            }
        );
    },

    deleteRuleFromEntityGroupRuleMap(id) {
        return Axios.del(
            "/api/entity/rule/entity-group-rule-map/delete",
            {
                id: id
            }
        );
    },

    getEntitiesList(type) {
        return Axios.post(
            "/api/entity/list/type",
            {
                type: type,
            }
        );
    },

    createEntityGroup(name) {
        return Axios.post(
            "/api/entity/entity-group/create",
            {
                name: name,
            }
        );
    },

    deleteEntityGroupMap(entities, entityGroupId) {
        return Axios.del(
            "/api/entity/entity-group-map/delete",
            {
                entities: entities,
                entityGroupId: entityGroupId
            }
        );
    },

    createEntityGroupMap(entities, entityGroupId) {
        return Axios.post(
            "/api/entity/entity-group-map/create",
            {
                entities: entities,
                entityGroupId: entityGroupId
            }
        );
    },

    deleteEntityGroup(id) {
        return Axios.del(
            "/api/entity/entity-group/delete",
            {
                id: id,
            }
        );
    },

    editEntityGroup(name, id) {
        return Axios.patch(
            "/api/entity/entity-group/edit",
            {
                name: name,
                id: id,
            }
        );
    },

    editEntityGroupRuleMap(id, ruleId, ruleInfo, name) {
        return Axios.patch(
            "/api/entity/rule/entity-group-rule-map/edit",
            {
                id: id,
                ruleId: ruleId,
                name: name,
                ruleInfo: ruleInfo
            }
        );
    },
    deleteTenantRule(tenantId, ruleId, isConfirm){
        return Axios.del(
            "/api/tenant/rule",
            {
                tenantId: tenantId,
                ruleId: ruleId,
                isConfirm: isConfirm
            }
        );
    }
};
