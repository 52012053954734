import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updateUserNotification(notificationId) {
    return Axios.post(
      "/api/notification/update",
      {
        id: notificationId
      }
    );
  },
  getUserSubscribedNotifications(){
    return Axios.get(
        "/api/notification"
    )
  },
  getListOfTypes(){
    return Axios.get(
      "/api/notification/types"
    )
  },
  updateUserNotificationPreferences(data){
    return Axios.patch(
      "/api/tenant/notification/update",
      {
        data: data
      }
    )
  }
};