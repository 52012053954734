import React from 'react';

export default function TooltipTemplate(pieChart) {
  const handleOnClickCenter = () => {
    switch(pieChart._$element.attr('id')) {
      case 'pieAssetsFirst':
      case 'pieAssetsUtilization':
        window.location.href = "/#/assets"
        break;
      case 'pieTagsFirst':
      case 'pieTagsBattery':
      case 'pieAnchorsFirst':
      case 'pieAnchorsBattery':
        window.location.href = "/#/devices"
        break;
      case 'pieGatewaysFirst':
        window.location.href = "/#/config/gateway"
        break;
      default:
        // do nothing for now
    }
  };
  return (
    <>
      {pieChart.getAllSeries()[0]._data.length ? <svg onClick={handleOnClickCenter}>
        <circle cx="100" cy="100" r={pieChart.getInnerRadius() - 6} fill="#eee" style={{ outline: 'none', cursor: 'pointer'}}></circle>
        <text textAnchor="middle" x="100" y="110" style={{ fontSize: 18, fill: '#000000', fontWeight: '400', cursor: 'pointer' }}>
          {pieChart.getAllSeries()[0]._data[0].data.tag1 ? <>
            <tspan x="100" y='95'>{pieChart.getAllSeries()[0]._data[0].tag}</tspan>
            <tspan x="100" y='115'>{pieChart.getAllSeries()[0]._data[0]?.data?.tag1}</tspan>
            </> : <tspan x="100">{pieChart.getAllSeries()[0]._data[0].tag}</tspan> }
        </text>
      </svg> : <svg></svg>}
    </>
  );
}
