import React, { useState, useEffect } from 'react';
import { Toast as DevextremeToast } from 'devextreme-react/toast';

function Toast({setParentToastConfig, toastBody}) {
	const [toastMessageType, setToastMessageType] = useState('info');
	const [message, setMessage] = useState('');

	// firstly we have to make sure that the code
	// data and message it has to be consistent
	//listing error codes
	//500, 422, 401, 200, 302, 201, 404, 403
  useEffect(() => {
  	let messageType = "INFO";
  	if(toastBody.isVisible){
  		switch(toastBody.status){
	  		case 500:
	  		case 401:
	  		case 404:
			case 400:
	  		case 422:
				case 403:
	  		 	setToastMessageType('error');
	  		 	messageType = 'ERROR';
	  			break;
			 	case 200:
			 		messageType = 'SUCCESS';
			 		setToastMessageType('success');
			 		break;
				case 320:
					messageType = 'SUCCESS';
					setToastMessageType('success-warning');
					break;
		 		default:
		 			setToastMessageType('error');
 			}
 		}
		if(toastBody?.message?.match(/status code 403/ig)){
			setMessage("INFO: Unauthorized: Access denied");
		} else {
			setMessage(`${messageType}: ${toastBody.message}`);
		}
  }, [toastBody]);

  function onHidingToast() {
    setToastMessageType('info');
    setParentToastConfig({...toastBody,isVisible: false});
  }

	return (
    <DevextremeToast
    	visible={toastBody?.isVisible}
    	message={message}
    	type={toastMessageType}
    	onHiding={onHidingToast}
    	displayTime={toastBody?.displayTime}
  	/>
  );
}

export default Toast;
