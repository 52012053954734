import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  StateStoring,
  HeaderFilter,
  SearchPanel,
  Editing,
  Texts,
  Button,
  Form,
  Popup,
  Item,
  FilterPanel
} from 'devextreme-react/data-grid';
import {
  Popup as GatewaysPopup,
  Popup as UpdateGatewayPopup,
  SelectBox,
  Button as SelectButton
} from 'devextreme-react';
import Toast from 'components/Toast';

import TenantService from "api/tenant.service";
import GatewayService from 'api/gateway.service';
import SpacesService from 'api/spaces.service';
import Move from 'assets/icons/move.svg';
import './gateway.css';

export default function App() {
  const dataGridRef = useRef();
  const history = useHistory();
  const [spaceData, setSpaceData] = useState([])
  const [data, setData] = useState([]);
  const [tenants, setTenants] = useState([])
  const [gatewayPopup, setGatewayPopup] = useState(false);
  const [gatewayUpdatePopup, setGatewayUpdatePopup] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedGatewayUpdate, setSelectedGatewayUpdate] = useState(null);
  const [loadingText, setloadingText] = useState('Loading...');
  const [gatewayName, setGatewayName] = useState('');
  const [gatewayUpdateName, setGatewayUpdateName] = useState('');
  const [gatewayId, setGatewayId] = useState(null);
  const [showTenantBtn, setShowTenantBtn] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 1000,
  });
  const myTenant = TenantService.getTenant();

  async function getData() {
    GatewayService.getGateways().then(async (value) => {
      if (value?.response?.data?.status === 403){
        await setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: value.status===200 ? 'success' : 'error',
          status: value?.data?.status ? value.data.status : value?.status,
          message: value?.data?.message ? value.data.message : value?.message,
        });
        setTimeout(() => {
          history.push({
            pathname: '/overview'
          })
        }, 1000);
      }
      if(value.data.data.length === 0){
        setloadingText('No Data')
      }
      setData(value?.data?.data)
    })
  }

  function getSpaces() {
    SpacesService.getSpaces(myTenant).then((value) => {
      let mydata = value.data.data
      mydata.unshift({
        "name": "None",
        "id": null
    })
      setSpaceData(mydata)
    })
  }

  function checkRole() {
    const menuItems = JSON.parse(localStorage.getItem('menu'))
    menuItems.map((item) => {
      if(item.text === 'Configuration'){
        item.items.map((data) => {
          if(data.path === '/config/tenants'){
            setShowTenantBtn(true)
          }
        })
      }
    })
  }

  function getStatus(rowData) {
    if(rowData.status){
      return "Online"
    } else if (!rowData.status){
      return "Offline"
    }
    return rowData.status;
  }

  function showInfo(e) {
    if (e.data){
      if (e.column.name === 'name') {
        e.cellElement.style.color = "#337ab7";
        history.push({
          pathname: `/gateway/${e.data.id}`,
          state3: {
            response: ""
          }
        })
      }
    }
  }

  function buttonColor(e) {
    if(e.column.dataField === 'status' && e.rowType !== "header"){
      if(e.data!== undefined){
        if(e.data.status!==undefined){
          if(e.data.status){
            e.cellElement.style.color = "#12DF1C";
          }
          if(!e.data.status){
            e.cellElement.style.color = "#FF0000";

          }
        }
      }
    }

    if ((e?.column?.dataField === 'name') && e?.rowType !== "header") {
      e.cellElement.style.color = "#337ab7";
      e.cellElement.style.setProperty('cursor', 'pointer', 'important')
    }
  }

  function onRowInserted(e) {
    GatewayService.addGateway(e.data.name).then(async (response) => {
      await setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: response.status===200 ? 'success' : 'error',
        status: response?.data?.status ? response.data.status : response?.status,
        message: response?.data?.message ? response.data.message : response?.message,
      });
      getData();
    });
  }

  function getTenants() {
    TenantService.getTenants().then((value) => {
      let response=value?.data
      setTenants(response?.data)
    })
  }

  useEffect(() => {
    getData();
    getTenants();
    getSpaces();
    checkRole();
  }, []);

  const onTenantSelect = (e) => {
    setSelectedTenant(e.value);
  }

  const onGatewaySelect = (e) => {
    setSelectedGatewayUpdate(e.value);
  }

  const onHiding = () => {
    setGatewayPopup(!gatewayPopup);
    setSelectedTenant(null);
    setGatewayId(null);
  }

  const updateGatewaySpace = () => {
    if(gatewayId == null) return false;
    GatewayService.updateGatewaySpace(gatewayId, selectedGatewayUpdate).then(async (response) => {
      await setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: response.status===200 ? 'success' : 'error',
        status: response?.data?.status ? response.data.status : response?.status,
        message: response?.data?.message ? response.data.message : response?.message,
      });
      setGatewayUpdatePopup(!gatewayUpdatePopup);
      setSelectedGatewayUpdate(null);
      setGatewayId(null);
      getData(false);
    })
  }

  const onHidingUpdate = () => {
    setGatewayUpdatePopup(!gatewayUpdatePopup);
    setSelectedTenant(null);
    setGatewayId(null);
  }

  function updateTenant(){
    if(gatewayId == null ) return false;
    GatewayService.updateGatewayTenant(selectedTenant, gatewayId).then(async (response) => {
      await setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: response.status===200 ? 'success' : 'error',
        status: response?.data?.status ? response.data.status : response?.status,
        message: response?.data?.message ? response.data.message : response?.message,
      });
      setGatewayPopup(!gatewayPopup);
      setSelectedTenant(null);
      setGatewayId(null);
      getData(false);
    })
  }

  function openPopup(e){
    setGatewayName("Move Gateway: " + e.row.data.name);
    setGatewayId(e.row.data.id);
    setGatewayPopup(true);
  }

  function openUpdatePopup(e){
    setGatewayUpdateName("Assign Space: " + e.row.data.name);
    setGatewayId(e.row.data.id);
    setGatewayUpdatePopup(true);
    setSelectedGatewayUpdate(e.row.data.spaceId)
  }


  return (
    <>
    <div className='headerHeadingPage'>
		  <div className='pageFullWidthEvents'>
        <h5 className='marginZeroEvents'>
          <b>Gateways</b>
        </h5>
      <DataGrid
        showColumnLines={false}
        showRowLines={true}
        onCellPrepared={buttonColor}
        height={'calc(100vh - 102px)'}
        dataSource={data}
        showBorders={false}
        allowColumnResizing={true}
        columnResizingMode={'nextColumn'}
        columnMinWidth={80}
        keyExpr="id"
        onCellClick={showInfo}
        ref={dataGridRef}
        noDataText={loadingText}
        onRowInserted={onRowInserted}
        onColumnsChanging={function (e) {
          if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
            setloadingText('No Data');
          }
        }}
      >
       <FilterPanel visible={true} texts={{createFilter: '', clearFilter:'Clear Filter', filterEnabledHint: ''}}/>
      <StateStoring enabled={true} type="localStorage" storageKey={myTenant + "gateway"}/>
      <Editing
        allowUpdating={true}
        allowAdding={showTenantBtn}
        allowDeleting={false}
        use-icons={true}
        mode="popup"
        texts={{
          addRow: 'Add Gateway'
        }}
      >
        <Texts
          confirmDeleteMessage="Are you sure you want to delete this Gateway?"
        />
        <Popup
          showTitle={true}
          width={500}
          title="Add Gateway"
        />
        <Form colCount={1}>
          <Item dataField="name" />
        </Form>
      </Editing>
      <FilterRow visible={ true } apply-filter={ true } />
      <HeaderFilter visible="showHeaderFilter" />
      <SearchPanel visible={ true } width="240" placeholder="Search..." />
      <Paging defaultPageSize={16} defaultPageIndex={1}/>
        <Column
          dataField={'name'}
          caption={"Gateway Id"}
          width={250}
          allowHeaderFiltering={false}
          allowEditing={true}
          fixed={true}
        />
        <Column
          dataField={'lastUpdate'}
          dataType="datetime"
          format="EEE MMM dd yyyy, hh:mm a" 
          caption={'Last Update'}
          allowFiltering={false}
          allowEditing={false}
          fixed={true}
        />
        <Column
          dataField={'status'}
          calculateDisplayValue={getStatus}
          dataType="string"
          caption={'Status'}
          allowFiltering={false}
          allowEditing={false}
          fixed={true}
        />
        <Column
          dataField={'spaceName'}
          dataType="string"
          caption={'Space'}
          allowFiltering={true}
          allowEditing={false}
          fixed={true}
        />
        <Column type="buttons" caption={''} fixed={true} >
          <Button name="edit" text="Assign Space" onClick={openUpdatePopup} />
        </Column>
        <Column type="buttons" name="custom" caption={''} visible={showTenantBtn}>
          <Button name="add" cssClass="btnMove" icon={Move} onClick={openPopup} visible={showTenantBtn}/>
        </Column>
      </DataGrid>
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
      <UpdateGatewayPopup
        visible={gatewayUpdatePopup}
        onHiding={onHidingUpdate}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        title={gatewayUpdateName}
        container=".dx-viewport"
        width={500}
        height={500}
      >
        <SelectBox
            placeholder="Choose Space"
            focusStateEnabled={false}
            width='450px'
            height={40}
            items={spaceData}
            stylingMode="outlined"
            onValueChanged={onGatewaySelect}
            valueExpr="id"
            displayExpr="name"
            value={selectedGatewayUpdate}
          >
          </SelectBox>
          <div className='movePopup' style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
            <SelectButton className='movePopupSaveBtn dx-button' onClick={updateGatewaySpace} style={{ marginRight:"2%" }}>Save</SelectButton>
            <SelectButton className='movePopupSaveBtn dx-button' onClick={onHidingUpdate}>Cancel</SelectButton>
          </div>
      </UpdateGatewayPopup>
      <GatewaysPopup
        visible={gatewayPopup}
        onHiding={onHiding}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        title={gatewayName}
        container=".dx-viewport"
        width={500}
        height={'40%'}
      >
        <SelectBox
          placeholder="Choose Tenant"
          focusStateEnabled={false}
          width='450px'
          height={40}
          items={tenants}
          stylingMode="outlined"
          onValueChanged={onTenantSelect}
          valueExpr="id"
          displayExpr="name"
          value={selectedTenant}
        >
        </SelectBox>
        <div className={'movePopup'}>
          <SelectButton onClick={updateTenant}  style={{cursor:"pointer", fontWeight: "bold", marginRight: "5px"}} className={'movePopupSaveBtn dx-button'}>Save</SelectButton>
          <SelectButton onClick={onHiding} className={'movePopupSaveBtn dx-button'}>Cancel</SelectButton>
        </div>
      </GatewaysPopup>
      </div>
      </div>
    </>
  )
}