import React, { useState, useEffect, useRef, useCallback, useMemo  } from 'react';
import 'devextreme/data/odata/store';
import {LoadPanel as LoadingPanel} from 'devextreme-react/load-panel';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Editing,
  Texts,
  Button,
  Form,
  HeaderFilter,
  SearchPanel,
  RequiredRule,
  Popup,
  FilterPanel
} from 'devextreme-react/data-grid';
import { Popup as EditTypePopup, Popup as ConfirmDeletePopUp } from 'devextreme-react/popup';
// import Validator, { RequiredRule as ReactRequiredRule } from 'devextreme-react/validator';
import { Form  as Form2, GroupItem, SimpleItem, Label, ButtonItem, PatternRule} from 'devextreme-react/form';
import {Button as SelectButton} from 'devextreme-react/button';
import TenantService from "../../api/tenant.service";
import AssetsService from "../../api/assets.service";
import Toast from 'components/Toast';
import DOMPurify from 'dompurify';
import './assets.css';
import { ScrollView } from 'devextreme-react/scroll-view';
import AssetTypeCreate from './assetType/assetTypeCreate';

export default function Types() {
  const formRef = useRef(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const scrollViewRef = useRef(null);
  const [data, setData] = useState([])
  const [attributeData, setAttributeData] = useState([])
  const [name, setName] = useState(null)
  const [loadingText, setloadingText] = useState('Loading...');
  const tenant = TenantService.getTenant()
  const [showEditTypePopup, setShowEditTypePopup] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [deleteAttribName, setDeleteAttribName] = useState(null)
  const dropDataGridRef = useRef()
  const formData = useMemo(() => ({ name: name, Attributes: attributeData }), [attributeData]);
  const [attributeLength, setAttributeLength] = useState(0)
  const [originalAttributes, setOriginalAttributes] = useState([]);
  const [originalRawAttributes, setOriginalRawAttributes] = useState([]);
  const [editedAttributes, setEditedAttributes] = useState([]);
  const [newlyAddedAttributes, setNewlyAddedAttributes] = useState([]);
  const [isAttribEmpty, setIsAttribEmpty] = useState(false)
  const [loading, setLoading] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 5000,
  });
  const [editingRow, setEditingRow] = useState(null);
  const noSpecialCharsPattern = /^[a-zA-Z0-9\s]*$/;
  function getData() {
    AssetsService.getAssetTenantAttributeTypes().then((value) => {
      let response=value.data
      setData(response.data)
      if (response?.data?.length === 0){
        setloadingText('No Data')
      }
    })
  }

  function handleAddAssetType(e){
    AssetsService.addAssetType(e.type, tenant, e.attributes).then((response) => {
        getData()
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: response?.status===200 ? 'success' : 'error', 
          status: response?.data?.status ? response?.data?.status : response.status,
          message: response?.data?.message ? response.data.message : response.message,
        });
      })
    setShowCreatePopup(false)
  }

//   function onRowInserted(e) {
//     AssetsService.addAssetType(e.data.name, tenant).then((response) => {
//       getData()
//       setToastConfig({
//         ...toastConfig,
//         isVisible: true,
//         type: response?.status===200 ? 'success' : 'error', 
//         status: response?.data?.status ? response?.data?.status : response.status,
//         message: response?.data?.message ? response.data.message : response.message,
//       });
//     })
//   }

  function onRowRemoved(e) {
    AssetsService.deleteAssetType(e.data.id).then((response) => {
      if(response.status === 200){
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: response.status===200 ? 'success' : 'error',
          status: response?.data?.status ? response.data.status : response?.status,
          message: response?.data?.message ? response.data.message : response?.message,
        });
      }else {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: 'error', 
          status: 400,
          message: 'The Asset Type is being used by one or more assets. Please delete those assets first.'
        });
      }  
      getData()
    })
  }

  function hideDeletePopup(){
    setShowDeletePopup(true)
  }

  useEffect(() => {
    getData()
  }, []);

  async function openEditPopUp(e){
    await setEditingRow(e.row.data.id);
    setShowEditTypePopup(true)
    await setName(e.row.data.name)
    // setOriginalName(e.row.data.name)
    if (e.row.data.attributes.length && e.row.data.attributes.length > 0){
      setAttributeData(e.row.data.attributes.map((item)=> item.display))
      setAttributeLength(e.row.data.attributes.length)
      setOriginalAttributes(e.row.data.attributes.map((item)=> item.display))
      setOriginalRawAttributes(e.row.data.attributes)
    } else {
      setAttributeData([])
      setAttributeLength(0)
      setOriginalAttributes([])
      setOriginalRawAttributes([])
    }
    await setEditingRow(e.row.data.id);
  }
  
  const valueChangeName = async (e) => {
    console.log('e', e.value);
    console.log('here 129');
    await setName(e.value)
    // saveInfo(e.value)
  }

  // const checkIsFormValid = () => {
  //   console.log("Function called every 3 seconds while popup is open");
  //   // Add your code here
  //   const formInstance = formRef.current?.instance;
  //   const validationResult = formInstance.validate();
  //   console.log('validation result: ', validationResult);
    
  //   if (!validationResult.isValid) {
  //     console.log('FORM VALID function checkIsFormValid: ', validationResult.isValid);
  //     setIsFormValid(validationResult.isValid)
  //     // Validation failed, show error and stop submission
  //     return;
  //   } else {
  //     setIsFormValid(validationResult.isValid)
  //   }
  // };

  // Effect to run myFunction every 3 seconds when popup is open
  // useEffect(() => {
  //   let intervalId;
  //   if (showEditTypePopup) {
  //     intervalId = setInterval(checkIsFormValid, 3000);
  //   }

  //   // Clean up interval when popup closes or component unmounts
  //   return () => clearInterval(intervalId);
  // }, [showEditTypePopup]);

  const valueChangeAttribute = (e) => {
    const formInstance = formRef.current?.instance;
    const validationResult = formInstance.validate();
    console.log('validation result: ', validationResult);
    
    if (!validationResult.isValid) {
      console.log('FORM VALID 123: ', validationResult.isValid);
      setIsFormValid(validationResult.isValid)
      // Validation failed, show error and stop submission
      return;
    } else {
      setIsFormValid(validationResult.isValid)
    }
    setEditedAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[e.index] = e.data;
      return updatedAttributes;
    });
  };

  async function onHidingEditTypePopup(e){
    // await saveInfo()
    setIsAttribEmpty(false)
    setAttributeData([])
    setOriginalAttributes([])
    setOriginalRawAttributes([])
    setEditedAttributes([])
    setNewlyAddedAttributes([])
    setShowEditTypePopup(false)
    
  }

  function onHidingDeleteAttributePopup(e){
    setDeleteAttribName(null)
    setShowDeletePopup(false)
  }

  async function deleteAssetAttribute(e){
    setLoading(true)
    await AssetsService.deleteAssetAttributeType(editingRow, deleteAttribName).then((response) => {
      if (response.status === 200){
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: response?.status === 200 ? 'success' : 'error', 
          status: response?.data?.status ? response?.data?.status : response.status,
          message: response?.data?.message ? response.data.message : response.message,
        });
        const attributes = response.data && response.data.data[0] && response.data.data[0].attributes
        if (attributes && attributes.length){
          setAttributeData(attributes.map((item)=> item.display))
          setAttributeLength(attributes.length)
          setOriginalAttributes(attributes.map((item)=> item.display))
          setOriginalRawAttributes(attributes)
        } else {
          setAttributeData([])
          setAttributeLength(0)
          setOriginalAttributes([])
          setOriginalRawAttributes([])
        }
        setShowDeletePopup(false)
      } else {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: 'error',
          status: 400,
          message: 'Something went wrong. Please try again later.'
        });
      }
      setLoading(false)
    })  
  }

  function manageContentScroll(){
    if (scrollViewRef.current) {
      const scrollViewInstance = scrollViewRef.current.instance;
      scrollViewInstance.scrollTo({ top: scrollViewInstance.scrollHeight() });
    }
  }

  function handleFocusOut(){
    console.log('204');
    saveInfo()
  }

  function saveInfo(updatedName){
    if (isFormValid) {
    console.log('HERE 208');
    let shouldBreak = false;
    setIsAttribEmpty(false)
    const updatedAttributes = attributeData.map((editedAttribute, index) => {
        const originalAttribute = originalAttributes[index];
        if (index < attributeLength) {
          // Check if the attribute has been edited
          if (editedAttribute !== originalAttribute) {
            return {
              action: 'update',
              index,
              oldValue: originalAttribute,
              newValue: editedAttribute,
            };
          }
        } else {
          // Newly added attribute
          return {
            action: 'add',
            index,
            value: editedAttribute,
          };
        }
  
        return null;
      }).filter(Boolean);
      console.log('Update :', updatedAttributes);
      console.log('Original Attributes :', originalRawAttributes);
      const finalData = updatedAttributes.map((item) => {
        if (item.action === 'update') {
          const matchingElement = originalRawAttributes.find(
            (element) => element.display === item.oldValue
          );
          if (matchingElement) {
            item.name = matchingElement.attributeName;
          }
        }
        return item;
      });
      
      if (finalData.length === 0 && updatedName !== undefined){
        setLoading(true)
        AssetsService.editAssetType(name, editingRow).then((response) => {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: response?.status===200 ? 'success' : 'error', 
            status: response?.data?.status ? response?.data?.status : response.status,
            message: response?.data?.message ? response.data.message : response.message,
          });
          const attributes = response.data && response.data.data[0] && response.data.data[0].attributes
          if (attributes && attributes.length){
            setAttributeData(attributes.map((item)=> item.display))
            setAttributeLength(attributes.length)
            setOriginalAttributes(attributes.map((item)=> item.display))
            setOriginalRawAttributes(attributes)
          } else {
            setAttributeData([])
            setAttributeLength(0)
            setOriginalAttributes([])
            setOriginalRawAttributes([])
          }
          setLoading(false)
        })
      } else if (finalData.length > 0) {
        
        // setOriginalName(name)
        for (const d in finalData){
          if (finalData[d].action === 'update' && finalData[d].newValue === ''){
            setIsAttribEmpty(true)
            shouldBreak = true;
            break
          } else if (finalData[d].action === 'add' && finalData[d].value === ''){
            setIsAttribEmpty(true);
            shouldBreak = true;
            break
          }
        }
        console.log('shouldBreak: ', shouldBreak);
        if (!shouldBreak) {
          setLoading(true)
          AssetsService.editAssetAttributeType(editingRow, name, finalData).then((response) => {
            if (response.status === 200){
              setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: response?.status===200 ? 'success' : 'error', 
                status: response?.data?.status ? response?.data?.status : response.status,
                message: response?.data?.message ? response.data.message : response.message,
              });
              const attributes = response.data && response.data.data[0] && response.data.data[0].attributes
              if (attributes && attributes.length){
                setAttributeData(attributes.map((item)=> item.display))
                setAttributeLength(attributes.length)
                setOriginalAttributes(attributes.map((item)=> item.display))
                setOriginalRawAttributes(attributes)
              } else {
                setAttributeData([])
                setAttributeLength(0)
                setOriginalAttributes([])
                setOriginalRawAttributes([])
              }
              setLoading(false)
            } else {
              setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error', 
                status: 400,
                message: response?.data?.message ? response.data.message : response.message,
              });
              setLoading(false)
            }
          })
          
          // setTimeout(() => {
          //   onHidingEditTypePopup()  
          // }, 5000);
        }
      }
      setTimeout(() => {
        window.location.reload()
      }, 5000);
    }
  }

  const confirmDeleteAttribute = (index, isExistingAttribute) => {
    if (isExistingAttribute) {
      const attribute = attributeData[index];
      setDeleteAttribName(attribute)
      setShowDeletePopup(true)
    }
  };

  const generateNewAttributeOptions = useCallback(
    (index) => {
      const isExistingAttribute = index < attributeLength;
      return{
        buttons: [
          {
            name: 'trash',
            location: 'after',
            options: {
              stylingMode: 'text',
              icon: 'trash',
              visible: true,
              onClick: () => {
                if (isExistingAttribute){
                  confirmDeleteAttribute(index, isExistingAttribute);
                  return true;
                }
                // If the attribute being deleted is from the originalAttributes, update the originalAttributes state
                if (index < attributeLength) {
                  setOriginalAttributes((prevAttributes) =>
                    prevAttributes.slice(0, index).concat(prevAttributes.slice(index + 1))
                  );
                }
                // If the attribute being deleted is from the editedAttributes, update the editedAttributes state
                if (index < editedAttributes.length) {
                  setEditedAttributes((prevAttributes) =>
                    prevAttributes.slice(0, index).concat(prevAttributes.slice(index + 1))
                  );
                }
                // If the attribute being deleted is from the newlyAddedAttributes, update the newlyAddedAttributes state
                if (index >= attributeLength && index < attributeLength + newlyAddedAttributes.length) {
                  setNewlyAddedAttributes((prevAttributes) =>
                    prevAttributes.slice(0, index - attributeLength).concat(prevAttributes.slice(index - attributeLength + 1))
                  );
                }
                // Update the attributeData state
                setAttributeData((prevAttributes) => prevAttributes.slice(0, index).concat(prevAttributes.slice(index + 1)));
              },
            },
          },
        ],
      }
      
    },
    [attributeData, attributeLength, editedAttributes, newlyAddedAttributes],
  );
  

  const AttributeOptions = useMemo(() => {
    const options = [];
    for (let i = 0; i < attributeData.length; i += 1) {
      options.push(generateNewAttributeOptions(i));
    }
    return options;
  }, [attributeData, generateNewAttributeOptions]);
  const AttributeButtonOptions = useMemo(
    () => ({
      icon: 'add',
      text: 'Add Attribute',
      color: '#FFFFFF',
      elementAttr: {
        style: 'background-color: #D71109; color: #FFFFFF', // Add inline styles
        class: 'add-atrib-btn',
      },
      onClick: () => {
        setAttributeData((prevAttributes) => [...prevAttributes, '']);
        manageContentScroll();
      },
    }),
    [],
  );

  const onCustomButtonClick = () => {
    setShowCreatePopup(true)
  };


  return (
    <>
    <div className='headerHeadingPage'>
		  <div className='pageFullWidthEvents'>
        <h5 className='marginZeroEvents'>
          <b>Asset Types</b>
        </h5>
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
      <LoadingPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={loading}
        showIndicator={true}
        showPane={true}
        shading={true}
        closeOnOutsideClick={false}
      />
      <div className='headerHeadingPage'>
            <div className='pageFullWidthEvents'>
                <div className='add_asset_col'>
                    <i className="entity-group-add dx-icon-add download_btn" onClick={onCustomButtonClick} style={{ "fontSize": "24px" }}></i>
                </div>
            </div>
        </div>
      <DataGrid
        // onOptionChanged={optionChange}
        noDataText={loadingText}
        ref={dropDataGridRef}
        showColumnLines={false}
        showRowLines={true}
        // onRowInserted={onRowInserted}
        onRowRemoved={onRowRemoved}
        allowColumnResizing={true}
        height={'calc(100vh - 102px)'}
        onColumnsChanging={function (e) {
          if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
            setloadingText('No Data');
          }
        }}
        dataSource={data}>
        <Paging enabled={false} />
        <Editing
          allowUpdating={false}
          allowDeleting={true}
          allowAdding={false}
          use-icons={true}
          mode="popup"
          texts={{
            addRow: 'Add an Asset Type',
            confirmDeleteMessage: 'Are you sure you want to delete this Asset Type?'
          }}
        >
          <Texts
            confirmDeleteMessage="Are you sure you want to delete this Asset Type?"
          />
          {/* <Popup
            showTitle={true}
            width={500}
            height={600}
            title="Asset Type"
          />
          <Form colCount={1}>
            <Item dataField="name" />
          </Form> */}
        </Editing>
        <FilterRow visible="true" apply-filter="true" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible="true" width="240" placeholder="Search..." />
        <FilterPanel visible={true} texts={{createFilter: '', clearFilter:'Clear Filter', filterEnabledHint: ''}}/>
        <Column
          dataField={DOMPurify.sanitize('name')}
          caption={"Asset Type Name"}
          width={190}
          hidingPriority={8}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={DOMPurify.sanitize('type')}
          caption={''}
          allowSorting={false}
          allowFiltering={false}
        >
        </Column>
        <Column type="buttons">
          <Button name="edit" onClick={openEditPopUp} visible={true} icon={"edit"} />
          <Button name="delete" />
        </Column>
      </DataGrid>
      <EditTypePopup
       visible={showEditTypePopup}
       onHiding={onHidingEditTypePopup}
       dragEnabled={false}
       hideOnOutsideClick={true}
       showCloseButton={true}
       showTitle={true}
       title={`Edit Asset Type`}
       container=".dx-viewport"
       width={500}
       height={700}
       >
        {/* <div className='entityRuleMap-name'>
          <Form2
              // labelLocation={"left"}
              // labelMode={"outside"}
              id={'form1'}
              formData={{
                  "name": name,
              }}
              onFieldDataChanged={(e) => valueChangeName(e)}
          >
            <GroupItem> 
            <SimpleItem dataField="name"
            value={name}
            editorOptions={{
              onValueChanged: (e) => setName(e.value),
              validationMessageMode: "always", // Show validation only when triggered
            }}
            >
              <Label text="Asset Type Name" />
              <RequiredRule message="Asset Type Name is required" />
            </SimpleItem>
            </GroupItem> 
          </Form2>
          </div> */}
          <div>
            <ScrollView height={400} showScrollbar='always' ref={scrollViewRef}>
          <Form2 id="form"
            formData={formData}
            // onFieldDataChanged={(e) => valueChangeAttribute(e)}
            ref={formRef}
            onFieldDataChanged={(e) => {valueChangeAttribute(e)}}
          >
            {/* <GroupItem>
              <SimpleItem dataField="name" 
                value={name}
                editorOptions={{
                  onValueChanged: (e) => setName(e.value),
                  validationMessageMode: "always", // Show validation only when triggered
                }}>
                <Label text="Asset Type Name" />
                <RequiredRule message="Asset Type Name is required" />
              </SimpleItem>
            </GroupItem> */}
            <GroupItem>
              <SimpleItem dataField="name"
                value={name}
                editorOptions={{
                  onValueChanged: (e) => setName(e.value),
                  validationMessageMode: "always", // Show validation only when triggered
                }}>
                <Label text="Asset Type Name" />
                <RequiredRule message="Asset Type Name is required" />
                {/* <PatternRule
                  message={'Do not use special characters like \\,/, ", @, # etc.'}
                  pattern={noSpecialCharsPattern}
                /> */}
              </SimpleItem>
            </GroupItem>
            <GroupItem
            caption="Attributes"
            name="attributes-container"
          >
            <GroupItem name="attributes">
              {AttributeOptions.map((attribute, index) => (
                <SimpleItem
                  key={`Attributes${index}`}
                  dataField={`Attributes[${index}]`}
                  editorOptions={{...attribute}}
                >
                  <Label text={`Attribute ${index + 1}`} />
                  <PatternRule
                  message={'Do not use special characters like \\,/, ", @, # etc.'}
                  pattern={noSpecialCharsPattern}
                />
                </SimpleItem>
              ))}
            </GroupItem>

            <ButtonItem
              horizontalAlignment="left"
              // cssClass="add-attrib-button"
              buttonOptions={AttributeButtonOptions}
            ></ButtonItem>
          </GroupItem>
          </Form2>
          </ScrollView>
          </div>
          <div style={{display: isAttribEmpty ? 'block' : 'none' }} className='editAttribute-setNote'>Error: Attribute value cannot be empty.</div>
          <div className='movePopup' style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
            {/* <SelectButton className='movePopupSaveBtn' onClick={saveInfo} style={{ marginRight:"5%"}}>Save</SelectButton> */}
            <SelectButton className='movePopupSaveBtn dx-button' style={{ marginRight:"2%", fontWeight: "bold"}} onClick={saveInfo} >Save</SelectButton>
            <SelectButton className='movePopupSaveBtn dx-button' onClick={onHidingEditTypePopup} style={{ fontWeight: "bold"}} >Cancel</SelectButton>
          </div>
      </EditTypePopup>
      <ConfirmDeletePopUp
        visible={showDeletePopup}
        // onHiding={hideDeletePopup}
        title={`Delete Asset Attribute - ${deleteAttribName}`}
        width={500}
        height={300}
      >
        Are you sure you want to delete the attribute <b>{deleteAttribName}</b>?
        <br/>
        <br/>
        Note: This action cannot be undone.
        <div className='movePopup'>
            <SelectButton className='movePopupSaveBtn' onClick={deleteAssetAttribute} style={{ marginRight:"5%"}}>Yes</SelectButton>
            <SelectButton className='movePopupSaveBtn' onClick={onHidingDeleteAttributePopup} >No</SelectButton>
        </div>
      </ConfirmDeletePopUp>
      {showCreatePopup && <AssetTypeCreate showPopUp={showCreatePopup} setShowPopUp={setShowCreatePopup} sendDataToParent={handleAddAssetType} />}
    </div>
    </div>
    </>
  );
}