import React, { useState, useEffect } from 'react';
import 'devextreme/data/odata/store';
import UserService from "../../api/user.service";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Editing,
  Texts,
  Button,
  Form,
  HeaderFilter,
  SearchPanel,
  RequiredRule,
  Popup,
  EmailRule,
  FilterPanel
} from 'devextreme-react/data-grid';
import { Item, PatternRule } from 'devextreme-react/form';
import TenantService from "../../api/tenant.service";
import { useRef } from 'react';
import Toast from 'components/Toast';

export default function UserPage() {
  const [data, setData] = useState([])
  const [loadingText, setloadingText] = useState('Loading...');
  const [editFlag, setEditFlag] = useState(false)
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 5000
  });
  const tenant = TenantService.getTenant()
  const dropDataGridRef = useRef();
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    getData()
  }, []);

  //To get tenant user list
  function getData() {
    UserService.getListTenantUsers(tenant).then((value) => {
      let response = value.data
      setData(response.data)
    })
  }

  //While adding new tenant user
  function onRowInserted(e) {
    let roleId = types.find((type) => type.name === e.data.role.name);
    UserService.createTenantUser(e.data.email, e.data.firstName, e.data.lastName, roleId.id, e.data.password, e.data.mobileNumber).then((response) => {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: 'success',
        status: 200,
        message: response.data.message,
      });
      getData()
    })
    .catch((er) =>{
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        status: 500,
        type: 'error',
        message: 'Something went wrong. Please try again later.',
      });
    })
  }

  //while deleting the tenant user
  function onRowRemoved(e) {
    UserService.removeTenantUser(e.data.id).then((tenantUser) => {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: tenantUser.status===200 ? 'success' : 'error', 
        status: tenantUser?.data?.status ? tenantUser?.data?.status : tenantUser.status,
        message: tenantUser?.data?.message ? tenantUser.data.message : tenantUser.message,
      });
      getData()
    }).catch((er) =>{
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        status: 500,
        type: 'error',
        message: 'Something went wrong. Please try again later.',
      });
      getData()
    })
  }

  //While editing the existing tenant user
  function onRowUpdating(e) {
    let roleId
    const id = e.oldData.id
    if (e.newData && e.newData.role && e.newData.role.name){
      roleId = types.find((type) => type.name === e.newData.role.name);
    }
    let role = e.newData.role !== undefined ? roleId.id : e.oldData.role.id
    let firstName = e.newData.firstName !== undefined ? e.newData.firstName : e.oldData.firstName
    let lastName = e.newData.lastName !== undefined ? e.newData.lastName : e.oldData.lastName
    let email = e.newData.email !== undefined ? e.newData.email : e.oldData.email
    let mobileNumber = e.newData.mobileNumber !== undefined ? e.newData.mobileNumber : e.oldData.mobileNumber
    UserService.editTenantUser(email, firstName, lastName, id, role, mobileNumber).then((response) => {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: 'success',
        status: 200,
        message: response.data.message,
      });
      getData()
    })
  }

  //To customize the cell params
  function buttonColor(e) {
    if ((e.column.dataField === 'email') && e.rowType !== "header") {
      e.cellElement.style.color = "#337ab7";
      e.cellElement.style.setProperty('cursor', 'pointer', 'important')
      e.cellElement.style.setProperty('text-decoration', 'underline')
    }
  }

  //It is triggered onclick of row level edit button
  function onEditClick(e) {
    setEditFlag(true)
  }

  //It is triggered onclick of cancel button in popup while adding and editing
  function onEditCancel(e) {
    setEditFlag(false)
  }

  //It is triggered onclick of save button in popup while adding and editing
  function onSaved(e) {
    setEditFlag(false)
  }

  
  let types = [{ id: 1, name: "Admin" }, { id: 2, name: "Viewer" }]
  const UserEditorOptions = {
    items: types,
    searchEnabled: true,
    // defaultValue: userRoleId,
    displayExpr: "name",
    valueExpr: "name",
    placeholder: "Select Role"
  };

  // const clearFilters = () => {
  //   const dataGridInstance = dropDataGridRef.current.instance;
  //   dataGridInstance.clearFilter();
  //   setIsDisabled(true)
  // }

  // const optionChange = (e) => {
  //   if (e.fullName.indexOf("filterValue") !== -1 || e.fullName.indexOf("searchPanel") !== -1) {
  //     if (e.value === null){
  //       const dataGridInstance = dropDataGridRef.current.instance;
  //       dataGridInstance.clearFilter();
  //       setIsDisabled(true)
  //     } else {
  //       setIsDisabled(false)
  //     }
  //   }
  // }

  return (
    <>
    <div className='headerHeadingPage'>
		  <div className='pageFullWidthEvents'>
        <h5 className='marginZeroEvents'>
          <b>User Config</b>
        </h5>
      <DataGrid
        // onOptionChanged={optionChange}
        ref={dropDataGridRef}
        showColumnLines={false}
        showRowLines={true}
        onEditingStart={onEditClick}
        onRowInserted={onRowInserted}
        onRowRemoved={onRowRemoved}
        onSaved={onSaved}
        onRowUpdating={onRowUpdating}
        // onCellPrepared={buttonColor}
        allowColumnResizing={true}
        onEditCanceling={onEditCancel}
        height={'calc(100vh - 102px)'}
        noDataText={loadingText}
        onColumnsChanging={function (e) {
          if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
            setloadingText('No Data');
          }
        }}
        dataSource={data}>
        <Paging enabled={false} />
        <Editing
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          use-icons={true}
          mode="popup"
          texts={{
            addRow: 'Add a User',
          }}
        >
          <Texts
            //confirmDeleteMessage={`Are you sure you want to delete this ${email}?`}
            confirmDeleteMessage="Are you sure you want to delete this user"
          />
          <Popup
            showTitle={true}
            width={500}
            height={800}
            title={editFlag ? "Edit User" : "Add User"}
            dicks={false}
          />
          <Form colCount={1}>
            <Item dataField="firstName" />
            <Item dataField="lastName" />
            <Item dataField="email" />
            <Item
              dataField="mobileNumber"
              helpText="Please provide a phone number that begins with a plus sign and includes the corresponding country code."
            />
            <Item dataField="role.name"
              editorType="dxSelectBox"
              editorOptions={UserEditorOptions}>
            </Item>
            <Item dataField="password" visible={editFlag ? false : true} />
          </Form>
        </Editing>
        <FilterRow visible="true" apply-filter="true" />
        <HeaderFilter visible="showHeaderFilter" />
        <SearchPanel visible="true" width="240" placeholder="Search..." />
        <FilterPanel visible={true} texts={{createFilter: '', clearFilter:'Clear Filter', filterEnabledHint: ''}}/>
        <Column
          dataField={'firstName'}
          caption={"First Name"}
          width={100}
          hidingPriority={8}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={'lastName'}
          caption={"Last Name"}
          width={200}
          hidingPriority={8}
        ></Column>
        <Column
          dataField={`email`}
          caption={"Email"}
          width={300}
          hidingPriority={8}
        >
          <RequiredRule />
          <EmailRule />
        </Column>
        <Column
          dataField={`mobileNumber`}
          caption={"Mobile Number"}
          width={300}
          hidingPriority={8}
        >
          <PatternRule
            pattern={/^\+(?:[0-9] ?){6,14}[0-9]$/}
            message={'Your phone must have "+11234567890" format!'}
          />
        </Column>
        <Column
          dataField={'role.name'}
          caption={"Role"}
          width={80}
          hidingPriority={8}
          dataType="string"
        >
          <RequiredRule />
        </Column>
        <Column dataField='password'
          visible={false} >
          <RequiredRule />
          <PatternRule
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
            message="Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character"
          />
        </Column>
        <Column
          dataField={'type'}
          caption={''}
          allowSorting={false}
          allowFiltering={false}
        >
        </Column>

        <Column type="buttons" width={110} >
          <Button name="edit" />
          <Button name="delete" />
        </Column>
      </DataGrid>
      {/* <div className='uploadBtnAssetsReport clrConfig'>
        <ReactButton
          disabled={isDisabled}
          width={"160px"}
          height={"90%"}
          style={{marginTop: "5px"}}
          type="normal"
          stylingMode="outlined"
          onClick={clearFilters}
          text="Clear Filters"
        />
      </div> */}
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
    </div>
    </div>
    </>
  );
}
