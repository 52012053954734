import React, { useState, useRef, useEffect } from 'react';
// import { MarkerMuster } from "react-leaflet-muster";
// import { MapContainer, TileLayer, Marker, ImageOverlay } from "react-leaflet";
// import MarkerClusterGroup from "react-leaflet-markercluster";
import FloorplanPopup from "./components/floorplan";
import { useHistory } from "react-router";
import "./testFloorplan.css"
import moment from 'moment-timezone';
import {LoadPanel as LoadingPanel} from 'devextreme-react/load-panel';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';


export default function App(props) {
    const history = useHistory();
    const downloadTargetRef = useRef();
    const [loading, setLoading] = useState(false);
    const [showReportBtn, setShowReportBtn] = useState(false);
    function closePopup(){
        if(props.location.state.toggleEditBool){
            history.push({
                pathname:  `/config/space`,
             })
        } else {
            history.push({
                pathname:  `/spaces`,
             })
        }
    }

    useEffect(() => {
        const checkAndSetValue = () => {
            if(props.location && props.location.state && props.location.state.title && (props.location.state.title.includes('Anchors -') || props.location.state.title.includes('Assets -'))){
                console.log('ABC 1', props.location.state.title)
                setShowReportBtn(true)
            }
        };
        // Call the function inside useEffect to ensure it runs after the initial render
        checkAndSetValue();
      }, []); 

    

    const captureComponent = () => {
        setLoading(true)
        const elementToCapture = document.getElementById('full-page-content-floorplan');
    
        domtoimage.toPng(elementToCapture)
          .then((dataUrl) => {
            // Handle the captured image dataUrl as needed
            console.log(dataUrl);
            const pdf = new jsPDF();

            // Add the captured image as a page in the PDF
            pdf.addImage(dataUrl, 'PNG', 10, 10, 190, 120); // Adjust the position and size as needed

            // Save the PDF with a specified filename
            pdf.save(`${props.location.state.title}-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`);
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.error('Error capturing element:', error);
          });
      };


    if(props.location !== undefined){
        if(props.location.state !== undefined){
            const myMarkers = props.location.state.myMarkers
            const spaceId = props.location.state.spaceId
            const type = props.location.state.type
            const windowType = props.location.state.windowType
            const zoneEditControl = props.location.state.zoneEditControl
            return (
                <div className="spaceFloorplan" id="full-page-content-floorplan" ref={downloadTargetRef}>
                    <div className="spaceFloorplan-bounds">
                        <div className="spaceFloorplan-title"> 
                            {props.location.state.title}
                            <div className='uploadBtnAssetsReport upload3' style={{display: showReportBtn ? 'block' : 'none', right: "75px", top: "50px" }}>
                              <button id="generatePdfButton" alt="Download Report" title="Download Report" type="button" onClick={() => captureComponent()}>
                                <img src="../icons/reports.svg" alt="Download Report" />
                              </button>
                            </div>
                            <div className="spaceFloorplan-close">
                                <i className="dx-icon-close" onClick={closePopup}></i>
                            </div>
                        </div>
                        <div className="spaceFloorplan-leaflet" >
                            <FloorplanPopup className="spaceFloorplan-bounds"  spaceId={spaceId} myMarkers={myMarkers} type={type} windowType={windowType} 
                            moveMarkers={props.location.state.moveMarkers} zoneEditControl={zoneEditControl}>
                            </FloorplanPopup>
                        </div>
                    </div>
                    <LoadingPanel
                      shadingColor="rgba(0,0,0,0.4)"
                      visible={loading}
                      showIndicator={true}
                      showPane={true}
                      shading={true}
                      closeOnOutsideClick={false}
                    />
                </div>
                
            )
        } else {
            history.push({
                pathname:  `/spaces`,
             })
        }
    } return (
        <></>
    )
    

//     return(
//     // <FloorplanPopup></FloorplanPopup>



//     //     <MapContainer
//     //     onClick={(e) => {console.log("onClusterClick", e)}}

//     //         zoom={14}
//     //         center={[25.0, 25.0]}
//     //         maxZoom={18}
//     //   style={{height: '500px', width: '1000px'}} 
//     //     >
//     //         <TileLayer
//     //             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//     //         />
//     //         <MarkerMuster
//     //         onClick={(e) => {console.log("onClusterClick", e)}}
//     //         >
//     //             <Marker position={[-21.210309, -47.647063]}/>
//     //             <Marker position={[-21.210309, -47.647063]}/>
//     //         </MarkerMuster>
//     //     </MapContainer>

// <>
// <MapContainer
//         onClick={(e) => {console.log("onClusterClick", e)}}

//         style={{height: '500px', width: '1000px'}} 
//         zoom={5}  
//         min-zoom={4.2} 
//         maxZoom={6}
//             center={[25.0, 25.0]}

//         >
//             <ImageOverlay
//                         url={"http://rpo.redlore.com/api/places/floorplan/a32b2298-bff7-44f8-a823-fa6b7fe1b8f7"}
//                         bounds={[[0,0], [20,20]]}
//                         style={{height: '100px', width: '100px'}}
//                         opacity={1}
//                         zIndex={10}
//                     />
//             <MarkerClusterGroup
//             onClick={(e) => {console.log("onClusterClick", e)}}
//             onMouseOver={(e) => {console.log("onClusterMouse", e)}}
//             >
//                 <Marker position={[5, 2]}/>
//                 <Marker position={[5, 1]}/>
//                 <Marker position={[4, 7]} />
//             </MarkerClusterGroup>
//         </MapContainer>


//         </> 

        
//     )
}
