import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Editing,
    Column,
    Paging,
    SearchPanel,
    RequiredRule,
} from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import EntityService from "../../api/entity.service";
import Toast from 'components/Toast';
import * as DOMPurify from 'dompurify';
import { Popup as Popup2 } from 'devextreme-react/popup';
import { Popup } from 'devextreme-react/popup';
import './tenantRules.css'
import { useHistory } from "react-router-dom";

export default function App(props) {
    const history = useHistory();
    const tenantId = props.match.params.tenantId
    const [data, setData] = useState([])
    const dropDataGridRef = useRef();
    const [loadingText, setloadingText] = useState('Loading...');
    const [popupVisible2, setPopupVisible2] = useState(false)
    const [popupVisible, setPopupVisible] = useState(false)
    const [ruleLabel, setRuleLabel] = useState()
    const [ruleType, setRuleType] = useState(null)
    const [ruleId, setRuleId] = useState(null)
    const [pageName, setPageName] = useState("")
    const [deleteMsg, setDeleteMsg] = useState("")
    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
        displayTime: 5000
    });

    function getData() {
        EntityService.getTenantEntityRulesAvailable(tenantId).then((value) => {
            let result
            if (value?.data?.data?.rules?.length > 0){
                setData(value.data.data.rules)
                result = value?.data?.data?.allRules.filter(o1 => !value?.data?.data?.rules.some(o2 => o1.id === o2.id));
                setRuleLabel(result)
            } else {
                setloadingText("No Data")
                setRuleLabel(value?.data?.data?.allRules)
            }
            setPageName(value?.data?.data?.tenantName)
        })
    }

    useEffect(() => {
        getData()
    }, []);

    function addOnClick() {
        setPopupVisible2(true)
    }

    function onHiding() {
        setRuleType(null)
        setPopupVisible(false)
        setPopupVisible2(false)
    }

    function onRuleChanged(e) {
        setRuleType(e.value)
    }

    function onRowRemoved(e) {
        EntityService.deleteTenantRule(tenantId, e.data.id).then((res) => {
            if(res.data.message === "Success"){
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: res.status===200 ? 'success' : 'error',
                    status: res?.data?.status ? res.data.status : res?.status,
                    message: res?.data?.message ? res.data.message : res?.message,
                  });
                  getData()
            } else {
                if (res.data.message){
                    setDeleteMsg(res.data.message)
                    setRuleId(e.data.id)
                }
                setPopupVisible(true)
            }
        })
        getData()
      }

    function addRuleToTenant(e) {
        EntityService.addRuleToTenant(tenantId, ruleType).then(async (value) => {
            setPopupVisible2(false)
            setPopupVisible(false)
            setRuleType(null)
            getData()
            await setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: value.status===200 ? 'success' : 'error',
                status: value?.data?.status ? value.data.status : value?.status,
                message: value?.data?.message ? value.data.message : value?.message,
            });
        })
    }

    function deleteRuleFromTenant(e){
        EntityService.deleteTenantRule(tenantId, ruleId, true).then((res) => {
            setPopupVisible(false)
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: res.status===200 ? 'success' : 'error',
                status: res?.data?.status ? res.data.status : res?.status,
                message: res?.data?.message ? res.data.message : res?.message,
              });
            getData()
            setTimeout(() => {
                getData()
            }, 4000);
        })
    }

    function closeTenant(){
        history.push("/config/tenants");
    }

    function backClickEntities(){
        setPopupVisible2(false)
      }

    return (
        <>
        <Popup2
                visible={popupVisible2}
                onHiding={onHiding}
                dragEnabled={false}
                width={600}
                height={400}
                showCloseButton={true}
                showTitle={true}
                title="Add Rule"
                container=".dx-viewport"
            >
                <div className='entityRuleMap-select'>
                    <span style={{fontSize:"12px", color:"rgba(117,117,117,.87)", paddingRight:"21%", paddingTop:"15px"}}>Rule&nbsp;Name</span>
                    <SelectBox
                        items={ruleLabel}
                        value={ruleType}
                        onValueChanged={onRuleChanged}
                        placeholder="Select a Rule"
                        valueExpr="id"
                        displayExpr="name"
                    />
                </div>
                <div className="entityGroup-setEntities" onClick={addRuleToTenant} style={{ marginRight:"2%" }}>
                    ADD
                </div>
                <div className="entityGroup-setEntitiesCancel" onClick={backClickEntities}>
                    CANCEL
                </div>
        </Popup2>

        <Popup
            visible={popupVisible}
            onHiding={onHiding}
            dragEnabled={false}
            width={500}
            height={200}
            showTitle={false}
            container=".dx-viewport"
        >
            <p>
            {deleteMsg}
            </p>
                
        <div className="addRule" onClick={onHiding}>
            KEEP
        </div>
        <div className="delRule" onClick={deleteRuleFromTenant}>
            DELETE
        </div>
        </Popup>
        <div className='headerHeadingPage'>
            <div className='pageFullWidthEvents'>
            <div className='editentities_col'>
            <div style={{ fontSize: "large", display: "inline-block" }}>Available rules for: {pageName}</div>
            <i className="entity-group-add dx-icon-close" onClick={closeTenant}></i>
            <i onClick={addOnClick} className="entity-group-add dx-icon-add" style={{ "fontSize": "24px" }}></i>
        </div>
        <DataGrid
            id="tenant-rule-grid"
            ref={dropDataGridRef}
            showColumnLines={false}
            showRowLines={true}
            height={'calc(100vh - 51px)'}
            noDataText={loadingText}
            onRowRemoved={onRowRemoved}
            allowColumnResizing={true}
            //onRowUpdating={onRowUpdating}
            onColumnsChanging={function (e) {
            if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
              setloadingText('No Data');
            }
        }}
        dataSource={data}>
        <Paging enabled={false} />
        <Editing
          allowUpdating={false}
          allowDeleting={true}
          allowAdding={false}
          use-icons={true}
          mode="popup"
        ></Editing>
        <SearchPanel visible="true" width="240" placeholder="Search..." />
        <Column
          dataField={DOMPurify.sanitize('name')}
          caption={"Rule Name"}
          hidingPriority={8}
        >
          <RequiredRule />
        </Column>
      </DataGrid>
            </div>
        </div>
        
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
        </>
    );
}
