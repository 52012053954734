import { withNavigationWatcher } from './contexts/navigation';
import { TasksPage, ProfilePage, NotificationPage, AssetsPage, AssetsAnalyzePage, AssetPage, DevicesPage, SpacesPage, EventsPage, ValmetPage, TypesPage, SpaceTypesPage, TestPage, GatewayPage, UploadPage, EntityGroupPage, EntityGroupMapPage,EntityRuleMapPage, UserPage, TenantPage, EntityRulesAssignPage, OverviewPage, NetworkToolPage, GatewaysPage} from './pages';

const routes = [
  {
    path: '/tasks',
    component: TasksPage
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/notification',
    component: NotificationPage
  },
  {
    path: '/floorplan',
    component: TestPage
  },
  {
    path: '/assets',
    component: AssetsPage
  },
  {
    path: '/assets/analyze',
    component: AssetsAnalyzePage
  },
  {
    path: '/valmet',
    component: ValmetPage
  },
  {
    path: '/asset/:assetId',
    component: AssetPage
  },
  {
    path: '/config/types',
    component: TypesPage
  },
  {
    path: '/config/spaceTypes',
    component: SpaceTypesPage
  },
  {
    path: '/devices',
    component: DevicesPage
  },
  {
    path: '/config/networkTool',
    component: NetworkToolPage
  },
  {
    path: '/spaces',
    component: SpacesPage
  },
  {
    path: '/events',
    component: EventsPage
  },
  {
    path: '/config/space',
    component: SpacesPage
  },
  {
    path: '/config/device',
    component: DevicesPage
  },
  {
    path: '/config/gateway',
    component: GatewaysPage
  },
  {
    path: '/gateway/:gatewayId',
    component: GatewayPage
  },
  {
    path: '/upload',
    component: UploadPage
  },
  {
    path: '/config/behaviors',
    component: EntityGroupPage
  },
  {
    path: '/config/tenants',
    component: TenantPage
  },
  {
    path: '/behaviors/:entityGroupId',
    component: EntityGroupMapPage
  },
  {
    path: '/behaviors/rule/:entityGroupId',
    component: EntityRuleMapPage
  },
  {
    path: '/config/user',
    component: UserPage
  },
  {
    path: '/behaviors/rule/available/:tenantId',
    component: EntityRulesAssignPage
  },
  {
    path: '/overview',
    component: OverviewPage
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
