import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updateGeoCoordinates(spaceId, geoCoordinates) {
    if (spaceId === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space/edit/geocoordinates",
      {
        spaceId: spaceId,
        geoCoordinates: geoCoordinates
      }
    );
  },

  getSpaceTypes(tenantId) {
    if (tenantId === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space/type/tenant",
      {
        tenantId: tenantId,
      }
    );
  },

  addSpaceType(name) {
    if (name === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space/type",
      {
        name: name,
      }
    );
  },

  editSpaceType(id, name) {
    if (name === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space/type/edit",
      {
        id: id,
        name: name,
      }
    );
  },

  deleteSpaceType(id) {
    if (id === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space/type/delete",
      {
        id: id,
      }
    );
  },
  getSpaceFloorplan(spaceId) {
    if (spaceId === undefined) {
      return ""
    }
    return Axios.getImage(
      "/api/space/floorplan",
      {
        spaceId:spaceId
      }
      ); 
  },

  getSpace(spaceId) {
    if (spaceId === undefined) {
      return ""
    }
   return Axios.post(
    "/api/space/data",
    {
      spaceId:spaceId
    }
    ); 
  },

  getSpaces(tenantId) {
    if (tenantId === undefined) {
      return ""
    }
     return Axios.post(
      "/api/space/tenant",
      {
        tenantId: tenantId,
      }
    ); 

  },

  getModels(tenantId) {
    if (tenantId === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space/model",
      {
        tenantId: tenantId,
      }
    ); 
  },

  getAnchorsInSpace(spaceID) {
    if (spaceID === undefined) {
      return ""
    }
    return Axios.get(`/api/device/anchors/${spaceID}`)
  },
  getGatewaysInSpace(spaceID) {
    if (spaceID === undefined) {
      return ""
    }
    return Axios.get(`/api/device/gateways/${spaceID}`)
  },

  editDevice(id, spaceCoordinates, serial) {
    if (id === undefined) {
      return ""
    }
    return Axios.post(
      "/api/device/edit",
      {
        id: id,
        spaceCoordinates: spaceCoordinates,
        serial: serial
      }
    );
  },
  editGateway(id, spaceCoordinates, spaceId) {
    if (id === undefined) {
      return ""
    }
    return Axios.post(
      "/api/gateway/edit",
      {
        gatewayId: id,
        spaceCoordinates: spaceCoordinates,
        spaceId: spaceId
      }
    );
  },

  editSpace(id, name, headId, spaceTenantTypeId, spaceTypeId) {
    if (id === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space/edit",
      {
        id: id,
        name: name,
        headId: headId,
        spaceTenantTypeId: spaceTenantTypeId,
        spaceTypeId: spaceTypeId
      }
    );
  },

  addSpace(name, headId, spaceTenantTypeId, spaceTypeId) {
    if (name === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space",
      {
        name: name,
        headId: headId,
        spaceTenantTypeId: spaceTenantTypeId,
        spaceTypeId: spaceTypeId
      }
    );
  },

   deleteSpace(spaceId) {
    return Axios.del(
      "/api/space",
      {
        spaceId:spaceId
      }
    );
  },  

   deleteFloorplan(spaceId) {
    return Axios.del(
      "/api/space/floorplan",
      {
        spaceId:spaceId
      });
  }, 

  uploadFloorplan(image, xMax, yMax, spaceId) {
    if (spaceId === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space",
      {
        image: image,
        xMax: xMax,
        yMax: yMax,
        spaceId: spaceId
      }
    );
  },

  editSpaceZone(id, spaceCoordinates) {
    if (id === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space/zone/edit",
      {
        id: id,
        spaceCoordinates: spaceCoordinates,
      }
    );
  },

  deleteSpaceZone(id) {
    if (id === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space/zone/delete",
      {
        id: id,
      }
    );
  },

  getChildZones(spaceId) {
    if (spaceId === undefined) {
      return ""
    }
    return Axios.post(
      "/api/space/zones",
      {
        spaceId: spaceId,
      }
    ); 
  },
};