
import React, { useState } from 'react';
import {
    Chart,
    CommonSeriesSettings,
    Legend,
    ValueAxis,
    Title,
    Grid,
    Series,
    ConstantLine,
    Label,
    Font,
    ArgumentAxis,
    ZoomAndPan,
    ScrollBar,
    Connector,
    Crosshair
} from 'devextreme-react/chart';
import moment from 'moment-timezone';

const belowAverageColor = '#FEC002';
const aboveAverageColor = '#B60704';

function MergedChart({eventData, thresholdValue}) {
    // function formatXAxis(arg) {
    //     console.log('ARG: ', arg);
    //     return moment(arg.value).format('YYYY-MM-DD HH:mm');
    // }

    // function filterLegendItems(items) {
    //     for (var item of items) {
    //       if (item.id != 6) {
    //         item.visible = false;
    //       }
    //     }
    //     console.log(items);
    //     return items;
    //   }

    const getSeriesColor = (seriesName) => {
        // Customize this logic based on your requirements
        return seriesName === 'Shocks mG' && thresholdValue > 1000 ? '#FF0000' : '#0000FF';
    };

    function customizeText(pointInfo) {
        if (pointInfo.point.data.showLabel) {
          if (!pointInfo.point.data.bottom && pointInfo.value === 6000) {
            return `${pointInfo.seriesName}`;
          } else if (pointInfo.point.data.bottom && pointInfo.value === 5000) {
            return `${pointInfo.seriesName}`;
          } else {
            return "";
          }
        } else {
          return "";
        }
    }

    const customizePoint = (pointInfo) => {
        console.log('POINT INFO: ', pointInfo);
        if (pointInfo.seriesName === "Shocks mG"){
            if (thresholdValue < pointInfo.value) {
                return { color: aboveAverageColor }; // Color for points above the line
            } else {
                return { color: belowAverageColor }; // Color for points below the line
            }
        }
	};

    function customizeLabel(arg) {
        if (arg.seriesName === "Shocks mG") {
            // Only show labels for certain conditions, otherwise hide them
            if (thresholdValue < arg.value) {
              return getLabelsSettings(aboveAverageColor);
            } else if (thresholdValue >= arg.value) {
              return getLabelsSettings(belowAverageColor);
            } else {
              return { visible: false }; // Hides labels for other cases
            }
          } else {
            return { visible: true }; // Hide labels for all other series
          }
		
	}

    function customizeLabelText(arg) {
        console.log('customizeLabelText ARG: ', arg);
		return arg.seriesName === "Shocks mG" ? '' : "";
	}

    const getLabelsSettings = (backgroundColor) => {
		return {
			visible: false,
			backgroundColor,
			// customizeLabelText
		};
	}

    eventData.sort((a, b) => new Date(a.date) - new Date(b.date));

return (
	<div style={{marginBottom: '50px', width: '100%', marginLeft: '20px', marginRight: '20px', marginTop: "50px"}}>
		<Chart id="mechanicalChart" 
        dataSource={eventData} 
        customizePoint={customizePoint}
        animation={{"enabled":false}}
        customizeLabel={customizeLabel}
        >
      <CommonSeriesSettings argumentField="date" />
      <Series
        type="rangearea"
        rangeValue1Field="minInWHSeats"
        rangeValue2Field="maxInWHSeats"
        name="Incoming WH at Seats"
        showInLegend={false}
      ><Label visible={true} customizeText={customizeText} position="outside">
      <Connector visible={true} />
    </Label></Series>
      <Series
        type="rangearea"
        rangeValue1Field="minOutWHSeats"
        rangeValue2Field="maxOutWHSeats"
        name="Outgoing WH at Seats"
        showInLegend={false}
        ><Label visible={true} customizeText={customizeText} position="outside">
        <Connector visible={true} />
      </Label></Series>
      <Series
        type="rangearea"
        rangeValue1Field="minInFastCars"
        rangeValue2Field="maxInFastCars"
        name="Incoming WH at FastCars"
        showInLegend={false} 
      ><Label visible={true} customizeText={customizeText} position="outside">
      <Connector visible={true} />
    </Label></Series>
      <Series
        type="rangearea"
        rangeValue1Field="minOutFastCars"
        rangeValue2Field="maxOutFastCars"
        name="Outgoing WH at FastCars"
        showInLegend={false} 
        ><Label visible={true} customizeText={customizeText} position="outside">
        <Connector visible={true} />
      </Label></Series>
      <Series
        type="rangearea"
        rangeValue1Field="minTransFastCars"
        rangeValue2Field="maxTransFastCars"
        name="Transit to Fast Cars"
        showInLegend={false} 
        ><Label visible={true} customizeText={customizeText} position="outside">
        <Connector visible={true} />
      </Label></Series>
      <Series
        type="rangearea"
        rangeValue1Field="minTransSeats"
        rangeValue2Field="maxTransSeats"
        name="Transit to Seats"
        showInLegend={false} 
        ><Label visible={true} customizeText={customizeText} position="outside">
        <Connector visible={true} />
      </Label></Series>
      <Series 
    //   color={customizeLabel}
        valueField="level" name="Shocks mG" showInLegend={false} type="bar">
            {/* <Label visible={false}></Label> */}
       </Series>
       
      <ValueAxis title="Shock Level [mG]" visualRange={{ startValue: 0, endValue: 7000 }}>
        <Grid visible={true} />
        <Label
          format={{
            type: 'fixedPoint',
            precision: 0
          }}
          customizeText={(e) => `${e.value}`}
        />
    <ConstantLine
        value={thresholdValue || 2000}
        width={0.5}
        color="#B60704"
        dashStyle="solid"
    >
        <Label text="" />
    </ConstantLine>
      </ValueAxis>
      <ArgumentAxis label={{
			customizeText: (arg) => {
				return `<p style="font-size: 10px;">${moment(arg.value).format('yyyy-MM-DD HH:mm')}</p>`
		    }
			}}
		/>
        <Crosshair
        enabled={true}
        color="#949494"
        width={3}
        dashStyle="dot"
      >
        <Label
          visible={true}
          backgroundColor="#949494"
        >
          <Font
            color="#fff"
            size={12}
          />
        </Label>
      </Crosshair>
      <Legend verticalAlignment="bottom" horizontalAlignment="center"
      />
      <ScrollBar visible={true} />
      <ZoomAndPan argumentAxis="both" dragToZoom={true} valueAxis="none"/>
    </Chart>
	</div>
  );
}

export default MergedChart;
