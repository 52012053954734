
import React from 'react';
import {
  Chart,
  Size,
  Series,
  StripStyle,
  Legend,
  ValueAxis,
  ArgumentAxis,
  ConstantLine,
  Label,
  Font,
  Title,
  Margin,
  Grid,
  CommonSeriesSettings,
  Tooltip,Export, Subtitle
} from 'devextreme-react/chart';
import moment from 'moment-timezone';

const belowAverageColor = '#FEC002';
const aboveAverageColor = '#B60704';

function MechanicalShock({heatmapData, aggregationInterval, thresholdValue, valueAxisSetting}) {
	const customizePoint = (pointInfo) => {
		if (thresholdValue < pointInfo.value) {
			return { color: aboveAverageColor }; // Color for points above the line
		} else {
			return { color: belowAverageColor }; // Color for points below the line
		}
	};

	const getLabelsSettings = (backgroundColor) => {
		return {
			visible: true,
			backgroundColor,
			customizeText
		};
	}

	function customizeText(arg) {
		return arg.seriesName === "Series 1" ? '' : `${arg.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
	}

	function customizeLabel(arg) {
		if (thresholdValue < arg.value) {
			return getLabelsSettings(aboveAverageColor);
		} else {
			return getLabelsSettings(belowAverageColor);
		}
	}

    function formatXAxis(arg) {
        return moment(arg.value).format('YYYY-MM-DD HH:mm');
    }

	return (
		<div style={{paddingBottom: '5px', width: '70%', marginLeft: '20px', marginRight: '20px'}}>
			<Chart
                id="shockChart"
                dataSource={heatmapData}
                title="Shock Levels Over Time"
            >
                <Size
					height={250}
					width={'100% !important'}
				/>
                <ArgumentAxis
                    tickInterval={86400} // Interval in seconds (3600 seconds = 1 hour)
                    label={{ customizeText: formatXAxis }}
                />
                <ValueAxis
                    title="Shock Level"
                    label={{ format: 'fixedPoint', precision: 0 }} // Show whole numbers
                />
                <Series
                    type="line"
                    argumentField="time"
                    valueField="level"
                    name="Shock Level"
                    color="#007bff"
                />
                <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                />
            </Chart>
		</div>
  );
}

export default MechanicalShock;
