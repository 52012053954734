import React from 'react';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import ParticipatingView from "./networkToolParticipating.js"
import DiscoveryView from "./networkToolDiscovery.js"
import NetworkToolView from "./networkToolOG"
import NetworkToolAnalyzeView from "./networkToolAnalyze"
import '../assets/assetTab.css'
import Toast from 'components/Toast';

export default function App() {
    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
        displayTime: 5000,
    });

    return (
        <>
            <div className='headerHeadingPage'>
                <div className='pageFullWidthEvents'>
                    <h5 className='marginZeroEvents'>
                        <b>Networking Tool</b>
                    </h5>
                    <TabPanel className='networkTool-tab'>
                        <Item title="Network Tool">
                            <NetworkToolView >
                            </NetworkToolView>
                        </Item>
                        <Item title="Network Tool Analyze">
                            <NetworkToolAnalyzeView >
                            </NetworkToolAnalyzeView>
                        </Item>
                        <Item title="Discovery Metrics">
                            <DiscoveryView >
                            </DiscoveryView>
                        </Item>
                        <Item title="Participating Metrics" >
                            <ParticipatingView />
                        </Item>
                    </TabPanel>
                    <Toast
                        setParentToastConfig={setToastConfig}
                        toastBody={toastConfig}
                    />
                </div>
            </div>
        </>
    );
}