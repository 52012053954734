// eslint-disable-next-line import/no-anonymous-default-export
import React, { useState, useEffect, useRef } from 'react';
import { SelectBox } from 'devextreme-react';
import moment from 'moment-timezone';
import { DropDownShockThreshold, MechanicalChart, ShockChart, MechanicalShock, MergedChart } from './mechnicalAttributes';
import './mechanical.css'
import AssetsService from 'api/assets.service';
import "leaflet-draw/dist/leaflet.draw-src.css";
import 'leaflet/dist/leaflet.css';
import { DateBox } from 'devextreme-react/date-box';
import Toast from 'components/Toast';
import DataGrid, {
} from 'devextreme-react/data-grid';


const MechanicalHeatMap = (props) => {
  const assetId = props.assetId
  const assetName = props.assetName
  const [selectedValue, setSelectedValue] = useState('Last 24 hours');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dateBoxRefStart = useRef(null);
  const dateBoxRefEnd = useRef(null);
  const tenant = props.tenant;
  // const [heatmapData, setHeatMapData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [selectedThresholdValue, setSelectedThresholdValue ] = useState(2000);
  const [thresholdValue, setThresholdValue ] = useState(2000);
  const [maxThresholdValue, setMaxThresholdValue ] = useState(16000);
  const [minThresholdValue, setMinThresholdValue ] = useState(0);
  const [aggregationInterval, setAggregationInterval ] = useState('hours');
  const [error, setError ] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading...');
  
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 15000,
  });

  async function handleDataChanges(){
    // await setHeatMapData([])
    await setEventData([])
    setLoadingText('Loading...')
  }

const defaultValues = {
  minInWHSeats: null,
  maxInWHSeats: null,
  minOutWHSeats: null,
  maxOutWHSeats: null,
  minInFastCars: null,
  maxInFastCars: null,
  minOutFastCars: null,
  maxOutFastCars: null,
  minTransFastCars: null,
  maxTransFastCars: null,
  minTransSeats: null,
  maxTransSeats: null,
};

const generateJSONFromRecord = (record) => {
  const messageMapping = {
    "Incoming WH at Seats": { min: "minInWHSeats", max: "maxInWHSeats" },
    "Outgoing WH at Seats": { min: "minOutWHSeats", max: "maxOutWHSeats" },
    "Incoming WH at FastCars": { min: "minInFastCars", max: "maxInFastCars" },
    "Outgoing WH at FastCars": { min: "minOutFastCars", max: "maxOutFastCars" },
    "Transit to FastCars": { min: "minTransFastCars", max: "maxTransFastCars" },
    "Transit to Seats": { min: "minTransSeats", max: "maxTransSeats" }
  };

  

  const mapped = messageMapping[record.message] || {};

  return [
    {
      date: new Date(record.time_from),
      // ...defaultValues,
      [mapped.min]: 5000,
      [mapped.max]: 6000,
      showLabel: true
    },
    {
      date: new Date(record.time_to),
      // ...defaultValues,
      [mapped.min]: 5000,
      [mapped.max]: 6000,
      showLabel: false
    }
  ];
};

// Function to generate the full JSON array
const generateJSON = (arrA) => {
  return arrA.flatMap(record => generateJSONFromRecord(record));
};

function updateBottomProperty(arr) {
  let shouldSetBottom = false; // Start with false
  let count = 0; // To keep track of occurrences of showLabel: true

  arr.forEach(item => {
      if (item.showLabel === true) {
          count++;
          // Toggle `bottom` property every alternate `showLabel: true`
          shouldSetBottom = count % 2 === 0;
          item.bottom = shouldSetBottom;
      } else {
          // For items where showLabel is not true, bottom property is not changed
          item.bottom = item.bottom || false; // Ensure bottom is false if not explicitly set
      }
  });

  return arr;
}



  async function getData() {
    await handleDataChanges()
    const from = moment(localStorage.getItem(`${tenant}dateFilterFrom`)).utc().format();
    const to = moment(localStorage.getItem(`${tenant}dateFilterTo`)).utc().format();
    if (from === "Invalid date" || to === "Invalid date") return true;
    if (from !== null && to !== null){
      await AssetsService.locationHistoryMechanical(assetId, from, to).then(async (value) => {
        if (value.data.data.history.length > 0){
          let jsonArray = generateJSON(value.data.data.events);
          let convertedArrB = value.data.data.history.map(record => ({
            date: new Date(record.time),
            level: record.level
          }));
          jsonArray = updateBottomProperty(jsonArray)
          console.log('JSON:', jsonArray);
          let combinedArray = await jsonArray.concat(convertedArrB);
          let sortedArr = await combinedArray.sort((a, b) => new Date(a.time) - new Date(b.time));
          
          setEventData(sortedArr)
        } else {
          setEventData([])
          setLoadingText("No Data")
        }
      })
    } 
  }

  useEffect(() => {
    // Fetch data when the component mounts
    getData()
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      getData()
    }
}, [startDate, endDate]);

  const handleStartDate = () => {
    dateBoxRefStart.current.instance.open()
    setSelectedValue("Custom")
  }

  const handleEndDate = () => {
    dateBoxRefEnd.current.instance.open()
    setSelectedValue("Custom")
  }

  function onThresholdValueChanged(e) {
    console.log('VALUE: ', e);
    if(e.value === 0){
      setShowInput(true)
    } else{
      setShowInput(false)
    }
    setSelectedThresholdValue(e.value)
    setThresholdValue(e.value)

  }

  const handleStartDateChange = async (value) => {
    await setStartDate(value);
    if (value && endDate && moment(value).isAfter(endDate)) {
      await setEndDate(moment(value).add(1, 'days').toDate());
      localStorage.setItem(`${tenant}dateFilterTo`, moment(value).add(1, 'days').toDate())
    }
    localStorage.setItem(`${tenant}dateFilterFrom`, moment(value).format())

    if (endDate) {
      localStorage.setItem(`${tenant}dateFilterTo`, moment(endDate).format());
    }
    getData()
  };

  const handleEndDateChange = async (value) => {
    await setEndDate(value);
    if (value && startDate && moment(value).isBefore(startDate)) {
      console.log('start date 361: ', value);
      await setStartDate(moment(value).subtract(1, 'days').toDate());
      localStorage.setItem(`${tenant}dateFilterFrom`, moment(value).subtract(1, 'days').toDate())
    }
    localStorage.setItem(`${tenant}dateFilterTo`, moment(value).format())
    // getAnchors()
    if (startDate) {
      localStorage.setItem(`${tenant}dateFilterFrom`, moment(startDate).format());
    }
    
    getData()
  };

  const handleValueChange = async (value) => {
    
    console.log('value: ', value);
    setSelectedValue(value);
    if (value === 'Custom') {
      localStorage.setItem(`${tenant}dateFilterText`, value);
    } else {
      setStartDate(null);
      setEndDate(null);
      let start, end;
      switch (value) {
        case 'Last 24 hours':
          start = moment().subtract(24, 'hours').format();
          end = moment().format();
          break;
        case 'Today':
          start = moment().startOf('day').format();
          end = moment().endOf('day').format();
          break;
        case 'Last 5 min':
          start = moment().subtract(5, 'minutes').format();
          end = moment().format();
          break;
        case 'Last 20 min':
          start = moment().subtract(20, 'minutes').format();
          end = moment().format();
          break;
        case 'Last 1 hour':
          start = moment().subtract(1, 'hour').format();
          end = moment().format();
          break;
        case 'Last 4 hours':
          start = moment().subtract(4, 'hours').format();
          end = moment().format();
          break;
        case 'Last 12 hours':
          start = moment().subtract(12, 'hours').format();
          end = moment().format();
          break;
        case 'Yesterday':
          start = moment().subtract(1, 'day').startOf('day').format();
          end = moment().subtract(1, 'day').endOf('day').format();
          break;
        case 'This Week':
          start = moment().startOf('week').format();
          end = moment().endOf('week').format();
          break;
        case 'Last Week':
          start = moment().subtract(1, 'weeks').startOf('week').format();
          end = moment().subtract(1, 'weeks').endOf('week').format();
          break;
        case 'This Month':
          start = moment().startOf('month').format();
          end = moment().endOf('month').format();
          break;
        case 'Last Month':
          start = moment().subtract(1, 'month').startOf('month').format();
          end = moment().subtract(1, 'month').endOf('month').format();
          break;
        case 'Last 7 days':
          start = moment().subtract(7, 'days').format();
          end = moment().format();
          break;
        default:
          break;
      }
      setStartDate(start);
      setEndDate(end);
      localStorage.setItem(`${tenant}dateFilterText`, value);
    }
  };

  const handleCustomThreshold = async (value) => {
  	if(value > minThresholdValue && value <= maxThresholdValue) {
  		setError(null);
  	} else {
      setError(`Threshold value should be between ${minThresholdValue} - ${maxThresholdValue} mG`);
  		return false;
  	}
  	setThresholdValue(parseInt(value))
    // calculateShocksForSelectedThreshold(value)
  }

  useEffect(() => {
    const periodVal = localStorage.getItem(`${tenant}dateFilterText`);
    if (periodVal !== null && periodVal !== 'null') {
      console.log('period val: ', periodVal);
      if (periodVal === 'Custom') {
          const startDate = moment(localStorage.getItem(`${tenant}dateFilterFrom`));
          const endDate = moment(localStorage.getItem(`${tenant}dateFilterTo`));
          setStartDate(startDate);
          setEndDate(endDate);
      }
      handleValueChange(periodVal);
    } else {
      handleValueChange('Last 24 hours');
    }
    getData()
    // getAssetMechanicalUtilization()
}, [props.selectedTabIndex]);

  return (
    <>
    <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
    <div className='assetMechanicalContainer'>
      <div className='btnContainer'>
        <div className='dateBtn'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SelectBox
              style={{ marginRight: '10px', width: '20%' }}
              dataSource={['Today', 'Last 5 min', 'Last 20 min', 'Last 1 hour', 'Last 4 hours', 'Last 12 hours', 'Last 24 hours', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Last 7 days', 'Custom']}
              value={selectedValue}
              onValueChanged={(e) => handleValueChange(e.value)}
              labelMode="static"
              label="Period"
              stylingMode="outlined"
            />
            <div onClick={() => handleStartDate()} >
              <DateBox
                ref={dateBoxRefStart}
                style={{ marginRight: '10px'}}
                type="datetime"
                value={startDate}
                onValueChanged={async (e) => await handleStartDateChange(e.value)}
                placeholder="Start Date"
                labelMode="static"
                label="Start Date"
                stylingMode="outlined"
                displayFormat="yyyy-MM-dd HH:mm"
                valueFormat="yyyy-MM-dd HH:mm"
              />
            </div>
            <div onClick={() => handleEndDate()}>
              <DateBox
                ref={dateBoxRefEnd}
                style={{ marginRight: '10px'}}
                type="datetime"
                value={endDate}
                onValueChanged={async (e) => await handleEndDateChange(e.value)}
                placeholder="End Date"
                labelMode="static"
                label="End Date"
                stylingMode="outlined"
                displayFormat="yyyy-MM-dd HH:mm"
                valueFormat="yyyy-MM-dd HH:mm"
              />
            </div>
        </div>
        </div>
      </div>

      {eventData.length > 0 ?
      <>
      {/* <ShockChart
        width="100%"
        eventData={eventData}
        assetName={assetName}
      /> */}
      
      <div className='mechanical-container'>
      <div className='side-content-mechanical'>
        <DropDownShockThreshold
          onValueChanged={(event)=> onThresholdValueChanged(event)}
          selectedThresholdValue = {selectedThresholdValue}
          showInput={showInput}
          OnTextChange={(value)=> handleCustomThreshold(value)}
          error={error}
        />
      </div>
     
      <MergedChart 
        width="100%"
        eventData={eventData}
        thresholdValue={thresholdValue}
      />
      {/* <MechanicalChart
        width="100%"
				eventData={eventData}
				thresholdValue={thresholdValue}
			/> */}
      {/* <MechanicalShock 
        width="100%"
        heatmapData={heatmapData}
      /> */}
      </div> </>: 
      <section className='column_two'>
      <DataGrid
        height={'calc(100vh - 329px)'}
        columnAutoWidth={true}
        allowColumnResizing={true}
        showBorders={true}
        noDataText={loadingText}
      ></DataGrid>
      </section>
      }
      </div>
    </>
  );
  
}
export default MechanicalHeatMap;
