import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetch(timeZone) {
		// return Axios.get(`/api/overview`);
		return Axios.post(
			"/api/overview",
			{
			  timeZone: timeZone
			}
		  );
	},

	getEvents(from, to, timeZone) {
		let from2 = from //"2023-07-26T21:34:37.000Z"//from
		let to2 = to //"2023-07-27T21:34:37.143Z" //to
		if (from === undefined || to === undefined) {
		  from2 = null;
		  to2 = null;
		}
		return Axios.post(
		  "/api/event",
		  {
			from: from2,
			to: to2,
			timeZone: timeZone
		  }
		);
	  },
};