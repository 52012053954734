import React from 'react';

const Red = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="14px" height="14px">
      <defs>
        <linearGradient id="redLinearGradient" x1="-792" x2="-792" y1="6" y2="42" gradientTransform="translate(816)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ff8a80" />
          <stop offset="1" stopColor="#d32f2f" />
        </linearGradient>
        <radialGradient id="redRadialGradient" cx="-792" cy="24" r="18" gradientTransform="translate(816)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ff6f6f" stopOpacity="0" />
          <stop offset=".4" stopColor="#ff6f6f" stopOpacity=".01" />
          <stop offset=".544" stopColor="#ff4040" stopOpacity=".048" />
          <stop offset=".647" stopColor="#ff3333" stopOpacity=".114" />
          <stop offset=".73" stopColor="#ff2929" stopOpacity=".209" />
          <stop offset=".801" stopColor="#ff1a1a" stopOpacity=".332" />
          <stop offset=".864" stopColor="#ff0d0d" stopOpacity=".484" />
          <stop offset=".921" stopColor="#ff0000" stopOpacity=".665" />
          <stop offset=".972" stopColor="#e60000" stopOpacity=".868" />
          <stop offset="1" stopColor="#b30000" />
        </radialGradient>
      </defs>
      <circle cx="24" cy="24" r="18" fill="url(#redLinearGradient)" />
      <circle cx="24" cy="24" r="18" fill="url(#redRadialGradient)" />
    </svg>
  );
};

export default Red;