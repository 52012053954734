import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDevices(tenantId) {
   return Axios.post(
    "/api/device/tenant",
    {
      tenantId: tenantId,
    }
  );
  },

  editDevice(id, spaceId, statusUpdateRate, proximityDistance) {
    return Axios.post(
      "/api/device/edit",
      {
        id: id,
        spaceId: spaceId,
        proximityDistance: proximityDistance,
        statusUpdateRate: statusUpdateRate
      }
    );
  },
  updateDeviceTenant(selectedTenant, deviceId){
    return Axios.patch(
      "/api/device/tenant/update",
      {
        tenantId: selectedTenant,
        deviceId: deviceId
      }
    );
  },
  updateDeviceStatus(deviceId, selectedStatus){
    return Axios.patch(
      "/api/device/status/update",
      {
        status: selectedStatus,
        deviceId: deviceId
      }
    );
  },
  getDeviceStatusList(){
    return Axios.get(
      "/api/device/status/all"
    );
  },
  updateDeviceConfig(device_id, tenant_id, configData){
    return Axios.patch(
      "/api/device/config/update",
      {
        deviceId: device_id,
        tenantId: tenant_id,
        config: configData
      }
    );
  },
  getDeviceConfigData(deviceId, tenantId){
    return Axios.post(
      "/api/device/config/data",
      {
        deviceId: deviceId,
        tenantId: tenantId
      }
    );
  }
};
