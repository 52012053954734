import React, { useCallback, useMemo, useState, useRef } from "react";
import Form, { GroupItem, SimpleItem, Label, ButtonItem, RequiredRule, PatternRule } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import { ScrollView } from "devextreme-react/scroll-view";
import Toast from 'components/Toast';
import {Button} from 'devextreme-react/button';

const AssetTypeCreate = ({ showPopUp, setShowPopUp, sendDataToParent }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [type, setType] = useState('');
  const [attributes, setAttributes] = useState(['']);
  const scrollViewRef = useRef(null);
  const formRef = useRef(null);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "info",
    message: "",
    displayTime: 3000,
  });
  const noSpecialCharsPattern = /^[a-zA-Z0-9\s]*$/;

  const generateNewAttributeOptions = useCallback(
    (index) => ({
      buttons: [
        {
          name: "trash",
          location: "after",
          options: {
            stylingMode: "text",
            icon: "trash",
            onClick: () => {
              const newAttributes = attributes
                .slice(0, index)
                .concat(attributes.slice(index + 1));
              setAttributes(newAttributes);
            },
          },
        },
      ],
    }),
    [attributes]
  );

  function manageContentScroll() {
    if (scrollViewRef.current) {
      const scrollViewInstance = scrollViewRef.current.instance;
      scrollViewInstance.scrollTo({ top: scrollViewInstance.scrollHeight() });
    }
  }

  const AttributeOptions = useMemo(() => {
    const options = [];
    for (let i = 0; i < attributes.length; i += 1) {
      options.push(generateNewAttributeOptions(i));
    }
    return options;
  }, [attributes, generateNewAttributeOptions]);

  const AttributeButtonOptions = useMemo(
    () => ({
      icon: "add",
      text: "Add Attribute",
      elementAttr: {
        style: 'background-color: #D71109; color: #FFFFFF', // Add inline styles
        class: 'add-atrib-btn'
      },
      onClick: () => {
        setAttributes((prevAttributes) => [...prevAttributes, ""]);
        manageContentScroll();
      },
    }),
    []
  );
  
  const valueChangeAttribute = (e) => {
    const formInstance = formRef.current?.instance;
    const validationResult = formInstance.validate();
    console.log('validation result: ', validationResult);
    
    if (!validationResult.isValid) {
      console.log('FORM VALID 123: ', validationResult.isValid);
      setIsFormValid(validationResult.isValid)
      // Validation failed, show error and stop submission
      return;
    } else {
      setIsFormValid(validationResult.isValid)
    }
  }
  
  const handleSave = (e) => {
    // Validate the form
    const formInstance = formRef.current?.instance;
    const validationResult = formInstance.validate();
    
    if (!validationResult.isValid) {
      console.log('FORM VALID: ', validationResult.isValid);
      
      setIsFormValid(validationResult.isValid)
      // Validation failed, show error and stop submission
      return;
    }
    // Filter out empty attributes
    const filteredAttributes = attributes.filter((attr) => attr.trim() !== "");
    const formData = {
      type: type,
      attributes: filteredAttributes,
    };

    // sending data to parent component
    sendDataToParent(formData);

  };

  const handleCancel = () => {
    setShowPopUp(false); // Close popup on cancel
  };

  return (
    <>
    <Toast
    setParentToastConfig={setToastConfig}
    toastBody={toastConfig}
  />
    <React.Fragment>
      <Popup
        visible={showPopUp}
        width={500}
        height={700}
        title={"Add Asset Type"}
        showCloseButton={true}
        onHiding={handleCancel} // Close popup when 'X' is clicked
      >
          <div className="popup-content" style={{ height: "calc(100% - 60px)" }}> {/* Adjust height of content */}

        <ScrollView height={400} showScrollbar="always" ref={scrollViewRef}>
          <Form
            ref={formRef}
            formData={{ Type: type, Attributes: attributes }}
            validationGroup="assetTypeForm"
            onFieldDataChanged={(e) => {
              if (e.dataField === "Type") {
                setType(e.value);
              }
              valueChangeAttribute(e)
            }}
            // showValidationSummary={true}
          >
            {/* <ValidationSummary
              validationGroup="assetTypeForm"
              showGeneralErrors={false} // To hide general errors if any
            /> */}
            <GroupItem>
              <SimpleItem dataField="Type" 
                editorOptions={{
                  onValueChanged: (e) => setType(e.value),
                  validationMessageMode: "always", // Show validation only when triggered
                }}>
                <Label text="Asset Type Name" />
                <RequiredRule message="Asset Type Name is required" />
                {/* <PatternRule
                  message={'Do not use special characters like \\,/, ", @, # etc.'}
                  pattern={noSpecialCharsPattern}
                /> */}
              </SimpleItem>
            </GroupItem>

            <GroupItem caption="Attributes">
              {AttributeOptions.map((attribute, index) => (
                <SimpleItem
                  key={`Attributes${index}`}
                  dataField={`Attributes[${index}]`}
                  editorOptions={attribute}
                >
                  <Label text={`Attribute ${index + 1}`} />
                  <PatternRule
                  message={'Do not use special characters like \\,/, ", @, # etc.'}
                  pattern={noSpecialCharsPattern}
                />
                </SimpleItem>
              ))}
              <ButtonItem
                horizontalAlignment="left"
                cssClass="add-attrib-button"
                buttonOptions={AttributeButtonOptions}
              />
            </GroupItem>
          </Form>
        </ScrollView>
        </div>
        <div className='movePopup' style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
          <Button className='movePopupSaveBtn dx-button' style={{cursor:"pointer", fontWeight: "bold", marginRight: "5px"}} onClick={handleSave}>Save</Button>
          <Button className='movePopupSaveBtn dx-button' useSubmitBehavior={true} style={{cursor:"pointer", fontWeight: "bold"}} onClick={handleCancel} >Cancel</Button>
        </div>
      </Popup>
    </React.Fragment>
    </>
  );
};

export default AssetTypeCreate;
