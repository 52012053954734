import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './side-navigation-menu.scss';
import * as events from 'devextreme/events';
import { useAuth } from 'contexts/auth';

export default function SideNavigationMenu(props) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const { user, signOut } = useAuth();
  const { isLarge } = useScreenSize();
  const menuItems = localStorage.getItem("menu");
  function normalizePath () {
    if (menuItems===null) signOut();
    const navigation = JSON.parse(menuItems)
    navigation.map((item, index) => {
      console.log('index', index);
      if(item.text === 'Spaces'){
        navigation.splice(index+1,0,{icon: "/icons/events.svg", path: "/events", text: "Events"})
      }
    })
    return navigation?.map((item) => {
      if(item.path && !(/^\//.test(item.path))){
        item.path = `/${item.path}`;
      }
      return {...item};
    });
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { navigationData: { currentPath } } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', e => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }
    if(currentPath === '/notification' || currentPath === '/profile'){
      treeView.unselectAll();
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
        <footer className="footer" display= "none" style={{display: !compactMode ? 'block' : 'none' }}>
          Copyright © 2018-{new Date().getFullYear()} Redlore
        </footer>
        
      </div>
      
      
    </div>
  );
}
